import React, { useState, useEffect } from 'react';
import {
  Icon,
  Box,
  Checkbox,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Center,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import moment from "moment";
import { useCookies } from "react-cookie";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useJwt } from "react-jwt";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternCheckbox from "../../Patterns/Checkbox";
import { toast } from "react-toastify";
import PatternSelect from '../../Patterns/Select';

interface Student {
  id: number;
  name: string;
  DayWeek: string;
  codigoAlunoReposicao: number;
  id_Reposicao: number;
  codigoAlunoTurma: number;
  presence: boolean;
  absence: boolean;
  justifiedAbsence: boolean;
  replacement: boolean;
  observation: string;
  tipoAulaPersonalizada: number;
  nivelDoAluno: number;
  materialUsadoNaAula: number;
  paginaQueParouNoMaterial: number;
}

interface Teacher {
  name: String,
  id: Number
}

export default function ClassLaunchModal(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies] = useCookies(['loginToken']);
  const [teacherId, setTeacherId] = useState<Number>(0);
  const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
  const [dayClass, setDayClass] = useState<string>(moment().format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<Student[]>([]);
  const [aulasEstudantes, setAulasEstudantes] = useState<Student[]>([]);

  const handleCheckboxChange = (index: number, field: keyof Student) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      presence: false,
      absence: false,
      justifiedAbsence: false,
      replacement: false,
      [field]: !newStudents[index][field],
    };
    setAulasEstudantes(newStudents);
  };

  const handleMaterialUsadoChange = (index: number, value: number) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      materialUsadoNaAula: value,
    };
    setAulasEstudantes(newStudents);
  };

  const handleNivelAlunoChange = (index: number, value: number) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      nivelDoAluno: value,
    };
    setAulasEstudantes(newStudents);
  };

  const handleAulaPersonanalizadaChange = (index: number, value: number) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      tipoAulaPersonalizada: value,
    };
    setAulasEstudantes(newStudents);
  };

  const handlePaginaQueParouChange = (index: number, value: number) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      paginaQueParouNoMaterial: value,
    };
    setAulasEstudantes(newStudents);
  };

  const handleObservationChange = (index: number, value: string) => {
    const newStudents = [...aulasEstudantes];
    newStudents[index] = {
      ...newStudents[index],
      observation: value,
    };
    setAulasEstudantes(newStudents);
  };

  const handleAddClass = async () => {
    try {
      setIsLoading(true);
      const resultValuesChecks = aulasEstudantes.map(student => {
        let tipo = '';
        if (student.presence) tipo = 'Presenca';
        else if (student.absence) tipo = 'Falta';
        else if (student.justifiedAbsence) tipo = 'FaltaReposicao';
        else if (student.replacement) tipo = 'ReposicaoOutroProfessor';
        if (student.name.toLowerCase().includes('reposicao')) {
          if (tipo === 'Presenca') tipo = 'ReposicaoPresenca';
          if (tipo === 'Falta') tipo = 'ReposicaoFalta';
        } else if (student.name.toLowerCase().includes('turma')) {
          if (tipo === 'Presenca') tipo = 'PresencaTurma';
          if (tipo === 'Falta') tipo = 'FaltaTurma';
          if (tipo === 'FaltaReposicao') tipo = 'FaltaTurmaReposicao';
        }

        return {
          id: student.id,
          tipo,
          texto: student.observation || '',
          Id_Reposicao: student.id_Reposicao,
          materialUsadoEmAula: student.materialUsadoNaAula,
          nivelDoAluno: student.nivelDoAluno,
          tipoAulaPersonalizada: student.tipoAulaPersonalizada,
          paginaQueParouNoMaterial: student.paginaQueParouNoMaterial,
        };
      }).filter(student => student.tipo);

      const dataToSubmit = {
        resultValuesChecks,
        date: dayClass,
        teacherId: decodedToken?.TeacherId
      };

      await api.post('api/ClassLaunch', dataToSubmit);
      toast.success("Aula Lançada com sucesso!");
      props?.updateTick();
      onClosePanel();
    } catch (err: any) {
      if (err.response?.data?.message === "Limite Atingido")
        toast.error("Ops, parece que algum aluno já está no seu limite de aulas declaradas.");
      else if (err.response?.data?.message === "Aula Ja Existente")
        toast.error("Ops, parece que algum aluno da lista já tem aula declarada neste dia.");
      else if (err.response?.data?.message === "Esse aluno não tem reposição de aula disponível")
        toast.error("Ops, Esse aluno não tem reposição de aula disponível");
      else if (err.response?.data?.message === "Você precisa preencher o material utilizado na aula")
        toast.error("Você precisa preencher o material utilizado na aula na aula que marcou presença");
      else if (err.response?.data?.message === "Você precisa preencher o nivel do aluno")
        toast.error("Você precisa preencher o nivel do aluno na aula que marcou presença");
      else if (err.response?.data?.message === "Você precisa preencher a pagina que parou no livro")
        toast.error("Você precisa preencher a pagina que parou no livro na aula que marcou presença");
      else if (err.response?.data?.message === "Você precisa precisa adicionar uma observação com no minimo 10 caracteres")
        toast.error("Você precisa precisa adicionar uma observação com no minimo 10 caracteres ao escolher uma aula personalizada");
      else
        toast.error("Um erro ocorreu ao lançar esta aula!");
    } finally {
      props?.updateTick();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onHandleEmptyData();
    setStudents([]);
    if (decodedToken?.role === "Professor" && decodedToken) {
      setTeacherId(decodedToken?.TeacherId);
      api.get(`api/StudentGetClassLaunch?TeacherId=${decodedToken?.TeacherId}&dataEscolhida=${dayClass}`).then((response) => {
        setStudents(response.data.object);
        setAulasEstudantes(response.data.object);
      });
    }
  }, [decodedToken, dayClass, isOpen]);

  const onClosePanel = () => {
    onHandleEmptyData();
    onClose();
  }

  const onHandleEmptyData = () => {
    setAulasEstudantes([]);
  }

  const handleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    onHandleEmptyData();
    const date = new Date(event.currentTarget.value);
    const hoje = new Date(moment().format());
    if (date > hoje) {
      setDayClass(moment(hoje).format('YYYY-MM-DD'));
    } else {
      setDayClass(event.currentTarget.value);
    }
  }

  const modalSize = useBreakpointValue({ base: 'full', md: 'xl' });

  return (
    <>
      {props.modalType === 'edit'
        ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
        : <PatternButton icon={IoMdAdd} onClick={onOpen}>Lançar Aula</PatternButton>}

      <Modal isOpen={isOpen} onClose={onClosePanel} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.modalType === 'new' ? 'Lançar Aula' : 'Editar Aula Já Lançada'}</ModalHeader>
          <ModalCloseButton />
          <Center>
            <ModalBody display="flex" flexDir="column" gap={2} w="100%">
              <p>Data da Aula</p>
              <PatternInput
                type="date"
                id="dayClass"
                name="dayClass"
                placeholder="E-dayClass"
                max={moment().format('YYYY-MM-DD')}
                min={moment().add(-30, "days").format('YYYY-MM-DD')}
                value={dayClass}
                onChange={(event) => handleDate(event)}
              />
            </ModalBody>
          </Center>
          <Center>
            <ModalBody display="flex" flexDir="column" gap={3} w="100%">
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Alunos</Th>
                      <Th>Presença</Th>
                      <Th>Falta</Th>
                      <Th>Falta com Direito a Reposição</Th>
                      <Th>Reposição de Aula com Outro Professor</Th>
                      <Th><Text>Aula Personalizada</Text><Text fontSize="10px">(Opcional)</Text></Th>
                      <Th><Text>Nível do Aluno</Text><Text fontSize="10px">(Obrigatório)</Text></Th>
                      <Th>Material usado na aula <Text fontSize="10px">(Obrigatório)</Text></Th>
                      <Th>Pagina que parou no material <Text fontSize="10px">(Obrigatório se Beyond Basics)</Text></Th>
                      <Th>Observação <Text fontSize="10px">(Obrigatório se Aula Personalizada)</Text></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {aulasEstudantes.map((student, index) => (
                      <Tr key={index}>
                        <Td>{student.name}</Td>
                        <Td><Checkbox isChecked={student.presence} onChange={() => handleCheckboxChange(index, 'presence')} /></Td>
                        <Td><Checkbox isChecked={student.absence} onChange={() => handleCheckboxChange(index, 'absence')} /></Td>
                        <Td><Checkbox isChecked={student.justifiedAbsence} onChange={() => handleCheckboxChange(index, 'justifiedAbsence')} /></Td>
                        <Td><Checkbox isDisabled={student.name.includes('REPOSICAO') ? false : true} isChecked={student.replacement} onChange={() => handleCheckboxChange(index, 'replacement')} /></Td>
                        <Td>
                          <PatternSelect 
                            value={student.tipoAulaPersonalizada}
                            onChange={(e) => handleAulaPersonanalizadaChange(index, Number(e.target.value))}
                            placeholder="">
                              <option key={1} value={-1}></option>
                              <option key={2} value={1}>Aula Personalizada</option>
                              <option key={3} value={2}>Aula Personalizada + Aula do Livro</option>
                          </PatternSelect>
                        </Td>
                        <Td>
                          <PatternSelect 
                            value={student.nivelDoAluno}
                            onChange={(e) => handleNivelAlunoChange(index, Number(e.target.value))}
                            placeholder="">
                              <option key={0} value={-1}></option>
                              <option key={1} value={1}>A1</option>
                              <option key={2} value={2}>A2</option>
                              <option key={3} value={3}>B1</option>
                              <option key={4} value={4}>B2</option>
                              <option key={5} value={5}>C1</option>
                              <option key={6} value={6}>C2</option>
                          </PatternSelect>
                        </Td>
                        <Td>
                          <PatternSelect 
                            value={student.materialUsadoNaAula}
                            onChange={(e) => handleMaterialUsadoChange(index, Number(e.target.value))}
                            placeholder="">
                              <option key={0} value={-1}></option>
                              <option key={1} value={1}>Conversação</option>
                              <option key={2} value={2}>Beyond Basics</option>
                          </PatternSelect>
                        </Td>
                        <Td><Input value={student.paginaQueParouNoMaterial} onChange={(e) => handlePaginaQueParouChange(index, Number(e.target.value))} placeholder="Página que parou no material" /></Td>
                        <Td><Input value={student.observation} onChange={(e) => handleObservationChange(index, e.target.value)} placeholder="Observação" /></Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </ModalBody>
          </Center>
          <ModalFooter flexDir="row" justifyContent="space-between">
            <Button variant='ghost' mr={3} onClick={onClosePanel}>
              Cancelar
            </Button>
            <PatternButton isLoading={isLoading} onClick={handleAddClass}>{'Salvar'}</PatternButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
