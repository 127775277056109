import React, { useState, useEffect } from "react";
import { Text, Container, Flex, Box, Icon, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { MdEmail, MdCheckBox, MdLock } from 'react-icons/md';
import PatternButton from "../../components/Patterns/Button";
import PatternInput from "../../components/Patterns/Input";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { api } from "../../services/api";
import { useJwt } from "react-jwt";

export function ResetPassword() {
    const [senha, setSenha] = useState<string>('');
    const [confirmaSenha, setConfirmaSenha] = useState<string>('');
    const [codigoDigitado, setCodigoDigitado] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCodeVerified, setIsCodeVerified] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const { codigo } = useParams();
    const { decodedToken } = useJwt<{ Codigo: number, Id: string }>(codigo || '');

    useEffect(() => {
        if(decodedToken) {
            if (decodedToken?.Codigo == 0 || decodedToken?.Codigo == null || !decodedToken?.Codigo) {
                navigate("/login");
            }
        }

    }, [decodedToken, navigate]);

    const validatePassword = (password: string) => {
        const validations = {
            minLength: password.length >= 8,
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        return validations;
    };

    const passwordValidations = validatePassword(senha);

    // Variável para verificar se a senha é válida e todos os critérios foram atendidos
    const isPasswordValid = Object.values(passwordValidations).every(Boolean);

    const handleVerifyCode = async () => {
        if (codigoDigitado === 0) {
            toast.warn('Favor preencher o código antes de enviar.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.post(`api/ResetSenha?codigoLink=${decodedToken?.Codigo}&codigoDigitado=${codigoDigitado}`);
            if (response.data.success) {
                toast.success('Código verificado com sucesso!');
                setIsCodeVerified(true);
            } else {
                toast.error('Código inválido!');
            }
        } catch {
            toast.error('Ocorreu um erro ao verificar o código!');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePassword = async () => {
        const response = await api.put(`api/AlterarSenhaPorEsquecimento`, {
            Id: decodedToken?.Id,
            Senha: senha
        });

        if(response.data.success) {
            toast.success('Reset concluído com sucesso! Utilize sua nova senha na plataforma!');
            navigate("/login");
        }
    }

    return (
        <>
            <Helmet>
                <title>King | {isCodeVerified ? "Redefinir Senha" : "Verificar Código"}</title>
            </Helmet>
            <Box>
                <Container
                    position="relative"
                    as="form"
                    mt="28vh"
                    border="2px solid"
                    borderColor="primary.500"
                    borderRadius="6px"
                    bg="white"
                >
                    <Box bg="white" position="absolute" left="44.5%" top="-32px" >
                        <Icon as={MdLock} fontSize="4rem" color="secondary.500" />
                    </Box>
                    <Text
                        fontSize="2rem"
                        fontWeight="900"
                        bgGradient="linear(to-tr, primary.500, secondary.500, white)"
                        bgClip="text"
                        m="24px 0 24px 24px"
                        textDecor="none"
                        position="relative"
                        _after={{
                            position: 'absolute',
                            content: "''",
                            height: '4px',
                            bottom: '4px',
                            margin: '0 auto',
                            left: 52,
                            width: '9%',
                            bgGradient: "linear(to-r, primary.500, secondary.500)"
                        }}
                    >
                        {isCodeVerified ? "Redefinir Senha" : "Verificar Código"}
                    </Text>
                    <Flex p={12} flexDir="column" gap={4}>
                        {!isCodeVerified ? (
                            <>
                                <PatternInput
                                    type="number"
                                    id="verification-code"
                                    name="verification-code"
                                    placeholder="Código de Verificação"
                                    variant="filled"
                                    groupSize="lg"
                                    value={codigoDigitado}
                                    onChange={(event) => setCodigoDigitado(Number(event.currentTarget.value))}
                                />
                                <PatternButton
                                    mt="2.5em"
                                    color="white"
                                    type="button"
                                    onClick={handleVerifyCode}
                                    isLoading={isLoading}
                                    disabled={codigoDigitado === 0}
                                >
                                    Enviar
                                </PatternButton>
                            </>
                        ) : (
                            <>
                                <InputGroup size="lg">
                                    <PatternInput
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        placeholder="Senha"
                                        icon={MdEmail}
                                        variant="filled"
                                        groupSize="lg"
                                        value={senha}
                                        onChange={(event) => setSenha(event.currentTarget.value)}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                
                                <InputGroup size="lg">
                                    <PatternInput
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="password-confirmation"
                                        name="password-confirmation"
                                        placeholder="Confirme sua senha"
                                        icon={MdCheckBox}
                                        variant="filled"
                                        groupSize="lg"
                                        value={confirmaSenha}
                                        onChange={(event) => setConfirmaSenha(event.currentTarget.value)}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>

                                <Box mt={4}>
                                    <Text color={passwordValidations.minLength ? "green" : "red"}>
                                        - 8 caracteres
                                    </Text>
                                    <Text color={passwordValidations.hasUpperCase ? "green" : "red"}>
                                        - 1 Letra maiúscula
                                    </Text>
                                    <Text color={passwordValidations.hasLowerCase ? "green" : "red"}>
                                        - 1 Letra minúscula
                                    </Text>
                                    <Text color={passwordValidations.hasNumber ? "green" : "red"}>
                                        - 1 Número
                                    </Text>
                                    <Text color={passwordValidations.hasSpecialChar ? "green" : "red"}>
                                        - 1 Caractere especial
                                    </Text>
                                </Box>
                                <PatternButton
                                    mt="2.5em"
                                    color="white"
                                    type="button"
                                    onClick={handlePassword}
                                    isLoading={isLoading}
                                    isDisabled={!isPasswordValid || senha !== confirmaSenha || senha === '' || confirmaSenha === ''}
                                >
                                    Enviar
                                </PatternButton>
                            </>
                        )}
                        <Link to="/login" replace>
                            <Box as="span" display="flex" alignItems="center" gap="0.35em"><FaArrowLeft /> Realizar Login</Box>
                        </Link>
                    </Flex>
                </Container>
            </Box>
        </>
    );
};
