import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useJwt } from "react-jwt";
import PatternCard from "../../components/Patterns/Card";
import DashboardsConfig from "./Dashbords";

export function Metricas() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    
    return (
        <>
            <Helmet>
                <title>King | Dashboards</title>
            </Helmet>
            <PatternCard title="Dashboards">
            <DashboardsConfig />
            </PatternCard>
        </>
    );
};