import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Image, List, ListIcon, ListItem, useTimeout } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { Link, useLocation } from 'react-router-dom';
import { navConfig, NavConfig } from '../../utils/navConfig';
import { useAulaStatus } from '../../contexts/VerificaAulasLancadasEm24hContext';
import { FestivityContext } from '../../contexts/FestivityContext';

interface DecodedLoginToken {
    role: string;
    [key: string]: any;
}

const Sidebar: React.FC = () => {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const { festivityDecision, festivityBreakPeriod } = useContext(FestivityContext);
    const location = useLocation();

    const { aulaLancada } = useAulaStatus();

    const breakPeriod = festivityDecision && festivityBreakPeriod;

    const rolePermissions: Record<string, (nav: NavConfig) => boolean> = {
        Administrador: () => true,
        Coordenador: (nav) => ['Configuração Hora/Aula', 'Calendário de Aulas', 'Aulas', 'Reposição', 'Configuração Aluno/Professor', 'Novos Alunos', 'Usuários', 'Alunos'].includes(nav.name),
        Suporte: (nav) => ['Home', 'Configuração Aluno/Professor', 'Aulas', 'Calendário de Aulas', 'Usuários', 'Reposição', 'Alunos'].includes(nav.name),
        Vendedor: (nav) => ['Novos Alunos', 'Calendário de Aulas', 'Configuração Hora/Aula', 'Configuração Aluno/Professor', 'Usuários', 'Alunos', 'Alunos que Começam em Breve'].includes(nav.name),
        Professor: (nav) => ['Configuração Hora/Aula', 'Calendário de Aulas', 'Reposição'].includes(nav.name),
    };

    const hasPermission = (nav: NavConfig): boolean => {
        if (!decodedToken?.role) return false;
        const checkPermission = rolePermissions[decodedToken.role];
        return checkPermission ? checkPermission(nav) : false;
    };

    return (
        <Container position="relative" zIndex="10 !important" bg="primary.500" minH="100vh" p="0 !important">
            <Box position="fixed" w="15%">
                <Image mt={4} p={4} src="/King-logo.png" />
                <List mt={8} color="white" overflowY="scroll" maxH="calc(100vh - 180px);" css={{ /*'&::-webkit-scrollbar': { display: 'none' }*/ }}>
                    {navConfig.map((nav) => {
                        const active = location.pathname === nav.path;
                        if (!hasPermission(nav)) return null;

                        return (
                            <Link
                                key={nav.id}
                                to={nav.path}
                                replace
                                style={
                                    decodedToken?.role === 'Professor' && (!aulaLancada || breakPeriod)
                                        ? { pointerEvents: 'none' }
                                        : undefined
                                }
                            >
                                <ListItem
                                    w="100%"
                                    p={3}
                                    cursor={
                                        decodedToken?.role === 'Professor' && (!aulaLancada || breakPeriod)
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    transition="0.2s"
                                    bg={active ? 'textColor' : 'initial'}
                                    color={active ? 'contrastTextColor' : 'textColor'}
                                    _hover={
                                        active
                                            ? {}
                                            : {
                                                bg: 'textColor',
                                                color: 'contrastTextColor',
                                                filter: 'brightness(0.7)',
                                                cursor: decodedToken?.role === 'Professor' && (!aulaLancada || breakPeriod)
                                                    ? 'not-allowed'
                                                    : 'pointer'
                                            }
                                    }
                                    style={
                                        decodedToken?.role === 'Professor' && (!aulaLancada || breakPeriod)
                                            ? { opacity: 0.6 }
                                            : undefined
                                    }
                                >
                                    <ListIcon as={nav.icon} fontSize="1.5rem" />
                                    {nav.name}
                                </ListItem>
                            </Link>
                        );
                    })}
                </List>
            </Box>
        </Container>
    );
};

export default Sidebar;
