import { Box, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, useDisclosure } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";

import { useState, useEffect } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { api } from "../../../services/api";
import DataGrid from "..";
import { formatDate } from "../../../utils/formatter";
import TurmasAdicionarAlunosSeparadamenteModal from "./TurmasAdicionarAlunosSeparadamenteModal";

export default function TurmasModel (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [alunosNaTurma, setAlunosNaTurma] = useState<object[]>([])
    const [tick, setTick] = useState<number>(0)
    const [dataDeInicioDaTurma, setDataDeInicioDaTurma] = useState<string>(props?.data?.dataInicioTurma);
    const [professorPrincipal, setProfessorPrincipal] = useState<string>(props?.data?.professorPrincipal);
    const [professorSubstituto, setProfessorSubstituto] = useState<string>(props?.data?.professorSubstituto);
    const turmaId = props.data.id;

    useEffect(() => {
        if(decodedToken && isOpen) {
            api.get(`api/turmas/ObterAlunosVinculadosATurma?turmaId=${props.data.id}`)
            .then((response) => {
                setAlunosNaTurma(response.data.object.map((row: any) => {
                    return {
                        nome: row.nome,
                        dataDeInicio: formatDate(row.dataDeInicio, false),
                        tempoNaTurma: row.tempoNaTurma,
                        id: row.id,
                        idAluno: row.idAluno
                    };
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false))
        }
    }, [isOpen, props, decodedToken, tick])

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            <Icon as={IoEyeSharp} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />

            <Modal isOpen={isOpen} size={"full"} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Turma - {props?.data?.nome} - {props?.data?.nivel}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                    Inicio da Turma: {dataDeInicioDaTurma} <br/>
                    Professor Principal: {professorPrincipal} <br/>
                    Professor Substituto: {professorSubstituto} <br/>
                    <VStack spacing={4} align="stretch" width="100%">
                        <Box width="100%">
                        {
                            alunosNaTurma.length > 0 ?
                            <DataGrid
                                rowData={alunosNaTurma}
                                isLoading={isLoading}
                                isError={isError}
                                addModal={<TurmasAdicionarAlunosSeparadamenteModal turmaId={props.data.id} updateTick={updateTick} />}
                                icons={['Deletar']}
                                deleteAlunoTurma={`turmas/ApagarAlunoEmUmaTurma/${turmaId}`}
                                deletePath={"turmas/ApagarAlunoEmUmaTurma/"}
                                updateTick={updateTick}
                            />
                            :
                            <>
                                <TurmasAdicionarAlunosSeparadamenteModal turmaId={turmaId} updateTick={updateTick} />
                                Não há alunos cadastrados na turma...
                            </>
                            
                        }
                        </Box>
                    </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}