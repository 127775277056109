import { useEffect, useState } from "react";
import UserModal from "../../components/DataGrid/AddModals/UserModal";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { decode } from "punycode";
import ClassLaunchModal from "../../components/DataGrid/AddModals/ClassLaunchModelOld";
import PatternInput from "../../components/Patterns/Input";
import PatternButton from "../../components/Patterns/Button";
import PatternCard from "../../components/Patterns/Card";
import PatternSelect from "../../components/Patterns/Select";
import moment from "moment";

interface RelatorioAluno {
    nomeAluno: string;
    tipoDaAula: string;
    quantidadeDeAulas: number;
    duracaoDaAula: number;
    professor: string;
    observacao: string;
    tipoAulaPersonalizada: string;
    nivelDoAluno: string;
    materialUsadoEmAula: string;
    paginaQueParouNoMaterial: number;
    diaDaAula: string;
};

interface GetTeacherResponse {
    nome: string;
    id: Number;
};

export default function ReportStudentPerTeacher() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();

    const [rowData, setRowData] = useState<RelatorioAluno[]>([]);
    const [teacher, setTeacher] = useState<GetTeacherResponse[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [month, setMonth] = useState<number>(mesAtual);
    const [year, setYear] = useState<number>(anoAtual);

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }

    useEffect(() => {
        setIsLoading(() => true)
        if(decodedToken && decodedToken?.role !== 'Professor'){
            api.get("/api/TeacherSelectBox").then((resp) => {
                setTeacher(resp.data.object);
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }


        setIsLoading(() => false);
        setIsError(() => false);
        // setRowData(() => []);
    }, [tick, decodedToken]);

    const handleUpdateReport = () =>{
        setIsLoading(() => true)
        api.get<RelatorioAluno[]>(`api/ReportValuesStudentPerTeacherGet?month=${month}&teacher_id=${teacherId}&year=${year}`)
        .then((response : any) => {
            setRowData(response.data.object.map((row: any) => {
                return {
                    ...row,
                    diaDaAula: moment(row.diaDaAula).format("DD/MM/YYYY"),
                };
            }));
        })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false));
    }

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                <PatternSelect 
                    placeholder='MÊS' 
                    value={month} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                >
                    <option key={1} value={1}>JANEIRO</option>
                    <option key={2} value={2}>FEVEREIRO</option>
                    <option key={3} value={3}>MARÇO</option>
                    <option key={4} value={4}>ABRIL</option>
                    <option key={5} value={5}>MAIO</option>
                    <option key={6} value={6}>JUNHO</option>
                    <option key={7} value={7}>JULHO</option>
                    <option key={8} value={8}>AGOSTO</option>
                    <option key={9} value={9}>SETEMBRO</option>
                    <option key={10} value={10}>OUTUBRO</option>
                    <option key={11} value={11}>NOVEMBRO</option>
                    <option key={12} value={12}>DEZEMBRO</option>
                </PatternSelect>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                <PatternSelect 
                    placeholder='ANO' 
                    value={year} 
                    onChange={(event) => { updateYear(event.currentTarget.value)}}
                >
                    <option key={1} value={2023}>2023</option>
                    <option key={2} value={2024}>2024</option>
                    <option key={3} value={2025}>2025</option>
                    <option key={4} value={2026}>2026</option>
                    <option key={5} value={2027}>2027</option>
                    <option key={6} value={2028}>2028</option>
                    <option key={7} value={2029}>2029</option>
                    <option key={8} value={2030}>2030</option>
                    <option key={9} value={2031}>2031</option>
                    <option key={10} value={2032}>2032</option>
                    <option key={11} value={2033}>2033</option>
                    <option key={12} value={2034}>2034</option>
                </PatternSelect>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                <PatternSelect 
                    placeholder='PROFESSOR' 
                    value={Number(teacherId)} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTeacherId(Number(event.currentTarget.value))}
                >
                    {
                        teacher.map((obj:GetTeacherResponse) => (
                            <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                        ))
                    }
                </PatternSelect>
            </FormControl>
            
            <PatternButton mt={[4, 4, 8]} onClick={handleUpdateReport} isLoading={isLoading}>Gerar relatório</PatternButton>

            <Box mt={6}>
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={true}
                />
            </Box>
        </>   
    );
};