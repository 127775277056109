import { useEffect, useState } from "react";
import { api } from "../../services/api";
import DataGrid from "../../components/DataGrid";
import { Box, HStack, VStack, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import StudentModal from "../../components/DataGrid/AddModals/StudentModel";
import { formatDate } from "../../utils/formatter";
import TurmasModel from "../../components/DataGrid/AddModals/TurmasModel";
import TurmasModelAddEdit from "../../components/DataGrid/AddModals/TurmasModelAddEdit";


export function Turmas() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<object[]>([]);
    const [tick, setTick] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const stackDirection = useBreakpointValue<"row" | "column">({ base: 'column', md: 'row' });

    useEffect(() => {
        setIsError(() => false);
        setIsLoading(() => true);

        RetornaDadosTurmas();

    }, [decodedToken, tick]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    const RetornaDadosTurmas = () => {
        if(decodedToken) {
            api.get('api/turmas/ObterTurmasAtivas')
            .then((response) => {
                setRowData(response.data.object.map((row: any) => {
                    console.log(row)
                    return {
                        ...row,
                        dataInicioTurma: formatDate(row.dataInicioTurma, false)
                    };
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false))
        }
    }


    return (
        <VStack spacing={4} align="stretch" width="100%">
            <Box width="100%">
                {rowData.length === 0 ? (
                    <TurmasModelAddEdit modalType="new" updateTick={updateTick} />
                ) : (
                    <DataGrid
                        rowData={rowData}
                        isLoading={isLoading}
                        isError={isError}
                        enableExport={decodedToken?.role === 'Administrador'}
                        addModal={<TurmasModelAddEdit modalType="new" updateTick={updateTick} />}
                        visualizationModal={<TurmasModel />}
                        icons={['Visualizar', 'Editar', 'Deletar']}
                        deletePath={"turmas/ApagarTurma"}
                        updateTick={updateTick}
                    />
                )}
                
            </Box>
        </VStack>
    );
};