import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button"
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";

export default function UserModal(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [admin, setAdmin] = useState<string>('');
    const [active, setActive] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    useEffect(() => {
        if (props?.modalType === 'edit') {
            setName(props?.data?.Nome);
            setEmail(props?.data?.Email);
            setRole(props?.data?.subRole);
            setAdmin(props?.data?.Role);
            setActive(props?.data?.ativo === 'Sim');
        };
    }, [props]);

    const handleAddUser = async () => {
        if (name === '' || email === '') {
            toast.warning("Preencha todas as informações!");
            return;
        };

        try {
            setIsLoading(() => true);

            const data = {
                name,
                email,
                role: admin,
                ativo: true
            };

            await api.post('api/RegisterUser', data);

            toast.success("Usuário criado com sucesso!");
            props?.updateTick();
        } catch {
            toast.error("Um erro ocorreu ao cadastrar este usuário!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleEditUser = async () => {
        if (name === '' || email === '' || role === '') {
            toast.warning("Preencha todas as informações!");
            return;
        };

        try {
            setIsLoading(() => true);

            const data = {
                id: props?.data?.id,
                name,
                email,
                role: admin,
                ativo: active,
            };

            await api.put('api/AlterUser', data);

            toast.success("Usuário alterado com sucesso!");
            props?.updateTick();
        } catch {
            toast.error("Um erro ocorreu ao alterar este usuário!");
        } finally {
            setIsLoading(() => false);
        };
    };

    return (
        <>
            {
                props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> 
                : 
                decodedToken?.role === 'Administrador' ||  decodedToken?.role === 'Coordenador' ?
                    <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
                :
                null
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar novo Usuário' : 'Alterar Usuário'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <PatternInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nome"
                            value={name}
                            onChange={(event) => setName(event.currentTarget.value)}
                        />
                        <PatternInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(event) => setEmail(event.currentTarget.value)}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Grupo de Permissão</FormLabel>
                            <PatternSelect
                                placeholder='Grupo de Permissão' 
                                value={admin} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setAdmin(event.currentTarget.value)}
                            >
                                <option key={1} value={"Administrador"}>Administrador</option>
                                <option key={1} value={"Coordenador"}>Coordenador</option>
                                <option key={1} value={"Suporte"}>Suporte</option>
                                <option key={1} value={"Professor"}>Professor</option>
                                <option key={1} value={"Vendedor"}>Vendedor</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Status</FormLabel>
                            <PatternSelect
                                value={active == true ? 1 : 0} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setActive(Number(event.currentTarget.value) == 1 ? true : false)}
                            >
                                <option key={1} value={1}>Ativo</option>
                                <option key={2} value={0}>Inativo</option>
                            </PatternSelect>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddUser : handleEditUser}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};