import { Button, Center, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function AulasCanceladasPorValorMonetarioModel (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [dayClass, setDayClass] = useState<string>(moment().format('YYYY-MM-DD'));
    const [mesReferenciaAulaDesconta, setMesReferenciaAulaDesconta] = useState<string>(moment().format('YYYY-MM-DD'));

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [students, setStudents] = useState<Student[]>([]);
    const [teacher, setTeacher] = useState<Teachers[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [studentId, setStudentId] = useState<Number>(0);
    const [quantidadeAulaASerCanceladas, setQuantidadeAulaASerCanceladas] = useState<Number>(0);
    const [motivo, setMotivo] = useState<string>("");
    
    useEffect(() => {
        setTeacher(props?.professor);
        setStudents(props?.aluno);
    }, [props])

    const handleAddAulaCancelar = async () => {
        try {
            setIsLoading(() => true);
            const data = {
                MesReferenciaPagamento: dayClass,
                IdProfessor: teacherId,
                IdAluno: studentId,
                Motivo: motivo,
                QuantidadeAulasASerCancelada: quantidadeAulaASerCanceladas,
                MesReferenciaAulaDescontada: mesReferenciaAulaDesconta
            };

            await api.post('api/CancelarAulaPorValorMonetario', data);

            toast.success("Cancelamento feito com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Aula Nao Encontrada")
                toast.error("Aula Nao Encontrada");
            else if(err.response.data.message === "Aluno não pode ser vazio")
                toast.error("O Aluno precisa ser escolhido");
            else if(err.response.data.message === "Professor não pode ser vazio")
                toast.error("O Professor precisa ser escolhido");
            else
                toast.error("Um erro ocorreu ao cadastrar essa Cancelamento!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleUpdateReport = (event : React.ChangeEvent<HTMLSelectElement>) => {
        if(decodedToken?.role !== "Professor"){
            setTeacherId(Number(event.currentTarget.value));  
        }
    };

    return (
        <>
            {
                props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> 
                : 
                <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar Cancelamento de Aula' : 'Excluir Cancelamento de Aula'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                        <PatternSelect 
                            placeholder='Professor' 
                            value={Number(teacherId)} 
                            onChange={handleUpdateReport}
                            >
                            {
                                teacher.map((obj) => (
                                    <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                                ))
                            }
                        </PatternSelect>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                        <PatternSelect 
                            placeholder='Aluno' 
                            value={Number(studentId)} 
                            onChange={(event) => setStudentId(Number(event.currentTarget.value))}
                        >

                            {students.map((group:Student) => {
                                return (<option key={Number(group.id)} value={Number(group.id)}>{group.nome}</option>)
                            })}
                        </PatternSelect>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês Referencia do Pagamento</FormLabel>
                        <Center>
                            <PatternInput
                                type="month"
                                id="dayClass"
                                name="dayClass"
                                value={dayClass}
                                onChange={(event) => setDayClass(event.currentTarget.value)}
                            />
                        </Center>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês Referencia Da Aula Descontada</FormLabel>
                        <Center>
                            <PatternInput
                                type="month"
                                id="dayClass"
                                name="dayClass"
                                value={mesReferenciaAulaDesconta}
                                onChange={(event) => setMesReferenciaAulaDesconta(event.currentTarget.value)}
                            />
                        </Center>
                        <PatternInput
                                type="text"
                                id="motivo"
                                name="motivo"
                                placeholder="Motivo do Cancelamento"
                                value={motivo}
                                onChange={(event) => setMotivo(event.currentTarget.value)}
                            />
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Quantidade de Aulas a Ser Canceladas</FormLabel>
                        <PatternInput
                                type="number"
                                id="quantidade"
                                name="quantidade"
                                placeholder="Quantidade de Aulas a Ser Canceladas"
                                value={Number(quantidadeAulaASerCanceladas)}
                                onChange={(event) => setQuantidadeAulaASerCanceladas(Number(event.currentTarget.value))
                        }
                            />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={handleAddAulaCancelar}>Salvar</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}