import { useContext, useEffect, useState } from "react";
import UserModal from "../../components/DataGrid/AddModals/UserModal";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, Center, Checkbox, CircularProgress, Flex, FormControl, FormLabel, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { decode } from "punycode";
import ClassLaunchModal from "../../components/DataGrid/AddModals/ClassLaunchModelV2";
import PatternCheckbox from "../../components/Patterns/Checkbox";
import ClassLaunchModalOld from "../../components/DataGrid/AddModals/ClassLaunchModelOld";
import moment from "moment";
import PatternSelect from "../../components/Patterns/Select";
import ModelPlataformaBloqueada from "../../components/ModelPlataformaBloqueada";
import { useAulaStatus } from "../../contexts/VerificaAulasLancadasEm24hContext";
import ContratoAlunoNovoProfessor from "../../components/ContratoAlunoNovoProfessor";
import PatternButton from "../../components/Patterns/Button";
import { EditPaymentDataModal } from "./EditPaymentDataModal";
import { FestivityContext } from "../../contexts/FestivityContext";
import { FaCheck, FaCheckSquare, FaSquare } from "react-icons/fa";
import WaitConfirmationPopover from "../../components/WaitConfirmationPopover";
import { toast } from "react-toastify";

interface GetAllUserResponse {
    id: Number;
    id_Aluno: Number;
    nome_Professor: string;
    nome_Aluno: string;
    quantidade_Aula: Number;
    dia_Aula: string;
    ValorAReceber: Number;
    observation: string;
};

interface objectSecondaryResponse {
    id: Number;
    id_Aluno: Number;
    nome_Professor: string;
    nome_Aluno: string;
    quantidade_Aula: Number;
    dia_Aula: string;
    valorAPagar: Number;
    observation: string;
}

interface GetStudentsPorTeacher {
    id_Professor: Number;
    nomeProfessor: string;
    valorAPagar: string;
    quantidadeAulas: number;
    mes: string;
};

interface GetAulasCanceladasPorTeacher {
    id: Number;
    aluno: string;
    aula_Id: Number;
    dt_Aula_Cancelada: Date;
    Motivo: string;
    Quantidade_Aulas_Canceladas: Number;
};

interface GetTeacherResponse {
    name: string;
    id: Number;
};

export default function ClassLaunchConfig() {
    const date = new Date();
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [rowDataStudentPorTeacher, setRowDataStudentPorTeacher] = useState<RelatorioUnificado[]>([]);
    const [rowDataHistoricoAulaPorTeacher, setRowDataHistoricoAulaPorTeacher] = useState<GetStudentsPorTeacher[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [valorAReceber, setValorAReceber] = useState(0);
    const [AutorizationPayment, setAutorizationPayment] = useState<boolean>(false);
    const [month, setMonth] = useState<number>(mesAtual);
    const [year, setYear] = useState<number>(anoAtual);
    const [rowDataAulasCanceladas, setRowDataAulasCanceladas] = useState<GetAulasCanceladasPorTeacher[]>([])
    const [rowDataAulasCanceladasPorValor, setRowDataAulasCanceladasPorValor] = useState<GetAulasCanceladasPorTeacher[]>([])
    const [valorEstimativaReceber, setValorEstimativaReceber] = useState<number>(0.0);
    const [paymentData, setPaymentData] = useState<Teachers>();

    const [professor, setProfessor] = useState<Teachers[]>([]);
    const [estudante, setEstudante] = useState<Student[]>([]);

    const today = date.getDate();

    const { aulaLancada, isLoadingContext, isModelCloseSuccess } = useAulaStatus();

    useEffect(() => {
        if (decodedToken) {
            api.get("/api/TeacherSelectBox").then((response) => {
                setProfessor(response.data.object);
            });

            api.get("api/StudentSelectBox").then((response) => {
                setEstudante(response.data.object);
            });

            api.get(`api/PaymentData/${decodedToken?.TeacherId}`)
                .then(response => {
                    setPaymentData(response.data.object);
                });
        }
    }, [decodedToken])

    useEffect(() => {
        setIsError(() => false);
        // setRowData(() => []);
        setRowDataStudentPorTeacher(() => []);
        setRowDataHistoricoAulaPorTeacher(() => []);

        if (decodedToken?.TeacherId) {
            updateData();
        }

    }, [tick, decodedToken, month, year]);

    const updateData = async () => {
        setIsLoading(() => true);

        try {
            var authorize = await api.get(`api/AuthorizePayment?Teacher_Id=${decodedToken?.TeacherId}`);

            var isAuthorize = authorize.data.object?.isAuthorized ? true : false;

            setAutorizationPayment(isAuthorize);

            const dataClassLaunch = await api.get(`api/ClassLaunch?Teacher_Id=${decodedToken?.TeacherId}`)
            if (dataClassLaunch.data.object.length > 0) {
                try {
                    setRowData(dataClassLaunch.data.object.map((row: any) => {
                        return {
                            nomeProfessor: row.nome_Professor,
                            nomeAluno: row.nome_Aluno,
                            quantidadeAula: row.quantidade_Aula,
                            tempoAulaAluno: row.tempo_Aula_Aluno + " MINUTOS",
                            tipoDeAula: row.typeClass,
                            materialUsadoEmAula: row.materialUsadoEmAula,
                            nivelDoAluno: row.nivelDoAluno,
                            paginaQueParouNoMaterial: row.paginaQueParouNoMaterial,
                            tipoAulaPersonalizada: row.tipoAulaPersonalizada,
                            Observação: row.observation,
                            dia_Aula: formatDate(row.dia_Aula, false),
                            id: row.id
                        };
                    }));
                }
                catch (err) {
                }
            }
            else {
                setRowData([]);
            }

            try {
                if (dataClassLaunch.data.object_Secondary)
                    setValorAReceber(dataClassLaunch?.data?.object_Secondary?.toFixed(2));
            }
            catch (ex) {
            }


            api.get<GetAulasCanceladasPorTeacher[]>(`api/AulasCanceladasPorProfessor/${decodedToken?.TeacherId}/${month}/${year}`)
                .then((response: any) => {
                    setRowDataAulasCanceladas(response.data.object.map((row: any) => {
                        return {
                            Professor: row.professor,
                            Aluno: row.aluno,
                            Motivo: row.motivo,
                            DataDaAulaCancelada: formatDate(row.dt_Aula_Cancelada, false),
                            AulaId: row.aula_Id
                        };
                    }));
                })
                .catch(() => setIsError(() => true))
                .finally(() => setIsLoading(() => false));

            api.get<GetAulasCanceladasPorTeacher[]>(`api/AulasCanceladasPorValorMonetarioPorProfessor/${decodedToken?.TeacherId}/${month}/${year}`)
                .then((response: any) => {
                    setRowDataAulasCanceladasPorValor(response.data.object.map((row: any) => {
                        return {
                            Professor: row.professor,
                            Aluno: row.aluno,
                            Motivo: row.motivo,
                            Quantidade_Aulas_Canceladas: row.quantidadeAulasASerCancelada,
                            MesReferenciaDoDescontoNoPagamento: formatDate(row.mesReferenciaPagamento, false, true),
                            MesReferenciaDaAulaAplicada: formatDate(row.mesReferenciaAulaDescontada, false, true)
                        };
                    }));
                })
                .catch(() => setIsError(() => true))
                .finally(() => setIsLoading(() => false));

            api.get<RelatorioUnificado[]>(`api/RelatorioUnificado?month=${month}&teacherId=${decodedToken?.TeacherId}&ano=${year}`)
                .then((response: any) => {
                    setValorEstimativaReceber(calcularEstimativaValores(response.data.object))
                    setRowDataStudentPorTeacher(response.data.object);
                    setRowDataStudentPorTeacher(response.data.object.map((row: any) => {
                        return {
                            ...row,
                            valorBase: `R$ ${row.valorBase.toFixed(2)}`,
                            valorTotal: `R$ ${row.valorTotal.toFixed(2)}`
                        };
                    }));
                })
                .catch(() => {
                    setIsError(() => true);
                    setValorEstimativaReceber(0);
                })
                .finally(() => setIsLoading(() => false));

            api.get(`api/HistoricoDoAluno/${decodedToken?.TeacherId}/${month}/${year}`)
                .then((response: any) => {
                    setRowDataHistoricoAulaPorTeacher(response.data.object.map((row: any) => {
                        try {
                            return {
                                NomeProfessor: row.nome_Professor,
                                NomeAluno: row.nome_Aluno,
                                QuantidadeDeAulas: row.quantidade_Aula,
                                materialUsadoEmAula: row.materialUsadoEmAula,
                                nivelDoAluno: row.nivelDoAluno,
                                paginaQueParouNoMaterial: row.paginaQueParouNoMaterial,
                                tipoAulaPersonalizada: row.tipoAulaPersonalizada,
                                TypeClass: row.typeClass,
                                DiaDaAula: formatDate(row.dia_Aula),
                                TempoDeAulaDoAluno: row.tempo_Aula_Aluno,
                                Observacao: row.observation,
                                id: row.id
                            };
                        }
                        catch (err) {
                        }
                    }));
                })
                .catch(() => setIsError(() => true))
        }
        catch (err) {
            setIsError(() => true)
            setIsLoading(() => false)
        }
        finally {
            setIsLoading(() => false)
        }
    }

    function calcularEstimativaValores(records: RelatorioUnificado[]): number {
        return records.reduce((sum, record) => sum + record.valorTotal, 0);
    }

    const updateAuthorizationPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setAutorizationPayment(event.currentTarget.checked);

            const data = {
                Teacher_Id: decodedToken?.TeacherId,
                IsAuthorized: !AutorizationPayment
            }

            api.put("/api/AuthorizePayment", data);
        }
        catch (err) {
        }

    }

    const updateYear = async (year: string) => {
        setYear(Number(year))
    }

    const updateTick = () => {
        setTick(tick + 1);
    };

    const { actualYear, festivityDecision, qtyChanges, breakInitialDay } = useContext(FestivityContext);

    const handleSubmit = async (pauseDecision: boolean) => {
        try {
            const data = {
                ProfessorId: Number(decodedToken?.TeacherId),
                AnoDecisao: actualYear,
                DecisaoPausar: pauseDecision,
            };

            await api.post('/api/FestivityDecision', data);
            toast.success('Decisão definida com sucesso!');
            window.location.reload();
        } catch {
            toast.error('Um erro ocorreu ao definir sua decisão... Contate um Administrador!');
        };
    };

    return (
        <>
            {decodedToken ? <ContratoAlunoNovoProfessor professorId={Number(decodedToken?.TeacherId)} /> : null}
            {
                decodedToken?.role === 'Professor' && !isLoadingContext && !aulaLancada && !isModelCloseSuccess ? <ModelPlataformaBloqueada /> : null
            }
            {decodedToken?.role === 'Professor' && festivityDecision !== undefined && festivityDecision !== null && moment().format('MM') === '12' && (
                <WaitConfirmationPopover
                    title={moment().isAfter(moment(`${actualYear}-12-${breakInitialDay}`)) ? 'Data limite para alteração atingida.' : qtyChanges >= 3 ? 'Você já alterou a decisão de pausar 3 vezes' : 'Tem certeza?'}
                    description={moment().isAfter(moment(`${actualYear}-12-${breakInitialDay}`)) ? 'Não é mais possível alterar sua decisão pois a data limite foi atingida.' : qtyChanges >= 3 ? 'Para alterar novamente, favor contatar a Coordenação.' : `Você pode alterar sua decisão de pausa mais ${3 - qtyChanges} vez(es) até ${moment(`${actualYear}-12-${breakInitialDay}`).subtract(1, 'week').format('DD/MM/YYYY')}. Por motivos de segurança, confirme a alteração no botão abaixo:`}
                    onAction={moment().isAfter(moment(`${actualYear}-12-${breakInitialDay}`)) ? undefined : qtyChanges >= 3 ? undefined : () => handleSubmit(!Boolean(festivityDecision))}
                >
                    <Flex as="span" align="center" gap={2} mb={4} cursor="pointer" w="10vw">
                        <Icon as={Boolean(festivityDecision) ? FaCheckSquare : FaSquare} color={Boolean(festivityDecision) ? "blue.500" : 'transparent'} border="2px solid" borderColor={Boolean(festivityDecision) ? "transparent" : 'gray.300'} borderRadius={4} fontSize="1.25rem" />
                        <Text>Quero pausar no fim de {actualYear}</Text>
                    </Flex>
                </WaitConfirmationPopover>
            )}
            {rowData?.length === 0 && (
                <Box mb={4}>
                    {decodedToken?.role === "Professor" ? <ClassLaunchModal modalType="new" updateTick={updateTick} /> : <ClassLaunchModalOld professor={professor} estudante={estudante} modalType="new" updateTick={updateTick} />}
                </Box>
            )}
            {
                decodedToken?.role === "Professor" ?
                    <>
                        <Flex>
                            {
                                today >= 30 || today >= 1 && today <= 5 && !isLoading ?
                                    <Box mr={8}>
                                        <Text color={"red"}>ATENÇÃO PROFESSOR</Text>
                                        <Text>Nesse mês você está previsto para receber R$: {valorAReceber}</Text>
                                        <Text>Você autoriza o pagamento do valor mencionado acima ?</Text>
                                        <PatternCheckbox
                                            isChecked={AutorizationPayment}
                                            onChange={(event) => updateAuthorizationPayment(event)}
                                        >
                                            Autorizo
                                        </PatternCheckbox>
                                    </Box>
                                    :
                                    month == 2 && (today >= 28 || today >= 1) && today <= 5 && !isLoading ?
                                        <Box mr={8}>
                                            <Text color={"red"}>ATENÇÃO PROFESSOR</Text>
                                            <Text>Nesse mês você está previsto para receber R$: {valorAReceber}</Text>
                                            <Text>Você autoriza o pagamento do valor mencionado acima ?</Text>
                                            <PatternCheckbox
                                                isChecked={AutorizationPayment}
                                                onChange={(event) => updateAuthorizationPayment(event)}
                                            >
                                                Autorizo
                                            </PatternCheckbox>
                                        </Box>
                                        :
                                        null
                            }
                            {decodedToken.role === "Professor" && (
                                <Flex flexDir="column">
                                    <Heading fontSize="1.25rem" mb={2}>Seus dados bancários</Heading>
                                    <Flex gap={4}>
                                        <Flex flexDir="column">
                                            <Text><b>Titular da Conta</b>: {paymentData?.NomeTitularidade}</Text>
                                            <Text><b>Banco</b>: {paymentData?.nomeBanco}</Text>
                                            <Text><b>PIX</b>: {paymentData?.pix}</Text>
                                        </Flex>
                                        <Flex flexDir="column">
                                            <Text><b>Agência</b>: {paymentData?.codigoAgencia}</Text>
                                            <Text><b>Conta Corrente</b>: {paymentData?.contaCorrente}</Text>
                                        </Flex>
                                    </Flex>
                                    <EditPaymentDataModal paymentData={paymentData} setPaymentData={setPaymentData} />
                                </Flex>
                            )}
                        </Flex>
                        <br />
                        <Tabs>
                            <TabList>
                                <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Lançamento de Aulas</Tab>
                                {
                                    aulaLancada ?
                                        <>
                                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Relatório Unificado</Tab>
                                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Histórico de Aulas Por Mês</Tab>
                                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Aulas Canceladas</Tab>
                                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Aulas Canceladas Por Quantidade</Tab>
                                        </>
                                        :
                                        null
                                }
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <DataGrid
                                        rowData={rowData}
                                        isLoading={isLoading}
                                        isError={isError}
                                        addModal={decodedToken?.role === "Professor" ? <ClassLaunchModal modalType="new" updateTick={updateTick} /> : <ClassLaunchModalOld modalType="new" updateTick={updateTick} />}
                                        icons={['Deletar']}
                                        deletePath={"ClassLaunch"}
                                        updateTick={updateTick}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='MÊS'
                                        value={month}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                                    >
                                        <option key={1} value={1}>JANEIRO</option>
                                        <option key={2} value={2}>FEVEREIRO</option>
                                        <option key={3} value={3}>MARÇO</option>
                                        <option key={4} value={4}>ABRIL</option>
                                        <option key={5} value={5}>MAIO</option>
                                        <option key={6} value={6}>JUNHO</option>
                                        <option key={7} value={7}>JULHO</option>
                                        <option key={8} value={8}>AGOSTO</option>
                                        <option key={9} value={9}>SETEMBRO</option>
                                        <option key={10} value={10}>OUTUBRO</option>
                                        <option key={11} value={11}>NOVEMBRO</option>
                                        <option key={12} value={12}>DEZEMBRO</option>
                                    </PatternSelect>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='ANO'
                                        value={year}
                                        onChange={(event) => { updateYear(event.currentTarget.value) }}
                                    >
                                        <option key={1} value={2023}>2023</option>
                                        <option key={2} value={2024}>2024</option>
                                        <option key={3} value={2025}>2025</option>
                                        <option key={4} value={2026}>2026</option>
                                        <option key={5} value={2027}>2027</option>
                                        <option key={6} value={2028}>2028</option>
                                        <option key={7} value={2029}>2029</option>
                                        <option key={8} value={2030}>2030</option>
                                        <option key={9} value={2031}>2031</option>
                                        <option key={10} value={2032}>2032</option>
                                        <option key={11} value={2033}>2033</option>
                                        <option key={12} value={2034}>2034</option>
                                    </PatternSelect>
                                    <br />
                                    {
                                        !isLoading ?
                                            `Estimativa de Valores: R$ ${valorEstimativaReceber.toFixed(2)}`
                                            :
                                            null
                                    }
                                    <br />
                                    <DataGrid
                                        rowData={rowDataStudentPorTeacher}
                                        isLoading={isLoading}
                                        isError={isError}
                                        enableExport={true}
                                        updateTick={updateTick}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='MÊS'
                                        value={month}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                                    >
                                        <option key={1} value={1}>JANEIRO</option>
                                        <option key={2} value={2}>FEVEREIRO</option>
                                        <option key={3} value={3}>MARÇO</option>
                                        <option key={4} value={4}>ABRIL</option>
                                        <option key={5} value={5}>MAIO</option>
                                        <option key={6} value={6}>JUNHO</option>
                                        <option key={7} value={7}>JULHO</option>
                                        <option key={8} value={8}>AGOSTO</option>
                                        <option key={9} value={9}>SETEMBRO</option>
                                        <option key={10} value={10}>OUTUBRO</option>
                                        <option key={11} value={11}>NOVEMBRO</option>
                                        <option key={12} value={12}>DEZEMBRO</option>
                                    </PatternSelect>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='ANO'
                                        value={year}
                                        onChange={(event) => { updateYear(event.currentTarget.value) }}
                                    >
                                        <option key={1} value={2023}>2023</option>
                                        <option key={2} value={2024}>2024</option>
                                        <option key={3} value={2025}>2025</option>
                                        <option key={4} value={2026}>2026</option>
                                        <option key={5} value={2027}>2027</option>
                                        <option key={6} value={2028}>2028</option>
                                        <option key={7} value={2029}>2029</option>
                                        <option key={8} value={2030}>2030</option>
                                        <option key={9} value={2031}>2031</option>
                                        <option key={10} value={2032}>2032</option>
                                        <option key={11} value={2033}>2033</option>
                                        <option key={12} value={2034}>2034</option>
                                    </PatternSelect>
                                    <br />
                                    <DataGrid
                                        rowData={rowDataHistoricoAulaPorTeacher}
                                        isLoading={isLoading}
                                        isError={isError}
                                        enableExport={true}
                                        updateTick={updateTick}
                                        icons={['Deletar']}
                                        deletePath={"ClassLaunch"}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='MÊS'
                                        value={month}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                                    >
                                        <option key={1} value={1}>JANEIRO</option>
                                        <option key={2} value={2}>FEVEREIRO</option>
                                        <option key={3} value={3}>MARÇO</option>
                                        <option key={4} value={4}>ABRIL</option>
                                        <option key={5} value={5}>MAIO</option>
                                        <option key={6} value={6}>JUNHO</option>
                                        <option key={7} value={7}>JULHO</option>
                                        <option key={8} value={8}>AGOSTO</option>
                                        <option key={9} value={9}>SETEMBRO</option>
                                        <option key={10} value={10}>OUTUBRO</option>
                                        <option key={11} value={11}>NOVEMBRO</option>
                                        <option key={12} value={12}>DEZEMBRO</option>
                                    </PatternSelect>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='ANO'
                                        value={year}
                                        onChange={(event) => { updateYear(event.currentTarget.value) }}
                                    >
                                        <option key={1} value={2023}>2023</option>
                                        <option key={2} value={2024}>2024</option>
                                        <option key={3} value={2025}>2025</option>
                                        <option key={4} value={2026}>2026</option>
                                        <option key={5} value={2027}>2027</option>
                                        <option key={6} value={2028}>2028</option>
                                        <option key={7} value={2029}>2029</option>
                                        <option key={8} value={2030}>2030</option>
                                        <option key={9} value={2031}>2031</option>
                                        <option key={10} value={2032}>2032</option>
                                        <option key={11} value={2033}>2033</option>
                                        <option key={12} value={2034}>2034</option>
                                    </PatternSelect>
                                    <br />
                                    <DataGrid
                                        rowData={rowDataAulasCanceladas}
                                        isLoading={isLoading}
                                        isError={isError}
                                        updateTick={updateTick}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='MÊS'
                                        value={month}
                                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                                    >
                                        <option key={1} value={1}>JANEIRO</option>
                                        <option key={2} value={2}>FEVEREIRO</option>
                                        <option key={3} value={3}>MARÇO</option>
                                        <option key={4} value={4}>ABRIL</option>
                                        <option key={5} value={5}>MAIO</option>
                                        <option key={6} value={6}>JUNHO</option>
                                        <option key={7} value={7}>JULHO</option>
                                        <option key={8} value={8}>AGOSTO</option>
                                        <option key={9} value={9}>SETEMBRO</option>
                                        <option key={10} value={10}>OUTUBRO</option>
                                        <option key={11} value={11}>NOVEMBRO</option>
                                        <option key={12} value={12}>DEZEMBRO</option>
                                    </PatternSelect>
                                    <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                                    <PatternSelect
                                        placeholder='ANO'
                                        value={year}
                                        onChange={(event) => { updateYear(event.currentTarget.value) }}
                                    >
                                        <option key={1} value={2023}>2023</option>
                                        <option key={2} value={2024}>2024</option>
                                        <option key={3} value={2025}>2025</option>
                                        <option key={4} value={2026}>2026</option>
                                        <option key={5} value={2027}>2027</option>
                                        <option key={6} value={2028}>2028</option>
                                        <option key={7} value={2029}>2029</option>
                                        <option key={8} value={2030}>2030</option>
                                        <option key={9} value={2031}>2031</option>
                                        <option key={10} value={2032}>2032</option>
                                        <option key={11} value={2033}>2033</option>
                                        <option key={12} value={2034}>2034</option>
                                    </PatternSelect>
                                    <br />
                                    <DataGrid
                                        rowData={rowDataAulasCanceladasPorValor}
                                        isLoading={isLoading}
                                        isError={isError}
                                        updateTick={updateTick}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </>

                    :
                    "Apenas professores conseguem ver está aba, para mais informações utilize a aba Relatório de Alunos por Professor"
            }

        </>
    );
};