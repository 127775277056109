import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Tooltip,
    Text,
    Button,
    Box,
    Center,
    FormControl,
    FormLabel,
    Flex,
    Icon,
    Input,
} from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FaQuestionCircle } from 'react-icons/fa';
import { useJwt } from 'react-jwt';
import { toast } from 'react-toastify';
import PatternButton from '../../components/Patterns/Button';
import PatternCheckbox from '../../components/Patterns/Checkbox';
import PatternSelect from '../../components/Patterns/Select';
import { api } from '../../services/api';
import Select, { SingleValue } from 'react-select';
import CalendarWeekDay from '../../components/CalendarWeekDay';
import HistoricoModel from './HistoricoModal';

interface NewClassModalProps {
    cellCoordHint: boolean;
    dayOfWeek: string;
    calendarData: CalendarData;
    teacherId: number | undefined;
    teacherName: string | undefined;
    setTick: React.Dispatch<React.SetStateAction<number>>;
    dayClasses: CalendarData[];
    allCalendar: CalendarData[];
    role: string | undefined;
    students: Student[];
    studentsExperimental: StudentExperimental[];
    users: User[];
};

export default function NewClassModal({ cellCoordHint, dayOfWeek, calendarData, teacherId, teacherName, setTick, dayClasses, allCalendar, role, students, users, studentsExperimental }: NewClassModalProps) {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const currentIdx = dayClasses.findIndex(c => c.id === calendarData.id);
    const doubleDate = dayClasses[currentIdx + 1];

    const [wasClicked, setWasClicked] = useState<boolean>(false);
    const [choosenStudent, setChoosenStudent] = useState<SingleValue<{ value: number; label: string }>>();
    const [choosenStudentId, setChoosenStudentId] = useState<number>(0);
    const [classType, setClassType] = useState<string>('');
    const [isDouble, setIsDouble] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [daysOfWeek, setDaysOfWeek] = useState<number[]>([]);
    const [reservedStudentName, setReservedStudentName] = useState<string>('');
    const [reservedDate, setReservedDate] = useState<string>(moment().format('YYYY-MM-DD'));

    const handleOpen = () => {
        setDaysOfWeek([calendarData?.diaSemana]);
        setWasClicked(true);
        onOpen();
    };

    useEffect(() => {
        setIsDouble(students.find((x: Student) => x.id === choosenStudentId)?.tempoDeAula === 60)
    }, [choosenStudentId])

    const handleClose = () => {
        const singleValue: SingleValue<{ value: number; label: string }> = { value: 0, label: "" };
        setChoosenStudent(singleValue);
        setWasClicked(false);
        onClose();
        setChoosenStudentId(0);
        setClassType('');
        setIsDouble(false);
        setDaysOfWeek([calendarData?.diaSemana]);
        setReservedStudentName('');
        setReservedDate(moment().format('YYYY-MM-DD'));
    };
    let studanteExperimentalName = studentsExperimental.find(s => s.id === calendarData.aluno_Id)?.nome;

    if (!studanteExperimentalName)
        studanteExperimentalName = "AULA EXPERIMENTAL"
    let studentName = students.find(s => s.id === calendarData.aluno_Id)?.nome;

    if (!studentName) {
        studentName = 'Carregando...'
    } else {
        studentName = `${studentName.substring(0, 20)}${studentName.length > 20 ? '...' : ''}`
    };

    const handleCreateClass = async () => {
        if (classType === '') {
            toast.warn('Favor escolher um tipo de agendamento!');
            return;
        };

        if (classType.includes('Aula') && choosenStudentId === 0) {
            toast.warn('Favor escolher um aluno para vincular neste agendamento!');
            return;
        };

        setIsLoading(() => true);

        try {
            const data: any = {
                horaInicialAula: calendarData.horaInicialAula,
                horaFinalAula: calendarData.horaFinalAula,
                diaSemana: [calendarData.diaSemana],
                professor_Id: teacherId,
                aluno_Id: choosenStudentId,
                id_QuemCriou: decodedToken?.Id,
                tipoAula: classType,
                atualizacaoEmMassa: false,
            };

            if (classType === 'Reservado') {
                data["nomeAluno"] = reservedStudentName;
                data["previsaoInicioAulas"] = new Date(reservedDate);
            };

            if (role === 'Professor' && classType === 'Aula normal') {
                await api.post('api/Agenda/CriarAgendamentoProfessor', data);
            } else {
                await api.post('api/Agenda/CriarAgendamento', data);
            }

            if (isDouble && doubleDate && doubleDate.tipoAula === null) {
                const dataDouble: any = {
                    horaInicialAula: doubleDate.horaInicialAula,
                    horaFinalAula: doubleDate.horaFinalAula,
                    diaSemana: [doubleDate.diaSemana],
                    professor_Id: teacherId,
                    aluno_Id: choosenStudentId,
                    id_QuemCriou: decodedToken?.Id,
                    tipoAula: classType,
                    atualizacaoEmMassa: false,
                };

                if (classType === 'Reservado') {
                    dataDouble["nomeAluno"] = reservedStudentName;
                    dataDouble["previsaoInicioAulas"] = new Date(reservedDate);
                };

                await api.post('api/Agenda/CriarAgendamento', dataDouble);
            };

            if (daysOfWeek.length > 1) {
                daysOfWeek.forEach(async (day) => {
                    const dayOfWeekData: any = {
                        horaInicialAula: calendarData.horaInicialAula,
                        horaFinalAula: calendarData.horaFinalAula,
                        diaSemana: [day],
                        professor_Id: teacherId,
                        aluno_Id: choosenStudentId,
                        id_QuemCriou: decodedToken?.Id,
                        tipoAula: classType,
                        atualizacaoEmMassa: false,
                    };

                    if (classType === 'Reservado') {
                        dayOfWeekData["nomeAluno"] = reservedStudentName;
                        dayOfWeekData["previsaoInicioAulas"] = new Date(reservedDate);
                    };

                    await api.post('api/Agenda/CriarAgendamento', dayOfWeekData);

                    if (isDouble) {
                        const doubleDataDayOfWeekData: any = {
                            horaInicialAula: doubleDate.horaInicialAula,
                            horaFinalAula: doubleDate.horaFinalAula,
                            diaSemana: [day],
                            professor_Id: teacherId,
                            aluno_Id: choosenStudentId,
                            id_QuemCriou: decodedToken?.Id,
                            tipoAula: classType,
                            atualizacaoEmMassa: false,
                        };

                        if (classType === 'Reservado') {
                            doubleDataDayOfWeekData["nomeAluno"] = reservedStudentName;
                            doubleDataDayOfWeekData["previsaoInicioAulas"] = new Date(reservedDate);
                        };

                        await api.post('api/Agenda/CriarAgendamento', doubleDataDayOfWeekData);
                    };
                });
            };

            setTimeout(() => {
                setTick(prev => prev + 1);
                toast.success('Agendamento criado com sucesso!');
                handleClose();
            }, isDouble ? 1000 : 500);
        } catch (ex) {
            toast.error('Ocorreu um erro ao criar este agendamento. Contate um Administrador!');
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleDeleteClass = async () => {
        setIsLoading(() => true);

        try {
            await api.post('api/Agenda/ApagarAgendamento', {
                professor_Id: teacherId,
                aluno_Id: calendarData?.aluno_Id,
                dia: [calendarData?.diaSemana],
                horaInicial: calendarData?.horaInicialAula,
                horaFim: calendarData?.horaFinalAula,
                apagarAgendamentoMassa: false,
            });

            setTick(prev => prev + 1);
            toast.success('Agendamento deletado com sucesso!');
            handleClose();
        } catch {
            toast.error('Ocorreu um erro ao deletar este agendamento. Contate um Administrador!');
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleChangeClassType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setClassType(event.currentTarget.value);
        if (!event.currentTarget.value.includes('Aula')) {
            setChoosenStudentId(0);
        };
    };

    const handleSelectDayOfWeek = (dayOfWeek: number) => {
        let newDaysOfWeek = [...daysOfWeek];

        if (newDaysOfWeek.find(day => day === dayOfWeek)) {
            newDaysOfWeek = [...newDaysOfWeek.filter(day => day !== dayOfWeek)];
        } else {
            newDaysOfWeek.push(dayOfWeek);
        };

        setDaysOfWeek(newDaysOfWeek);
    };

    return (
        <>
            <Box
                as="span"
                cursor={
                    role !== "Professor" ||
                        (role === "Professor" && (
                            !calendarData?.tipoAula ||
                            ["Reposição de aula", "Horário ocupado", "Aula normal", ""].includes(calendarData?.tipoAula)
                        ))
                        ? "pointer"
                        : "default"
                }
                onClick={
                    role !== "Professor"
                        ? handleOpen
                        : role === "Professor" && (
                            !calendarData?.tipoAula ||
                            ["Reposição de aula", "Horário ocupado", "Break", "Aula normal"].includes(calendarData?.tipoAula)
                        )
                            ? handleOpen
                            : () => { }
                }
            >
                <Tooltip
                    placement="top"
                    bg="primary.500"
                    mb={-1.5}
                    hasArrow
                    label={
                        cellCoordHint && `${dayOfWeek} - ${moment(calendarData.horaInicialAula).format('HH:mm')} - ${moment(calendarData.horaFinalAula).format('HH:mm')}`
                    }
                >
                    <Text
                        color={
                            calendarData.tipoAula === 'Aula normal' ? 'black' :
                                calendarData.tipoAula === 'Aula experimental' ? 'white' :
                                    calendarData?.tipoAula === 'Horário ocupado' ? 'white' :
                                        calendarData.tipoAula === 'Break' ? 'black' :
                                            calendarData.tipoAula === 'Aluno em Pausa' ? 'white' :
                                                calendarData.tipoAula === 'Reposição de aula' ? 'black' : "white"
                        }
                        bg={
                            calendarData.tipoAula === 'Aula normal' ? 'green' :
                                calendarData.tipoAula === 'Aula experimental' ? 'primary.500' :
                                    calendarData?.tipoAula === 'Horário ocupado' ? 'black' :
                                        calendarData.tipoAula === 'Reservado' ? 'red.600' :
                                            calendarData.tipoAula === 'Break' ? '#d1d1d1' :
                                                calendarData.tipoAula === 'Aluno em Pausa' ? 'purple' :
                                                    calendarData.tipoAula === 'Reposição de aula' ? 'gold' : "white"
                        }
                        borderTop="1px solid black"
                        borderBottom="1px solid black"
                        border={
                            wasClicked ?
                                `2px dashed ${calendarData.tipoAula === 'Aula experimental' ? 'white' :
                                    calendarData.tipoAula === 'Aula normal' ? 'white' : '#005EB2'}`
                                : '2px black initial'
                        }
                    >
                        {
                            calendarData.tipoAula === 'Aula normal' ?
                                studentName :
                                calendarData.tipoAula === 'Reposição de aula' ?
                                    `${calendarData.tipoAula.split(' ')[0]} - ${studentName}` :
                                    calendarData.tipoAula === 'Aula experimental' ?
                                        studanteExperimentalName :
                                        ["Horário ocupado", "Reservado", "Break"].includes(calendarData?.tipoAula) ?
                                            calendarData.tipoAula :
                                            calendarData.tipoAula === 'Aluno em Pausa' ? `Aluno em Pausa (${studentName})`: "ㅤ"
                        }
                    </Text>
                </Tooltip>
            </Box>


            <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Agendar Aula</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center flexDir="column">
                            {calendarData?.tipoAula === null ? (
                                <>
                                    <Text>Você está criando um agendamento para o Professor <b>{teacherName}</b> em:</Text>
                                    <Text fontSize="1rem" letterSpacing="1.5px" textTransform="uppercase" fontWeight="bold" m="16px 0">{['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][calendarData.diaSemana]} - {moment(calendarData.horaInicialAula).format('HH:mm')} às {moment(calendarData.horaFinalAula).format('HH:mm')}</Text>

                                    <FormControl>
                                        <FormLabel fontWeight="bolder">Tipo do Agendamento</FormLabel>
                                        <PatternSelect
                                            id="class-type"
                                            name="class-type"
                                            value={classType}
                                            onChange={handleChangeClassType}
                                        >
                                            <Box as="option" value={""} disabled>Selecione o Tipo deste Agendamento...</Box>
                                            {role === 'Professor' &&
                                                <>
                                                    <Box as="option" value={"Aula normal"} color="green">Aula normal</Box>
                                                    <Box as="option" value="Reposição de aula" color="#c99400">Reposição de aula</Box>
                                                    <Box as="option" value={"Horário ocupado"} color="black">Horário ocupado</Box>
                                                    <Box as="option" value={"Break"} color="gray">Break</Box>
                                                </>
                                            }
                                            {role !== 'Professor' && (
                                                <>
                                                    <Box as="option" value={"Aula normal"} color="green">Aula normal</Box>
                                                    <Box as="option" value={"Aluno em Pausa"} color="purple">Aluno em Pausa</Box>
                                                    <Box as="option" value={"Aula experimental"} color="primary.500">Aula experimental</Box>
                                                    <Box as="option" value={"Horário ocupado"} color="black">Horário ocupado</Box>
                                                    <Box as="option" value={"Reservado"} color="red.600">Reservado</Box>
                                                    <Box as="option" value={"Break"} color="gray">Break</Box>
                                                </>
                                            )}
                                        </PatternSelect>
                                    </FormControl>
                                    {(classType.toLowerCase().includes('aula') || classType === 'Aluno em Pausa') && (
                                        <>
                                            <FormControl mt={4}>
                                                <FormLabel fontWeight="bolder">Aluno</FormLabel>
                                                <Select
                                                    id="student"
                                                    name="student"
                                                    placeholder="Escolha um aluno..."
                                                    noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                                    value={choosenStudent}
                                                    onChange={(event) => { setChoosenStudent(event); setChoosenStudentId(Number(event?.value)) }}
                                                    options={classType === 'Aula experimental' ? studentsExperimental.map(t => { return { value: t.id, label: t.nome } }) : students.map(t => { return { value: t.id, label: t.nome } })}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel display="flex" flexDirection="row" gap={2} mt={4}>
                                                    <PatternCheckbox isChecked={isDouble} isDisabled={true} />
                                                    <Text cursor="pointer" color={!doubleDate || doubleDate.tipoAula !== null ? 'gray' : 'initial'}>Dobradinha</Text>
                                                    <Tooltip
                                                        placement="right"
                                                        hasArrow
                                                        label={!doubleDate || doubleDate.tipoAula !== null ? 'A dobradinha está inativada pois o próximo horário está ocupado ou é inexistente!' : `Está opção é selecionado automaticamente de acordo com o plano do aluno.`}
                                                        bg={!doubleDate || doubleDate.tipoAula !== null ? 'gray' : "primary.500"}
                                                    >
                                                        <span>
                                                            <Icon as={FaQuestionCircle} color="primary.500" />
                                                        </span>
                                                    </Tooltip>
                                                </FormLabel>
                                            </FormControl>
                                        </>
                                    )}
                                    {!(classType?.toLowerCase()?.includes('aula normal') && role === 'Professor')
                                        && (
                                            <>
                                                <Flex align="center" justify="center">
                                                    <Text mt={4}>Repetir para os dias:</Text>
                                                    <Tooltip
                                                        placement="top"
                                                        hasArrow
                                                        label={`Clique nos dias em que deseja repetir o agendamento do Horário ${moment(calendarData?.horaInicialAula).format('HH:mm')} - ${moment(calendarData?.horaFinalAula).format('HH:mm')}. (Os dias selecionados são mostrados com um tom de Azul mais intenso)`}
                                                        bg="primary.500"
                                                    >
                                                        <span>
                                                            <Icon as={FaQuestionCircle} color="primary.500" />
                                                        </span>
                                                    </Tooltip>
                                                </Flex>
                                                <Flex mt={2} flexDir="row" align="center" justify="center" gap={1}>
                                                    <CalendarWeekDay
                                                        label="Segunda-feira"
                                                        weekDayNumber={1}
                                                        calendarData={calendarData}
                                                        allCalendar={allCalendar}
                                                        daysOfWeek={daysOfWeek}
                                                        handleSelectDayOfWeek={handleSelectDayOfWeek}
                                                    />
                                                    <CalendarWeekDay
                                                        label="Terça-feira"
                                                        weekDayNumber={2}
                                                        calendarData={calendarData}
                                                        allCalendar={allCalendar}
                                                        daysOfWeek={daysOfWeek}
                                                        handleSelectDayOfWeek={handleSelectDayOfWeek}
                                                    />
                                                    <CalendarWeekDay
                                                        label="Quarta-feira"
                                                        weekDayNumber={3}
                                                        calendarData={calendarData}
                                                        allCalendar={allCalendar}
                                                        daysOfWeek={daysOfWeek}
                                                        handleSelectDayOfWeek={handleSelectDayOfWeek}
                                                    />
                                                    <CalendarWeekDay
                                                        label="Quinta-feira"
                                                        weekDayNumber={4}
                                                        calendarData={calendarData}
                                                        allCalendar={allCalendar}
                                                        daysOfWeek={daysOfWeek}
                                                        handleSelectDayOfWeek={handleSelectDayOfWeek}
                                                    />
                                                    <CalendarWeekDay
                                                        label="Sexta-feira"
                                                        weekDayNumber={5}
                                                        calendarData={calendarData}
                                                        allCalendar={allCalendar}
                                                        daysOfWeek={daysOfWeek}
                                                        handleSelectDayOfWeek={handleSelectDayOfWeek}
                                                    />
                                                </Flex>
                                            </>
                                        )}
                                    {classType === 'Reservado' && (
                                        <>
                                            <FormControl mt={2}>
                                                <FormLabel htmlFor="student-name" fontWeight="bolder">Nome do Aluno</FormLabel>
                                                <Input
                                                    id="student-name"
                                                    name="student-name"
                                                    type="text"
                                                    value={reservedStudentName}
                                                    onChange={(event) => setReservedStudentName(event.currentTarget.value)}
                                                />
                                            </FormControl>
                                            <FormControl mt={2}>
                                                <FormLabel htmlFor="reserved-date" fontWeight="bolder">Data Início Aluno</FormLabel>
                                                <Input
                                                    id="reserved-date"
                                                    name="reserved-date"
                                                    type="date"
                                                    max={moment().add(15, "days").format('YYYY-MM-DD')}
                                                    value={reservedDate}
                                                    onChange={(event) => setReservedDate(event.currentTarget.value)}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Text>Você está deletando a aula para o(a) Professor(a) <b>{teacherName}</b> em:</Text>
                                    <Text fontSize="1rem" letterSpacing="1.5px" textTransform="uppercase" fontWeight="bold" m="16px 0">{['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][calendarData.diaSemana]} - {moment(calendarData.horaInicialAula).format('HH:mm')} às {moment(calendarData.horaFinalAula).format('HH:mm')}</Text>
                                    {calendarData?.tipoAula.includes('Aula') ? (
                                        <Text>O(a) aluno(a) <b>{students.find(s => s.id === calendarData?.aluno_Id)?.nome}</b> não terá mais esta aula nesse dia!</Text>
                                    ) : (
                                        <Text>O(a) professor(a) não estará mais em <b>{calendarData?.tipoAula}</b> nesta data!</Text>
                                    )}
                                    <Text>Deseja mesmo prosseguir?</Text>
                                    {calendarData?.tipoAula === 'Reservado' && (
                                        <Box mt={4} textAlign="center">
                                            <Text fontWeight="bolder">Informações da Reserva</Text>
                                            <Text>Reservado por: {calendarData.quemCriou}</Text>
                                            <Text>Para o Aluno: {calendarData.nomeAluno}</Text>
                                            <Text>Previsto para início em: {moment(calendarData.previsaoInicioAulas).format('DD/MM/YYYY')}</Text>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Center>
                    </ModalBody>
                    <ModalFooter>
                        <FormLabel display="flex">
                            <Button variant="ghost" mr={3} onClick={handleClose} isDisabled={isLoading}>
                                Cancelar
                            </Button>
                            {
                                decodedToken?.role !== 'Professor' ?
                                    <HistoricoModel
                                        calendarData={calendarData}
                                        teacherId={teacherId}
                                        handleCloseOld={handleClose}
                                    />
                                    :
                                    null
                            }
                            <PatternButton mr={3} bg={calendarData?.tipoAula === null ? "initial" : "red.600 !important"} isDisabled={(!doubleDate || doubleDate.tipoAula !== null) && isDouble ? true : false} onClick={calendarData?.tipoAula === null ? handleCreateClass : handleDeleteClass} isLoading={isLoading}>{calendarData.tipoAula === null ? 'Criar Agendamento' : 'Deletar Agendamento'}</PatternButton>
                            {
                                (!doubleDate || doubleDate.tipoAula !== null) && isDouble ?
                                    <Tooltip
                                        placement="right"
                                        hasArrow
                                        label={'Não é possível cadastrar um agendamento de 60 min, pois existe uma aula a seguir!'}
                                        bg={!doubleDate || doubleDate.tipoAula !== null ? 'gray' : "primary.500"}
                                    >
                                        <span>
                                            <Icon as={FaQuestionCircle} color="primary.500" />
                                        </span>
                                    </Tooltip>
                                    :
                                    null
                            }
                        </FormLabel>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}