import Select from 'react-select';

export default function PatternMultipleSellect({...rest}) {
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      {...rest}
    />
  );
}