import moment from 'moment';

export function extractOnlyNumbers(value: string) {
    return value.replace(/\D/g, '');
};

export function formatCep(value: string) {
    value = extractOnlyNumbers(value);
    value = value.replace(/(\d{5})(\d{3})/, '$1-$2');
    
    return value;
};

export function formatCpfCnpj(value: string) {
    value = extractOnlyNumbers(value);

    if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else {
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    };
    
    return value;
};

export function formatCurrency(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function formatPhone(value: string) {
    value = extractOnlyNumbers(value);

    if (value.length <= 10) {
        value = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    } else {
        value = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    };
    
    return value;
};

export function formatRg(value: string) {
    value = value.replace(/-/g, '').replace(/\./g, '').toUpperCase();

    value = value.replace(/(.{2})(.{3})(.{3})(.{1})/, '$1.$2.$3-$4');

    return value;
};

export function formatDate(value: string, time: boolean = false, day = false) {
    if(value){
        if (time) {
            return moment(value).format('DD/MM/YYYY - HH:mm:ss');
        };
    
        if(day){
            return moment(value).format('MM/YYYY');
        }
        
        return moment(value).format('DD/MM/YYYY');
    }
    return "";
};

export function formatMoney(value: string) {
    let valueNumber = Number(extractOnlyNumbers(value));
    const currency = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valueNumber / 100);

    return String(currency);
};

export function TratamentoData(data: string){
    switch (data.toUpperCase()){
        case "SEGUNDA":
            return "SEGUNDA-FEIRA";
        case "TERCA":
            return "TERCA-FEIRA";
        case "QUARTA":
            return "QUARTA-FEIRA";
        case "QUINTA":
            return "QUINTA-FEIRA";
        case "SEXTA":
            return "SEXTA-FEIRA";
        case "SABADO":
            return "SABADO";
        case "DOMINGO":
            return "DOMINGO";
    }
}