import moment from "moment";
import 'moment/locale/pt-br'
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { api } from "../services/api";

interface FestivityContextData {
    actualYear: number;
    festivityDecision: boolean | null | undefined;
    festivityBreakPeriod: boolean;
    qtyChanges: number;
    breakInitialDay: string;
}

interface FestivityProviderProps {
    children: React.ReactNode;
};

export const FestivityContext = createContext<FestivityContextData>(
    {} as FestivityContextData
);

export function FestivityProvider({ children }: FestivityProviderProps) {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [actualYear] = useState<number>(Number(moment().format('YYYY')));
    const [festivityDecision, setFestivityDecision] = useState<boolean | null | undefined>(false);
    const [festivityBreakPeriod, setFestivityBreakPeriod] = useState<boolean>(false);
    const [qtyChanges, setQtyChanges] = useState<number>(0);
    const [breakInitialDay, setBreakInitialDay] = useState<string>('00');

    useEffect(() => {
        if (moment().format('MM') === '12') {
            console.log(12);
            let lastFridayBeforeChristmas = moment().endOf('year');

            let fridays = 0;
            while (fridays <= 1) {
                if (lastFridayBeforeChristmas.format('dddd') === 'sexta-feira') {
                    lastFridayBeforeChristmas = lastFridayBeforeChristmas.subtract(1, 'day');
                    continue;
                };

                lastFridayBeforeChristmas = lastFridayBeforeChristmas.subtract(1, 'day');

                if (lastFridayBeforeChristmas.format('dddd') === 'sexta-feira')
                    fridays++;
            };

            setBreakInitialDay(lastFridayBeforeChristmas.format('DD'));

            if (moment().isBetween(moment(`${actualYear}-12-${String(Number(lastFridayBeforeChristmas.format('DD')) - 1).padStart(2, '0')}`), moment(`${actualYear + 1}-01-02`), 'date'))
                setFestivityBreakPeriod(true);

            if (moment().isBetween(moment(`${actualYear}-11-30`), moment(`${actualYear + 1}-01-01`), 'date') && decodedToken?.role === 'Professor') {
                api.get(`/api/FestivityDecision/${Number(decodedToken?.TeacherId)}`)
                    .then(response => {
                        setFestivityDecision(response?.data?.object?.decisaoPausar);
                        setQtyChanges(response?.data?.object_Secondary);
                    });
            };
        };
    }, [decodedToken]);

    return (
        <FestivityContext.Provider value={{ actualYear, festivityDecision, festivityBreakPeriod, qtyChanges, breakInitialDay }}>
            {children}
        </FestivityContext.Provider>
    );
};