import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Image,
    Center,
} from '@chakra-ui/react'
import { useContext } from 'react';
import { FestivityContext } from '../../contexts/FestivityContext';

export default function FestivityBreakModal() {
    const { actualYear, festivityDecision, festivityBreakPeriod, breakInitialDay } = useContext(FestivityContext);

    return (
        <>
            <Modal isOpen={Boolean(festivityDecision) && festivityBreakPeriod} onClose={() => { }} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalBody textAlign="center">
                        <Center flexDir="column" pt={4} pb={4}>
                            <Image src="../king-logo-black.png" />
                            <Text>Você está em período de pausa entre {breakInitialDay}/12/{actualYear} até 01/01/{actualYear + 1}.</Text>
                            <Text>Nós lhe desejamos boas festas!</Text>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};