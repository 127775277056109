import React from 'react';
import { Box, Heading, Flex, Icon } from '@chakra-ui/react';
import { FaUsers } from 'react-icons/fa';
import InfoCard from './InfoCard';

interface InfoCliente {
  ativos: number;
}

const Clientes: React.FC<InfoCliente> = ({ativos} : InfoCliente) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} width={{ base: '90%', lg: '30%' }}>
      <Flex align="center" mb={4}>
        <Icon as={FaUsers} w={6} h={6} mr={2} />
        <Heading size="md">Clientes</Heading>
      </Flex>
      <InfoCard titulo="Ativos" valor={ativos} corValor="green.500" />
    </Box>
  );
};

export default Clientes;
