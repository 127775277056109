import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternButton from "../../components/Patterns/Button";
import PatternSelect from "../../components/Patterns/Select";
import moment from "moment";

interface GetAllUserResponse {
    id_Professor: Number;
    nomeProfessor: string;
    valorAPagar: string;
    quantidadeAulas: number;
    mes: string;
    autorizado_Pagamento: string;
};

export default function ReportSalaryTeacher() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [typeData, setTypeData] = useState<string>("ReportSalaryTeacherAutomatic");
    const [tick, setTick] = useState<number>(0);
    const [month, setMonth] = useState<number>(mesAtual);
    const [valorTotalMes, setValorTotalMes] = useState<number>(0);
    const [year, setYear] = useState<number>(anoAtual);

    useEffect(() => {
        setIsLoading(() => false);
        setIsError(() => false);
        // setRowData(() => []);
    }, [tick, decodedToken]);

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }

    const handleUpdateReport = () =>{
        setIsLoading(() => true);
        if(typeData === "ReportSalaryTeacherAutomatic"){
            api.get<GetAllUserResponse[]>(`api/ReportValuesAutomatic`)
            .then((response: any) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        nomeProfessor: row.nomeProfessor,
                        valorAPagar: row.valorAPagar
                    }
                }))
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }
        else{
            setIsLoading(() => true)
            api.get<GetAllUserResponse[]>(`api/ReportValuesTeacher?month=${month}&ano=${year}`)
            .then((response : any) => {
                const valorAPagarMesTotal = response.data.object[0]?.valorTotalMes;
                setValorTotalMes(valorAPagarMesTotal);
                try {
                    setRowData(response.data.object.map((row: any) => {
                        return {
                            nomeProfessor: row.nomeProfessor,
                            valorAPagar: row.valorAPagar.toFixed(2),
                            valorBase: row.valorHoraAula,
                            quantidadeAulas: row.quantidadeAulas,
                            pagamentoAutorizado: row.autorizado_Pagamento,
                            NomeTitularidade: row.nomeTitularidade,
                            NomeBanco: row.nomeBanco,
                            CodigoAgencia: row.codigoAgencia,
                            ContaCorrente: row.contaCorrente,
                            PIX: row.pix,
                        };
                    }));
                } catch (e) {console.log(e);}

            })
            .catch((err) => {
                setIsError(() => true)
            })
            .finally(() => setIsLoading(() => false));
        }
    }

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Tipo de Relatório</FormLabel>
                <PatternSelect 
                    placeholder='Tipo de Relatório' 
                    value={typeData} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTypeData(event.currentTarget.value)}
                >
                    <option key={1} value={"ReportSalaryTeacherAutomatic"}>RELATÓRIO SALÁRIO AUTOMÁTICO</option>
                    <option key={2} value={"ReportSalaryTeacher"}>RELATÓRIO SALÁRIO POR LANÇAMENTO</option>
                </PatternSelect>

                {
                    typeData !== "ReportSalaryTeacherAutomatic" ? 
                    <>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                        <PatternSelect 
                            placeholder='MÊS' 
                            value={month} 
                            onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                        >
                            <option key={1} value={1}>JANEIRO</option>
                            <option key={2} value={2}>FEVEREIRO</option>
                            <option key={3} value={3}>MARÇO</option>
                            <option key={4} value={4}>ABRIL</option>
                            <option key={5} value={5}>MAIO</option>
                            <option key={6} value={6}>JUNHO</option>
                            <option key={7} value={7}>JULHO</option>
                            <option key={8} value={8}>AGOSTO</option>
                            <option key={9} value={9}>SETEMBRO</option>
                            <option key={10} value={10}>OUTUBRO</option>
                            <option key={11} value={11}>NOVEMBRO</option>
                            <option key={12} value={12}>DEZEMBRO</option>
                        </PatternSelect>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                        <PatternSelect 
                            placeholder='ANO' 
                            value={year} 
                            onChange={(event) => { updateYear(event.currentTarget.value)}}
                        >
                            <option key={1} value={2023}>2023</option>
                            <option key={2} value={2024}>2024</option>
                            <option key={3} value={2025}>2025</option>
                            <option key={4} value={2026}>2026</option>
                            <option key={5} value={2027}>2027</option>
                            <option key={6} value={2028}>2028</option>
                            <option key={7} value={2029}>2029</option>
                            <option key={8} value={2030}>2030</option>
                            <option key={9} value={2031}>2031</option>
                            <option key={10} value={2032}>2032</option>
                            <option key={11} value={2033}>2033</option>
                            <option key={12} value={2034}>2034</option>
                        </PatternSelect>
                    </>
                    :
                    null
                }

            </FormControl>
            
            <PatternButton mt={[4, 4, 8]} onClick={handleUpdateReport} isLoading={isLoading}>Gerar relatório</PatternButton>

            <Box mt={6}>
                {
                    !isLoading && rowData.length > 0 ? <p>Valor Total no Mês: R$: {valorTotalMes}</p> : null
                }
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={decodedToken?.role === 'Administrador' ? true : false}
                />
            </Box>
        </>   
    );
};