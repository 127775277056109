import { Helmet } from "react-helmet";
import PatternCard from "../../components/Patterns/Card";
import AlunosQueComecamEmBreve from "./AlunosComecamEmBreve";
import AlunosComPendencia from "./AlunosComPendencia";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

export function Index() {
    return (
        <>
            <Helmet>
                <title>King | Index</title>
            </Helmet>
            <Tabs isFitted variant='enclosed' colorScheme="teal" paddingTop={"5%"} paddingLeft={"5%"}>
                <TabList>
                <Tab
                _selected={{ bg: 'blue.500', color: 'white' }}
                bg="gray.300" // Cor de fundo para as abas não selecionadas
                color="black" // Cor do texto para as abas não selecionadas
            >
                Alunos que Começam em Breve
            </Tab>
            <Tab
                _selected={{ bg: 'blue.500', color: 'white' }}
                bg="gray.300" // Cor de fundo para as abas não selecionadas
                color="black" // Cor do texto para as abas não selecionadas
            >
                Alunos que ja Iniciaram e ainda tem Pendências
            </Tab>
                </TabList>

                <TabPanels >
                    <TabPanel paddingTop={"-2%"}>
                            <AlunosQueComecamEmBreve />
                    </TabPanel>
                    <TabPanel>
                            <AlunosComPendencia />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
}

export default Index; // Aqui você exporta o componente Index como padrão
