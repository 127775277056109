import { useCookies } from "react-cookie";
import { useJwt } from 'react-jwt';
import { Flex, Avatar, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spacer, IconButton, Box } from '@chakra-ui/react';
import PreferencesModal from './PreferencesModal';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { IoIosNotifications } from "react-icons/io";
import NotificationSection from "./NotificacaoSection";

interface LeftSectionProps {
    ALunosQUeComecamEmVreve: any;
    ALunosComPendncia: any;
}

export default function Topbar() {
    const [cookies, , removeCookies] = useCookies(["loginToken"]);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [alunosQueComecamEmBreve, setAlunosQueComecamEmBreve] = useState([]);
    const [alunosComPendencia, setAlunosComPendencia] = useState([]);
    const [isError, setIsError] = useState<boolean>(false);
    const navigate = useNavigate();

    const rolePermissions: Record<string, () => boolean> = {
        Administrador: () => true,
        Coordenador: () => true,
        Suporte: () => true,
        Vendedor: () => true,
        Professor: () => false,
    };

    let ShowAlunosEmBrevePendecia = false;
    if (decodedToken && decodedToken.role && rolePermissions.hasOwnProperty(decodedToken.role)) {
        ShowAlunosEmBrevePendecia = rolePermissions[decodedToken.role]();
    }

    useEffect(() => {
        const GetAlunosQueComecamEmBreve = () => {
            api.get('api/CountGetStartingNext')
                .then(response => {
                    setAlunosQueComecamEmBreve(response.data.object);
                })
                .catch(() => setIsError(true));
        };

        const GetAlunosQueComPendencia = () => {
            api.get('api/GetPendingStudentsNextStart')
                .then(response => {
                    setAlunosComPendencia(response.data.object);
                })
                .catch(() => setIsError(true));
        };

        GetAlunosQueComecamEmBreve();
        GetAlunosQueComPendencia();
    }, []);

    const handleLogout = () => {
        removeCookies('loginToken', { path: '/' });
        navigate('/login');
    };

    const MenuVendedor = (
        <Flex
            position="fixed"
            justifyContent="space-between"
            alignItems="center"
            minH="16"
            w="100vw"
            bg="rgba(255, 255, 255, 0.5)"
            boxShadow="0 2px 32px 0 rgba(0, 0, 0, 0.37)"
            backdropFilter="blur(1.2px)"
            border="1px solid rgba(255, 255, 255, 0.18)"
            zIndex="2 !important"
            pr={4}
            pl={4}
        >
            <Flex flex="1" justifyContent="center">
                <LeftSection ALunosQUeComecamEmVreve={alunosQueComecamEmBreve} ALunosComPendncia={alunosComPendencia} />
            </Flex>
            <NotificationSection />
            <RightSection decodedToken={decodedToken} handleLogout={handleLogout} />
        </Flex>
    );

    const MenuProfessor = (
        <Flex
            position="fixed"
            justifyContent="flex-end"
            align="center"
            minH="16"
            w="100vw"
            bg="rgba(255, 255, 255, 0.5)"
            boxShadow="0 2px 32px 0 rgba(0, 0, 0, 0.37)"
            backdropFilter="blur(1.2px)"
            border="1px solid rgba(255, 255, 255, 0.18)"
            zIndex="2 !important"
            pr={4}
            pl={4}
        >
            <NotificationSection />
            <RightSection decodedToken={decodedToken} handleLogout={handleLogout} />
        </Flex>
    );

    if (ShowAlunosEmBrevePendecia) {
        return MenuVendedor;
    } else {
        return MenuProfessor;
    }
}


function LeftSection({ ALunosQUeComecamEmVreve, ALunosComPendncia }: LeftSectionProps) {
    return (
        <Flex alignItems="center">
            <span style={{ fontWeight: 'bold', marginRight: '0.2rem' }}>Alunos que iniciam em breve:</span>
            <span style={{ color: 'green', fontWeight: 'bold' }}>{ALunosQUeComecamEmVreve}</span>

            <Spacer />

            <span style={{ fontWeight: 'bold', marginLeft: '1rem', marginRight: '0.2rem' }}>Alunos com pendência:</span>
            <span style={{ color: 'red', fontWeight: 'bold' }}>{ALunosComPendncia}</span>
        </Flex>
    );
}

function RightSection({ decodedToken, handleLogout }: { decodedToken: any; handleLogout: () => void; }) {
    return (
        <Flex alignItems="center">
            <Menu>
                <MenuButton position="relative">
                    <Avatar name={decodedToken?.Username} />
                </MenuButton>
                <MenuList>
                    <MenuGroup title="Minha Conta">
                        <PreferencesModal _hover={{ bg: "secondary.500", color: "textColor" }} />
                        <MenuItem _hover={{ bg: "secondary.500", color: "textColor" }} onClick={handleLogout}>Sair</MenuItem>
                    </MenuGroup>
                </MenuList>
            </Menu>
        </Flex>
    );
}
