import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";
import moment from "moment";
import AulasCanceladasModel from "../../components/DataGrid/AddModals/AulasCanceladasModel";

interface GetAllUserResponse {
    Id: Number;
    Professor: string;
    Aluno: string;
    Cancelado: boolean;
    Dt_Aula_Cancelada: string;
    Aula_Id: Number;
};

interface GetUsuallyUser {
    name: string;
    id: Number;
}

interface GetTeacher {
    nome: string;
    id: Number;
}

export default function AulasCanceladasPorProfessorPorValorMonetario(props:any) {
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [year, setYear] = useState<number>(anoAtual);
    const [month, setMonth] = useState<number>(mesAtual);
    const [teacher, setTeacher] = useState<Teachers[]>([]);
    const [aluno, setAluno] = useState<Student[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);

    useEffect(() => {
        setTeacher(props?.professor);
        setAluno(props?.aluno);
    }, [props])

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);
        // setRowData(() => []);

        setTeacher(props?.professor);
 
        updateData();

    }, [tick, decodedToken, month, teacherId, year]);

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }


    const updateData = async () => {
        setIsLoading(() => true);
        if(decodedToken && teacherId !== 0){
            try{
                const dataClassLaunch = await api.get(`api/AulasCanceladasPorValorMonetarioPorProfessor/${teacherId}/${month}/${year}`)
                if(dataClassLaunch.data.object.length > 0) {
                    setRowData(dataClassLaunch.data.object.map((row: any) => {
                        return {
                            id: row.id,
                            Aluno: row.aluno,
                            MesReferenciaDoDescontoDoPagamento: formatDate(row.mesReferenciaPagamento, false, true),
                            DiaAulaCancelada: formatDate(row.dt_Aula_Cancelada),
                            Motivo: row.motivo,
                            QuantidadeDeAulasCanceladas: row.quantidadeAulasASerCancelada
                        };
                    }));
                }
                else{
                    setRowData([]);
                }
            }
            catch(err){
                setIsError(() => true)
            }
            finally{
                setIsLoading(() => false)
            }
        }
    }
    
    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
            <PatternSelect 
                placeholder='MÊS' 
                value={month} 
                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
            >
                <option key={1} value={1}>JANEIRO</option>
                <option key={2} value={2}>FEVEREIRO</option>
                <option key={3} value={3}>MARÇO</option>
                <option key={4} value={4}>ABRIL</option>
                <option key={5} value={5}>MAIO</option>
                <option key={6} value={6}>JUNHO</option>
                <option key={7} value={7}>JULHO</option>
                <option key={8} value={8}>AGOSTO</option>
                <option key={9} value={9}>SETEMBRO</option>
                <option key={10} value={10}>OUTUBRO</option>
                <option key={11} value={11}>NOVEMBRO</option>
                <option key={12} value={12}>DEZEMBRO</option>
            </PatternSelect>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
            <PatternSelect 
                placeholder='ANO' 
                value={year} 
                onChange={(event) => { updateYear(event.currentTarget.value)}}
            >
                <option key={1} value={2023}>2023</option>
                <option key={2} value={2024}>2024</option>
                <option key={3} value={2025}>2025</option>
                <option key={4} value={2026}>2026</option>
                <option key={5} value={2027}>2027</option>
                <option key={6} value={2028}>2028</option>
                <option key={7} value={2029}>2029</option>
                <option key={8} value={2030}>2030</option>
                <option key={9} value={2031}>2031</option>
                <option key={10} value={2032}>2032</option>
                <option key={11} value={2033}>2033</option>
                <option key={12} value={2034}>2034</option>
            </PatternSelect>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Professores</FormLabel>
                <PatternSelect 
                    placeholder='Professor' 
                    value={Number(teacherId)} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTeacherId(Number(event.currentTarget.value))}
                >
                    {
                        teacher ?
                            teacher.map((obj:Teachers) => (<option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>))
                        :
                            null
                    }
                </PatternSelect>
            </FormControl>
            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <AulasCanceladasModel aluno={aluno} professor={teacher} modalType="new" updateTick={updateTick} />
                </Box>
            )}
            {
                year !== 0 && month !== 0  && teacherId !== 0 ?
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    icons={['Deletar']}
                    updateTick={updateTick}
                    deletePath={"CancelarAula"}
                />
                :
                null
            }
        </>
        
    );
};