import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { api } from '../services/api';
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';

// Definir o tipo para o contexto
interface AulaContextType {
    aulaLancada: boolean | undefined;
    isLoadingContext: boolean;
    isModelCloseSuccess: boolean;
    setIsModelCloseSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

// Criar um contexto
const AulaContext = createContext<AulaContextType>({
    aulaLancada: undefined,
    isLoadingContext: true,
    isModelCloseSuccess: false,
    setIsModelCloseSuccess: () => {}
});

// Definir os tipos de propriedades do componente AulaProvider
interface AulaProviderProps {
    children: ReactNode;
}

export const AulaProvider: React.FC<AulaProviderProps> = ({ children }) => {
    const [verificaProfessorLancouAulaDasUltimas24H, setVerificaProfessorLancouAulaDasUltimas24H] = useState<boolean>(false);
    const [isModelCloseSuccess, setIsModelCloseSuccess] = useState<boolean>(false);
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [isLoadingContext, setIsLoading] = useState(true);

    const verificaSeJaLancouAula = async () => {
        if (decodedToken && decodedToken?.role === 'Professor') {
            try {
                const response = await api.get(`/api/VerificaProfessorLancouAulaDasUltimas24H?professorId=${decodedToken?.TeacherId}`);
                const aulaLancada = response.data.object;
                setVerificaProfessorLancouAulaDasUltimas24H(aulaLancada);
                if (!aulaLancada) {
                    toast.error("Lance suas aulas atrasadas para desbloquear a plataforma");
                }
            } catch (error) {
                console.error("Erro ao verificar aulas:", error);
            } finally {
                setIsLoading(false);
            }
        } else if (decodedToken?.role !== 'Professor') {
            setVerificaProfessorLancouAulaDasUltimas24H(true);
            setIsLoading(false);
        }
        return false;
    };

    useEffect(() => {
        let isMounted = true; 
        const checkAula = async () => {
            const aulaLancada = await verificaSeJaLancouAula();
            if (!aulaLancada && isMounted) {
                setTimeout(checkAula, 60000);
            }
        };
        checkAula();

        return () => {
            isMounted = false; 
        };
    }, [decodedToken]);

    return (
        <AulaContext.Provider value={{ aulaLancada: verificaProfessorLancouAulaDasUltimas24H, isLoadingContext, isModelCloseSuccess, setIsModelCloseSuccess }}>
            {children}
        </AulaContext.Provider>
    );
};

export const useAulaStatus = () => {
    return useContext(AulaContext);
};
