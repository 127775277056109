import { Button, Text, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { formatPhone } from "../../../utils/formatter";

interface Teacher {
    nome: String,
    id: Number
}

export default function NovosAlunosExperimentaisModel (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataAula, setDataAula] = useState<string>(moment().format('YYYY-MM-DD'));
    const [nomeAluno, setNomeAluno] = useState<string>("");
    const [nomeVendedor, setNomeVendedor] = useState<string>("");
    const [professorVinculado, setProfessorVinculado] = useState<Teachers[]>([]);
    const [professorVinculadoEscolhido, setProfessorVinculadoEscolhido] = useState<Number>(0);
    const [horaAula, setHoraAula] = useState<string>("")
    const [telefone, setTelefone] = useState<string>("")
    const [statusAula, setStatusAula] = useState<string>("")
    const [statusFechamento, setStatusFechamento] = useState<string>("");

    useEffect(() => {
        setProfessorVinculado(props?.professor)
    }, [props])

    useEffect(() => {
        if (props?.modalType === 'edit') {
            setHoraAula(props?.data?.HorarioDaAula);
            setTelefone(props?.data?.NumeroDeTelefone);
            setDataAula(moment(moment(props?.data?.DataDaAulaExperimental, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setNomeAluno(props?.data?.Nome);
            setNomeVendedor(props?.data?.Vendedor)
            setProfessorVinculadoEscolhido(props?.data?.teacherId);
            setStatusAula(props?.data?.StatusDaAula);
            setStatusFechamento(props?.data?.StatusDoFechamento);
        }
    }, [decodedToken])

    const handleAddNewStudent = async () => {

        if (dataAula === '') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (nomeAluno === '') { 
            toast.warning("O campo Data de Inicio das Aulas está vazio");
            return;
        };

        if (nomeVendedor === '') { 
            toast.warning("O campo Valor Fechado está vazio");
            return;
        };

        if (professorVinculadoEscolhido === 0) { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };      
        
        if(horaAula === ''){
            toast.warning("O campo Hora Aula está vazio");
            return;
        }

        if(telefone === ''){
            toast.warning("O campo Telefone está vazio");
            return;
        }

        try {
            setIsLoading(() => true);
            const data = {
                Nome: nomeAluno,
                Vendedor: nomeVendedor,
                Teacher_Id: Number(professorVinculadoEscolhido),
                Data_Aula_Experimental: dataAula,
                Hora_Da_Aula: String(horaAula),
                Numero_Telefone_Aluno: telefone,
                HoraInicialAula: moment(`${moment().utc().format('YYYY-MM-DD')}T${horaAula}:00.000Z`).utc().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]')
            };

            await api.post('api/NovosAlunosExperimentais', data);

            toast.success("Aluno criado com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario ja existente")
                toast.error("Aluno Já Existente");
            else if(err.response.data.message === "Ja existe aula nesse horario para o professor")
                toast.error("Já existe aula nesse horário para o professor!");
            else
                toast.error("Um erro ocorreu ao cadastrar este Aluno!");
        } finally {
            setIsLoading(() => false);
            onClose();
        };
    };

    const handleEditNewStudent = async () => {

        if (dataAula === '') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (nomeAluno === '') { 
            toast.warning("O campo Data de Inicio das Aulas está vazio");
            return;
        };

        if (nomeVendedor === '') { 
            toast.warning("O campo Valor Fechado está vazio");
            return;
        };

        if (professorVinculadoEscolhido === 0) { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };  

        if(horaAula === ''){
            toast.warning("O campo Hora Aula está vazio");
            return;
        }

        if(telefone === ''){
            toast.warning("O campo Telefone está vazio");
            return;
        }

        try {
            setIsLoading(() => true);
            const data = {
                Id: props?.data?.id,
                Nome: nomeAluno,
                Vendedor: nomeVendedor,
                Teacher_Id: Number(professorVinculadoEscolhido),
                Data_Aula_Experimental: dataAula,
                Hora_Da_Aula: String(horaAula),
                Numero_Telefone_Aluno: telefone,
                Status_Aula: statusAula,
                Status_Fechamento: statusFechamento,
                HoraInicialAula: moment(`${moment().utc().format('YYYY-MM-DD')}T${horaAula}:00.000Z`).utc().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]')
            };

            await api.put('api/NovosAlunosExperimentais', data);

            toast.success("Aluno Editado com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario ja existente")
                toast.error("Aluno Já Existente");
            else
                toast.error("Um erro ocorreu ao atualizar este Aluno!");
        } finally {
            setIsLoading(() => false);
            onClose();
        };
    };

    return (
        <>
            {
                props?.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> 
                : 
                <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
            }
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props?.modalType === 'new' ? 'Adicionar novo Aluno' : 'Alterar Aluno'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Data da Aula</p>
                        <PatternInput
                            type="date"
                            id="dt_Aula"
                            name="dt_Aula"
                            placeholder="Data da Aula"
                            value={dataAula}
                            onChange={(event) => setDataAula(event.currentTarget.value)}
                        />
                        <p>Nome do Aluno</p>
                        <PatternInput
                            type="text"
                            id="NomeAluno"
                            name="NomeAluno"
                            placeholder="Nome do Aluno"
                            value={nomeAluno}
                            onChange={(event) => setNomeAluno(event.currentTarget.value)}
                        />
                        <p>Hora da Aula</p>
                        <PatternInput
                            type="time"
                            id="student-HoraDaAula"
                            name="student-HoraDaAula"
                            placeholder="Hora da Aula"
                            maxLength={5}
                            value={horaAula}
                            onChange={(event) => setHoraAula(event.currentTarget.value)}
                        />
                        <p>Telefone Principal do Aluno</p>
                        <PatternInput
                            type="text"
                            id="student-PhoneNumber1"
                            name="student-PhoneNumber1"
                            placeholder="Telefone Principal do Aluno..."
                            maxLength={20}
                            value={telefone}
                            onChange={(event) => setTelefone(formatPhone(event.currentTarget.value))}
                        />
                        <p>Nome do Vendedor</p>
                        <PatternInput
                            type="text"
                            id="NomeVendedor"
                            name="NomeVendedor"
                            placeholder="Nome do Vendedor"
                            value={nomeVendedor}
                            onChange={(event) => setNomeVendedor(event.currentTarget.value)}
                        />
                        {
                            professorVinculado ? 
                            <>
                                <p>Professor</p>
                                <PatternSelect placeholder='Professor' value={Number(professorVinculadoEscolhido)} onChange={(event) => setProfessorVinculadoEscolhido(Number(event?.currentTarget?.value))}>
                                    {professorVinculado?.map(teacher => {
                                        return (<option key={Number(teacher?.id)} value={Number(teacher?.id)}>{teacher?.nome}</option>)
                                    })}
                                </PatternSelect>
                            </>
                            :
                            <Text>Professor Vinculado:</Text>
                        }
                        {
                            props?.modalType === 'edit' ?
                            <>
                                <p>STATUS DA AULA</p>
                                <PatternSelect placeholder=' ' value={statusAula} onChange={(event) => setStatusAula(event?.currentTarget?.value)}>
                                    <option key={1} value={"PRESENCA"}>PRESENÇA</option>
                                    <option key={2} value={"NOSHOW"}>NO-SHOW</option>
                                    <option key={3} value={"CANCELADO"}>CANCELADO</option>
                                </PatternSelect>
                                <p>STATUS DO FECHAMENTO</p>
                                <PatternSelect placeholder=' ' value={statusFechamento} onChange={(event) => setStatusFechamento(event?.currentTarget?.value)}>
                                    <option key={1} value={"Nao_Fechado"}>NÃO FECHADO</option>
                                    <option key={2} value={"Fechado"}>FECHADO</option>
                                </PatternSelect>
                            </>
                            
                            :
                            null
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddNewStudent : handleEditNewStudent}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}