import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import PatternCard from "../../components/Patterns/Card";
import TeacherWithStudentConfig from "./TeacherWithStudentConfig";

export function TeacherWithStudent() {
    const [cookies] = useCookies(['loginToken']);
    
    return (
        <>
            <Helmet>
                <title>King | Configuração Aluno/Professor</title>
            </Helmet>
            <PatternCard title="Configuração Aluno/Professor">
                <Tabs>
                    <TabList>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Gerenciar Alunos</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <TeacherWithStudentConfig />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </PatternCard>
        </>
    );
};