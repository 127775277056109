import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternButton from "../../components/Patterns/Button";
import PatternSelect from "../../components/Patterns/Select";
import moment from "moment";
import Select, { SingleValue } from 'react-select';
import { toast } from "react-toastify";

interface GetTeacherResponse {
    nome: string;
    id: Number;
};

export default function ReportStudent() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();

    const [rowData, setRowData] = useState<RelatorioUnificado[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [month, setMonth] = useState<number>(mesAtual);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [teachers, setTeachers] = useState<Teachers[]>([]);
    const [year, setYear] = useState<number>(anoAtual);
    const [valorEstimativaReceber, setValorEstimativaReceber] = useState<number>(0.0);

    const [choosenTeacher, setChoosenTeacher] = useState<SingleValue<{ value: number; label: string }>>();
    const [choosenTeacherId, setChoosenTeacherId] = useState<number>(0);

    useEffect(() => {
        if(decodedToken && decodedToken?.role !== 'Professor'){
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeachers(response.data.object);
            })   
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }

        setIsLoading(() => false);
        setIsError(() => false);
        // setRowData(() => []);
    }, [decodedToken]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }

    const handleUpdateReport = () =>{
        if (choosenTeacherId === 0) { 
            toast.warning("É obrigatório escolher um professor");
            return;
        };

        setIsLoading(() => true)
        api.get<RelatorioUnificado[]>(`api/RelatorioUnificado?month=${month}&teacherId=${choosenTeacherId}&ano=${year}`)
        .then((response : any) => {
            setValorEstimativaReceber(calcularEstimativaValores(response.data.object))
            setRowData(response.data.object);
        })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false));
        updateTick();
    }

    function calcularEstimativaValores(records: RelatorioUnificado[]): number {
        return records.reduce((sum, record) => sum + record.valorTotal, 0);
    }

    return (
        <>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                <PatternSelect 
                    placeholder='MÊS' 
                    value={month} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                >
                    <option key={1} value={1}>JANEIRO</option>
                    <option key={2} value={2}>FEVEREIRO</option>
                    <option key={3} value={3}>MARÇO</option>
                    <option key={4} value={4}>ABRIL</option>
                    <option key={5} value={5}>MAIO</option>
                    <option key={6} value={6}>JUNHO</option>
                    <option key={7} value={7}>JULHO</option>
                    <option key={8} value={8}>AGOSTO</option>
                    <option key={9} value={9}>SETEMBRO</option>
                    <option key={10} value={10}>OUTUBRO</option>
                    <option key={11} value={11}>NOVEMBRO</option>
                    <option key={12} value={12}>DEZEMBRO</option>
                </PatternSelect>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                <PatternSelect 
                    placeholder='ANO' 
                    value={year} 
                    onChange={(event) => { updateYear(event.currentTarget.value)}}
                >
                    <option key={1} value={2023}>2023</option>
                    <option key={2} value={2024}>2024</option>
                    <option key={3} value={2025}>2025</option>
                    <option key={4} value={2026}>2026</option>
                    <option key={5} value={2027}>2027</option>
                    <option key={6} value={2028}>2028</option>
                    <option key={7} value={2029}>2029</option>
                    <option key={8} value={2030}>2030</option>
                    <option key={9} value={2031}>2031</option>
                    <option key={10} value={2032}>2032</option>
                    <option key={11} value={2033}>2033</option>
                    <option key={12} value={2034}>2034</option>
                </PatternSelect>

                <FormLabel fontWeight="bolder">Professor</FormLabel>
                <Select
                    id="teacher"
                    name="teacher"
                    placeholder="Escolha um professor..."
                    noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                    value={choosenTeacher}
                    onChange={(event) => { setChoosenTeacher(event); setChoosenTeacherId(Number(event?.value)) }}
                    options={teachers.map(t => { return { value: t.id, label: t.nome } })}
                />
            </FormControl>
            
            <PatternButton mt={[4, 4, 8]} onClick={handleUpdateReport} isLoading={isLoading}>Gerar relatório</PatternButton>

            <Box mt={6}>
                {
                    !isLoading ?
                        `Estimativa de Valores: R$ ${valorEstimativaReceber.toFixed(2)}`
                    :
                    null
                }
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={true}
                    updateTick={updateTick}
                />
            </Box>
        </>   
    );
};