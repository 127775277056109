import {
    Button,
    Center,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import Select, { MultiValue } from "react-select";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button";
import PatternSelect from "../../Patterns/Select";
import { addMinutes, parseISO, format } from 'date-fns';
import moment from "moment";
import PatternInput from "../../Patterns/Input";

interface AgendaProfessor {
    id: number;
    horaInicialAula: string;
    horaFinalAula: string;
    diaSemana: number;
    professor_Id: number;
    aluno_Id: number;
}

interface AvailableTime {
    id: number;
    professor: any;
    horaInicialDisponivel: string;
    horaFinalDisponivel: string;
    diaSemanaDisponivel: string;
    tipoAula: any;
}

const formatTime = (timeString: string) => {
    const date = new Date(timeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

const parseLocalTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return `1900-01-01T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000`;
};

export default function TeacherWithStudentModal(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [teacher, setTeacher] = useState<Teachers[]>([]);
    const [teacherId, setTeacherId] = useState<number>(0);
    const [student, setStudent] = useState<Student[]>([]);
    const [studentId, setStudentId] = useState<number>(0);

    const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const [selectedTime, setSelectedTime] = useState<string>("");
    const [selectedDays, setSelectedDays] = useState<MultiValue<{ value: number; label: string }>>([]);
    const [dataInicio, setDataInicio] = useState<string>(moment().format('YYYY-MM-DD'));

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setTeacher(props?.teacher);
        setStudent(props?.student);
    }, [props]);

    useEffect(() => {
        if (decodedToken) {
            if (props?.modalType === 'edit') {
                setStudentId(props?.data?.IdAluno);
                setTeacherId(props?.data?.IdProfessor);
                fetchAvailableTimes(props?.data?.IdProfessor);
                setDataInicio(moment(props?.data?.DataInicioAluno, "DD/MM/YYYY").format("YYYY-MM-DD"))
            };
        }
    }, [decodedToken]);

    function addMinutesToTime(dateString: string, minutes: number): string {
        const date = parseISO(dateString);
        const newDate = addMinutes(date, minutes);
        return format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    }

    const handleAddBond = async () => {
        if (teacherId === 0 || studentId === 0 || selectedTime === "" || selectedDays.length === 0) {
            toast.warning("Preencha todas as informações!");
            return;
        };

        try {
            setIsLoading(() => true);

            const dataBond = {
                Teacher_Id: teacherId,
                Student_Id: studentId,
                DataInicio: dataInicio,
            };

            await api.post('api/AssociatedTeacherWithStudent', dataBond);

            const [horaInicialString, horaFinalString] = selectedTime.split('-').map(t => t.trim());

            const horaInicial = parseLocalTime(horaInicialString);
            const horaFinal = parseLocalTime(horaFinalString);

            const data = {
                HoraInicialAula: horaInicial,
                HoraFinalAula: horaFinal,
                DiaSemana: selectedDays.map(day => day.value),
                Professor_Id: teacherId,
                Aluno_Id: studentId,
                Id_QuemCriou: null,
                TipoAula: "Aula normal",
                NomeAluno: null,
                PrevisaoInicioAulas: null,
                AtualizacaoEmMassa: true
            };

            try {
                if (student.find((s:Student) => s.id === studentId)?.temDobradinha === true || student.find((s:Student) => s.id === studentId)?.tempoDeAula === 60) {
                    const doubleDataDayOfWeekData: any = {
                        HoraInicialAula: addMinutesToTime(horaInicial, 30),
                        HoraFinalAula: addMinutesToTime(horaFinal, 30),
                        DiaSemana: selectedDays.map(day => day.value),
                        Professor_Id: teacherId,
                        Aluno_Id: studentId,
                        Id_QuemCriou: null,
                        TipoAula: "Aula normal",
                        NomeAluno: null,
                        PrevisaoInicioAulas: null,
                        AtualizacaoEmMassa: true
                    };

                    await api.post('api/Agenda/CriarAgendamento', data);
                    await api.post('api/Agenda/CriarAgendamento', doubleDataDayOfWeekData);
                } else { 
                    await api.post('api/Agenda/CriarAgendamento', data);
                }
            }
            catch {
                toast.success("Erro ao inserir aluno na agenda do professor");
            }
            
            toast.success("Vinculado criado com sucesso!");
            props?.updateTick();
        } catch (err: any) {
            if (err.response?.data?.message.includes("Aluno Ja Vinculado")) {
                var professor = err.response.data.message.split(",");
                toast.error(`Ops, parece que este aluno já está vinculado há o professor: ${professor[1]}!`);
            }
            else {
                toast.error("Um erro ocorreu ao cadastrar este vinculo!");
            }
        } finally {
            setIsLoading(() => false);
        };
    };


    const removerAlunoDaAgendaDoProfessor = async (oldTeacherId: number, teacherId: number, studentId: number, selectedTime: string, selectedDays: MultiValue<{ value: number; label: string }>) => {
        try {
            const response = await api.get(`api/Agenda/ProcurarAgendaPorProfessor?professor_Id=${oldTeacherId}`);
            const agendaProfessor: AgendaProfessor[] = response.data.object;
            const agendaFiltradaPorAluno = agendaProfessor.filter((agenda: AgendaProfessor) => agenda.aluno_Id === studentId);
    
            // Extrai as horas inicial e final fornecidas
            const timeParts = selectedTime.split('-').map(t => t.trim());
            if (timeParts.length !== 2) {
                toast.error("Formato de hora inválido.");
                return;
            }
    
            const [horaInicialString, horaFinalString] = timeParts;
            
            // Verifica se horaFinalString está vazio
            if (!horaInicialString || !horaFinalString) {
                toast.error("Hora inicial ou final está vazia.");
                return;
            }
    
            const selectedDaysArray = selectedDays.map(day => day.value);
    
            // Verifica se existe uma correspondência exata na agenda
            // const isAgendaMatching = agendaFiltradaPorAluno.some((agenda: AgendaProfessor) => {
            //     if (!agenda.horaInicialAula || !agenda.horaFinalAula) {
            //         return false;
            //     }
    
            //     const agendaHoraInicial = agenda.horaInicialAula.split('T')[1].slice(0, 5); // HH:MM
            //     const agendaHoraFinal = agenda.horaFinalAula.split('T')[1].slice(0, 5); // HH:MM
    
            //     const matching = selectedDaysArray.includes(agenda.diaSemana) &&
            //         agendaHoraInicial === horaInicialString &&
            //         agendaHoraFinal === horaFinalString;

    
            //     return matching;
            // });

            // if (!isAgendaMatching) {
                // Coleta de dias da semana únicos usando um objeto
            const diasParaApagarObj: { [key: number]: boolean } = {};
            agendaFiltradaPorAluno.forEach((agenda: AgendaProfessor) => {
                diasParaApagarObj[agenda.diaSemana] = true;
            });

            if(agendaFiltradaPorAluno && agendaFiltradaPorAluno.length > 0) {
                const diasParaApagar = Object.keys(diasParaApagarObj).map(Number);

                // Obter horários do primeiro agendamento para a chamada de exclusão

                let horaInicialExemplo = agendaFiltradaPorAluno[0].horaInicialAula;
                let horaFinalExemplo = agendaFiltradaPorAluno[0].horaFinalAula;

                for (const item of agendaFiltradaPorAluno) {
                    if (item.horaInicialAula < horaInicialExemplo) {
                        horaInicialExemplo = item.horaInicialAula;
                    }
                    if (item.horaFinalAula > horaFinalExemplo) {
                        horaFinalExemplo = item.horaFinalAula;
                    }
                }

                // Apaga os agendamentos existentes

                try {
                    await api.post('/api/agenda/ApagarAgendamento', {
                        professor_Id: oldTeacherId,
                        aluno_Id: studentId,
                        dia: diasParaApagar,
                        horaInicial: horaInicialExemplo,
                        horaFim: horaFinalExemplo,
                        apagarAgendamentoMassa: true
                    });
                }
                catch (err) {
                    toast.error("Erro ao remover aluno da agenda do prof");
                }
            }

            const data = {
                HoraInicialAula: addMinutesToTime(`1900-01-01T${horaInicialString}:00.000`, 0),
                HoraFinalAula: addMinutesToTime(`1900-01-01T${horaFinalString}:00.000`, 0),
                DiaSemana: selectedDaysArray,
                Professor_Id: teacherId,
                Aluno_Id: studentId,
                Id_QuemCriou: null,
                TipoAula: "Aula normal",
                NomeAluno: null,
                PrevisaoInicioAulas: null,
                AtualizacaoEmMassa: true
            };

            try {
                if (student.find((s:Student) => s.id === studentId)?.temDobradinha === true || student.find((s:Student) => s.id === studentId)?.tempoDeAula === 60) {                   
                    const doubleDataDayOfWeekData: any = {
                        HoraInicialAula: addMinutesToTime(`1900-01-01T${horaInicialString}:00.000`, 30),
                        HoraFinalAula: addMinutesToTime(`1900-01-01T${horaFinalString}:00.000`, 30),
                        DiaSemana: selectedDays.map(day => day.value),
                        Professor_Id: teacherId,
                        Aluno_Id: studentId,
                        Id_QuemCriou: null,
                        TipoAula: "Aula normal",
                        NomeAluno: null,
                        PrevisaoInicioAulas: null,
                        AtualizacaoEmMassa: true
                    };

                    await api.post('api/Agenda/CriarAgendamento', data);
                    await api.post('api/Agenda/CriarAgendamento', doubleDataDayOfWeekData);
                } else {
                    await api.post('api/Agenda/CriarAgendamento', data);
                }
            }
            catch {
                toast.error("Erro ao inserir aluno na agenda do professor");
            }               
        // }
        } catch (error) {
            console.log(error);
            toast.error("Um erro ocorreu ao alterar o vínculo!");
        }
    };

    const handleEditBond = async () => {
        if (teacherId === 0 || studentId === 0) {
            toast.warning("Preencha todas as informações!");
            return;
        };

        try {
            setIsLoading(() => true);

            const dataBond = {
                Id: props?.data?.id,
                Teacher_Id: teacherId,
                Student_Id: studentId,
                DataInicio: dataInicio,
            };

            await api.put('api/AssociatedTeacherWithStudent', dataBond);

            toast.success("Alterado vinculo com sucesso!");
            props?.updateTick();
        } catch (error){
            toast.error("Um erro ocorreu ao alterar o vinculo!");
        } finally {
            setIsLoading(() => false);
        };

        if(selectedTime !== "" || selectedDays.length !== 0) {
            try{
                await removerAlunoDaAgendaDoProfessor(props?.data?.id_Professor,  teacherId, studentId, selectedTime, selectedDays);
            }
            catch (error){
               toast.error("Um erro ocorreu ao editar a agenda dos professores");
            }
        }
    };

    const fetchAvailableTimes = async (teacherId: number) => {
        try {
            const response = await api.get(`/api/Agenda/ProcurarAgendaPorProfessorDisponivel?professorId=${teacherId}`);
            const times: AvailableTime[] = response.data.object || [];
            const uniqueTimes = Array.from(new Set(times.map(time => `${formatTime(time.horaInicialDisponivel)} - ${formatTime(time.horaFinalDisponivel)}`)));
            setAvailableTimes(uniqueTimes);
        } catch (err) {
            toast.error("Um erro ocorreu ao buscar horários disponíveis!");
        }
    };

    const handleTeacherChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTeacherId = Number(event.currentTarget.value);
        setTeacherId(selectedTeacherId);
        fetchAvailableTimes(selectedTeacherId);
    };

    return (
        <>
            {props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> : <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Vincular Aluno ao Professor' : 'Alterar Vinculo do Aluno ao Professor'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <FormControl>
                            <FormLabel htmlFor="teacher" fontWeight="bolder">Professor</FormLabel>
                            <PatternSelect 
                                id="teacher"
                                placeholder='Professor' 
                                value={teacherId} 
                                onChange={handleTeacherChange}
                            >
                                {teacher.map((obj: Teachers) => (
                                    <option key={obj.id} value={obj.id}>{obj.nome}</option>
                                ))}
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="student" fontWeight="bolder">Aluno</FormLabel>
                            <PatternSelect 
                                id="student"
                                placeholder='Aluno' 
                                value={studentId} 
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStudentId(Number(event.currentTarget.value))}
                            >
                                {student.map((obj: Student) => (
                                    <option key={obj.id} value={obj.id}>{obj.nome}</option>
                                ))}
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="availableTime" fontWeight="bolder">Horário disponível</FormLabel>
                                <PatternSelect 
                                    id="availableTime"
                                    placeholder='Horário disponível' 
                                    value={selectedTime} 
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSelectedTime(event.currentTarget.value)}
                                >
                                    {availableTimes.map((time, index) => (
                                        <option key={index} value={time}>{time}</option>
                                    ))}
                                </PatternSelect>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="availableDays" fontWeight="bolder">Dias disponíveis</FormLabel>
                                <Select
                                    id="availableDays"
                                    placeholder='Dias disponíveis'
                                    isMulti
                                    value={selectedDays}
                                    onChange={(selectedOptions) => setSelectedDays(selectedOptions as MultiValue<{ value: number; label: string }>)} 
                                    options={Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira"][i] }))}
                                />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="availableDays" fontWeight="bolder">Data Inicio do Aluno</FormLabel>
                            <PatternInput
                                type="date"
                                id="dayClass"
                                name="dayClass"
                                max={moment().add(30, "days").format('YYYY-MM-DD')}
                                min={moment().format('YYYY-MM-DD')}
                                value={dataInicio}
                                onChange={(event:any) => setDataInicio(event.currentTarget.value)}
                            />
                        </FormControl>
                        

                        
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddBond : handleEditBond}>
                            {props?.modalType === 'new' ? 'Salvar' : 'Editar'}
                        </PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
