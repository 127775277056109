import { useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Icon,
    useDisclosure,
    List,
    Text,
    ListItem,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import PatternButton from "../../Patterns/Button";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import { MdOutlineLockReset } from "react-icons/md";

export default function ResetPasswordModel(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const id = props?.data?.id ? props.data.id : props?.id;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleResetPassword = () => {
        api.post(`api/EsqueciMinhaSenha?email=${props?.data?.Email}`).then((response) => {
            if(response.data.object == true) {
                toast.success("Reset de Senha enviado com sucesso");
            } else {
                toast.error("Houve um problema ao resetar a senha desse usuário")
            }
        })
        .catch((err) => {
            toast.error("Houve um problema ao resetar a senha desse usuário");
        })
        .finally(() => {
            onClose();
        })
    }

    return (
        <>
            <Icon as={MdOutlineLockReset} color="warning.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Resetar Senha do Usuário: {id}</ModalHeader>
                    <ModalCloseButton />
    
                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <Text mb={4} color="gray">Clicando em "Resetar" você enviara um email de reset de senha para o usuário:</Text>

                        <List>
                            {props.data ? Object.keys(props.data).map(key => {
                                return (
                                    <ListItem key={props.data[key]}><b>{key.substring(0, 1).toUpperCase()}{key.substring(1)}</b>: {props.data[key]}</ListItem>
                                );
                            }) 
                            : 
                            "Você tem certeza que quer deletar esse registro?"}
                        </List>
                    </ModalBody>
    
                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton bg="warning.500 !important" isLoading={isLoading} onClick={handleResetPassword}>Resetar Senha</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    ); 
}

