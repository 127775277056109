import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Center, CircularProgress, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { utils, writeFile } from 'xlsx';
import { CgArrowAlignH } from "react-icons/cg";
import { FaFileExcel } from 'react-icons/fa';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { MdOutlineLockReset } from "react-icons/md";
import PatternButton from '../Patterns/Button';
import { DeleteModal } from './DeleteModal';
import { EditModal } from './EditModal';
import React from 'react';
import TurmasModel from './AddModals/TurmasModel';
import ResetPasswordModel from './ResetSenha/ResetSenhaModal';
import FormularioVisualizacaoModel from './AddModals/FormularioVisualizacaoModel';
import { VisualizarModal } from './Visualizar';

const filterIconSvg = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style="width: 12px; height: 12px; fill: currentColor;">
        <path d="M487 0H25C7.8 0-3.1 19.1 6.5 33.2L192 281.6V450c0 16.4 18.8 26.5 32 17l96-64c9.4-6.3 15-16.9 15-28.3V281.6L505.5 33.2C515.1 19.1 504.2 0 487 0z"/>
    </svg>
`;

interface DataGridProps {
    rowData: object[];
    isLoading?: boolean;
    isError?: boolean;
    addModal?: React.ReactNode;
    visualizationModal?: React.ReactNode;
    ResetSenha?: React.ReactNode;
    deletePath?: string;
    deleteAlunoTurma?: string;
    removerAlunoAgendaProf?: boolean;
    icons?: string[];
    updateTick?: () => void;
    enableExport?: boolean;
    rowData2?: object[];
}

export default function DataGrid({
    rowData,
    rowData2,
    isLoading,
    deleteAlunoTurma,
    removerAlunoAgendaProf,
    isError,
    addModal,
    visualizationModal,
    ResetSenha,
    deletePath,
    icons,
    updateTick,
    enableExport = false
}: DataGridProps) {
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs, setColumnDefs] = useState<object[]>([]);
    const [paginationNumber] = useState<number>(50);

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        if (rowData.length > 0) {
            const cols: object[] = [];
            if (icons) {
                icons.forEach(icon => {
                    switch (icon) {
                        case 'Visualizar':
                            if (visualizationModal) {
                                cols.push({
                                    field: icon,
                                    cellRenderer: TurmasModel,
                                    cellRendererParams: { modal: visualizationModal },
                                });
                            }
                            break;
                        case 'VisualizarFormulario':
                                if (visualizationModal) {
                                    cols.push({
                                        field: icon,
                                        cellRenderer: VisualizarModal,
                                        cellRendererParams: { modal: visualizationModal, propsData: rowData2, updateTick },
                                    });
                                }
                                break;
                        case 'ResetSenha':
                                if (ResetSenha) {
                                    cols.push({
                                        field: icon,
                                        cellRenderer: ResetPasswordModel,
                                        cellRendererParams: { modal: MdOutlineLockReset  },
                                    });
                                }
                                break;
                        case 'Editar':
                            if (addModal) {
                                cols.push({
                                    field: icon,
                                    cellRenderer: EditModal,
                                    cellRendererParams: { modal: addModal, updateTick },
                                });
                            }
                            break;
                        case 'Deletar':
                            if (deletePath) {
                                cols.push({
                                    field: icon,
                                    cellRenderer: DeleteModal,
                                    cellRendererParams: {
                                        deletePath,
                                        updateTick,
                                        deleteAlunoTurma,
                                        removerAlunoAgendaProf,
                                    },
                                });
                            }
                            break;
                        default:
                            break;
                    }
                });
            }

            Object.keys(rowData[0]).forEach(key => {
                cols.push({
                    field: key,
                    filter: true,
                    resizable: true,
                    sortable: true,
                    icons: {
                        menu: filterIconSvg, // Ícone de menu personalizado
                    },
                });
            });

            setColumnDefs(cols);
        }
    }, [rowData, addModal, visualizationModal, deletePath, icons, updateTick]);

    const autoSizeAll = useCallback(() => {
        gridRef?.current?.api?.refreshCells();
        const allColumnIds: string[] = [];

        gridRef?.current?.columnApi?.getColumns()?.forEach((column) => {
            allColumnIds.push(column?.getId());
        });

        gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds);
    }, []);

    const handleGenerateExcel = async () => {
        const fileName = `dados_exportados.xlsx`;

        const workSheet = utils.json_to_sheet(rowData);
        const wb = utils.book_new();

        utils.book_append_sheet(wb, workSheet, fileName);

        writeFile(wb, fileName);
    };

    return (
        <>
            <style>
                {`
                .ag-header-cell .ag-header-icon {
                    opacity: 1 !important;
                }
                `}
            </style>

            <Center display={isLoading ? 'initial' : 'none'} mt={48} flexDir="column">
                <CircularProgress isIndeterminate color="secondary.500" />
                <Text>Carregando Tabela...</Text>
            </Center>

            <Text display={isError ? 'initial' : 'none'}>Um erro ocorreu ao trazer os dados desta Tabela!</Text>

            <Box display={!isLoading && !isError && rowData.length > 0 ? 'initial' : 'none'}>
                <div className="ag-theme-alpine" style={{ width: '100%', height: isLargerThan768 ? 600 : 450, paddingBottom: isLargerThan768 ? 0 : 48, fontSize: isLargerThan768 ? 'initial' : '0.65rem' }}>
                    <HStack mb={2} gap={2}>
                        <PatternButton
                            icon={CgArrowAlignH}
                            onClick={autoSizeAll}
                            fontSize={["0.8rem", "0.8rem", "1rem"]}
                        >
                            Ajustar Colunas
                        </PatternButton>
                        {enableExport && (
                            <PatternButton
                                icon={FaFileExcel}
                                onClick={handleGenerateExcel}
                                fontSize={["0.8rem", "0.8rem", "1rem"]}
                            >
                                Exportar para Excel
                            </PatternButton>
                        )}
                        {addModal && React.isValidElement(addModal) && addModal}
                    </HStack>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        enableRangeSelection={true}
                        suppressMultiRangeSelection={true}
                        paginationPageSize={paginationNumber}
                        rowStyle={{ fontSize: isLargerThan768 ? "initial" : '0.65rem' }}
                    />
                </div>
            </Box>

            <Box display={!isError && !isLoading && rowData.length <= 0 ? 'initial' : 'none'}>
                <div className="ag-theme-alpine" style={{ width: '100%', height: isLargerThan768 ? 600 : 450, paddingBottom: isLargerThan768 ? 0 : 48, fontSize: isLargerThan768 ? 'initial' : '0.65rem' }}>
                    {rowData.length > 0 ?
                        <HStack mb={2} gap={2}>
                            {addModal && React.isValidElement(addModal) && addModal}
                        </HStack>
                        :
                        null}
                    <Text>Não há dados para Exibir!</Text>
                </div>
            </Box>
        </>
    );
}
