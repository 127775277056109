import {
    Button,
    Center,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button"
import PatternCheckbox from "../../Patterns/Checkbox";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import moment from "moment";
import { useJwt } from "react-jwt";
import { useCookies } from "react-cookie";

interface GetUsuallyUser {
    nome: string;
    id: Number;
}

interface AulaParaRepor {
    dataAulaFaltanta: string;
    id: Number;
    nomeAluno: string;
    idAluno: Number;
}
interface GetEstudante {
    name: string;
    id: Number;
}

export default function SchedulingRepositionModel(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [teacher, setTeacher] = useState<GetUsuallyUser[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [student, setStudent] = useState<Student[]>([]);
    const [student2, setStudent2] = useState<GetEstudante[]>([]);
    const [studentId, setStudentId] = useState<Number>(0);
    const [idAulaQueIraRepor, setIdAulaQueIraRepor] = useState<Number>(0);
    const [idsAulaAlunoEscolhido, setIdsAulaAlunoEscolhido] = useState<string>("");
    const [dayClass, setDayClass] = useState<string>(moment().format('YYYY-MM-DD'));

    const [aulasParaRepor, setAulasParaRepor] = useState<AulaParaRepor[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {

        setTeacherId(props?.data?.teacher_Id);
        setStudentId(props?.data?.student_Id);
        setIdAulaQueIraRepor(props?.data?.id_Reposicao);

        if(decodedToken?.role !== "Professor" && decodedToken)
        {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeacher(response.data.object);
            })
            .catch(() => setIsError(() => true))
            

            api.get("api/StudentSelectBox").then((response) => {
                setStudent(response.data.object);
            })
            .catch(() => setIsError(() => true))

            api.get(`/api/ObterReposicoesDisponiveisParaAgendarPorProfessor/${teacherId}`)
            .then((response:any) => {setAulasParaRepor(response.data.object);})
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }
        else if(decodedToken){
            api.get(`api/StudentPerTeacherGet?TeacherId=${decodedToken?.TeacherId}`).then((response) => {
                setStudent2(response.data.object);
            })
            .catch(() => setIsError(() => true))

            api.get(`/api/ObterReposicoesDisponiveisParaAgendarPorProfessor/${decodedToken?.TeacherId}`)
            .then((response:any) => setAulasParaRepor(response.data.object))
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
            
            setTeacherId(Number(decodedToken?.TeacherId));
        }
        
    }, [props, decodedToken]);

    const handleAddBond = async () => {

        if(idAulaQueIraRepor === 0)
            toast.warning('Campo Aula que deseja repor está vazio')

        if(studentId === 0)
            toast.warning('Campo Aula que deseja repor está vazio')

        const data = {
            DayClass: dayClass,
            Teacher_Id: teacherId,
            Student_Id: studentId,
            Id_Reposicao: idAulaQueIraRepor,
        }
        api.post("/api/schedulingRepositionClassRoom/RegisteSchedulingClassRoom" , data)
        .then((response) => {
            toast.success("Aula Agendada com Sucesso");
        })
        .catch((err) => {
            toast.error("Ops, parece que houve um problema. Tente novamente mais tarde!")
        })
        .finally(() => {
            props?.updateTick();
        })
    };

    const handleEditBond = async () => {
        const data = {
            Id: props?.data?.id,
            DayClass: dayClass,
            Teacher_Id: teacherId,
            Student_Id: studentId,
            Id_Reposicao: idAulaQueIraRepor,
        }
        api.put("/api/schedulingRepositionClassRoom/UpdateSchedulingClassRoom" , data)
        .then((response) => {
            toast.success("Aula Agendada com Sucesso");
        })
        .catch((err) => {
            toast.error("Ops, parece que houve um problema. Tente novamente mais tarde!")
        }).finally(() => {
            props?.updateTick();
        });
    };

    const handleEdit = (idStudentidAulaParaRepor : string) => {
        setIdsAulaAlunoEscolhido(idStudentidAulaParaRepor);
        const ids = idStudentidAulaParaRepor.split('-');
        
        setIdAulaQueIraRepor(Number(ids[0]));
        setStudentId(Number(ids[1]));
        
    }

    return (
        <>
            {props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> : <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Agendar Reposição de Aula' : 'Alterar Agendamento Reposição de Aula'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <FormControl>
                            <p>Data da Reposição de Aula</p>
                            <PatternInput
                                type="date"
                                id="dayClass"
                                name="dayClass"
                                placeholder="E-dayClass"
                                max={moment().add(+30, "days").format('YYYY-MM-DD') }
                                min={moment().format('YYYY-MM-DD')}
                                value={dayClass}
                                onChange={(event) => setDayClass(event.currentTarget.value)}
                            />
                        </FormControl>
                        {
                            decodedToken?.role !== "Professor" ? 
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                                <PatternSelect 
                                    placeholder='Professor' 
                                    value={Number(teacherId)} 
                                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTeacherId(Number(event.currentTarget.value))}
                                >
                                    {
                                        teacher.map((obj) =>(
                                            <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                                        ))
                                    }
                                </PatternSelect>
                            </FormControl>
                            :
                            null
                        }
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Aula que deseja repor</FormLabel>
                            <PatternSelect 
                                value={idsAulaAlunoEscolhido} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => handleEdit(event.currentTarget.value)}
                            >
                                <option key={0} value={0}>NENHUM</option>
                                {
                                    aulasParaRepor.map((obj) => (
                                        <option key={Number(obj.id)} value={`${obj.id}-${obj.idAluno}`}>{obj.nomeAluno} - Data aula: {obj.dataAulaFaltanta}</option>
                                    ))
                                }
                            </PatternSelect>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddBond : handleEditBond}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};