import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Text,
    useDisclosure,
    Box,
    Flex,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react'
import moment from 'moment';
import 'moment/locale/pt-br'
import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { FestivityContext } from '../../contexts/FestivityContext';
import { api } from '../../services/api';
import WaitConfirmationPopover from '../WaitConfirmationPopover';

export default function FestivityModal() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const { actualYear, festivityDecision, breakInitialDay } = useContext(FestivityContext);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (festivityDecision === null || festivityDecision === undefined)
            onOpen();
        else
            onClose();
    }, [festivityDecision]);

    const handleSubmit = async (pauseDecision: boolean) => {
        try {
            const data = {
                ProfessorId: Number(decodedToken?.TeacherId),
                AnoDecisao: actualYear,
                DecisaoPausar: pauseDecision,
            };

            await api.post('/api/FestivityDecision', data);
            onClose();
            window.location.reload();
        } catch {

        };
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => { }} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Comunicado de Festividades {actualYear}</ModalHeader>
                    <ModalBody>
                        <Text fontSize="1.15rem">
                            Prezados professores,<br />
                            Com a proximidade do fim do ano, chegou o momento de iniciarmos o planejamento para os últimos dias de {actualYear}.
                        </Text>

                        <Text fontSize="1.15rem" mt={8}>
                            Em nosso modelo de contratação, cada professor tem total autonomia para decidir como organizar suas atividades durante este período.
                            Gostaríamos de informar que parte da equipe de direção da escola estará em recesso de {breakInitialDay}/12/{actualYear} a 01/01/{actualYear + 1}. Contudo, ressaltamos que a participação dos professores nesse período é opcional.
                        </Text>

                        <Text fontSize="1.15rem" mt={8} fontWeight="600">Para os professores que decidirem continuar dando aulas:</Text>
                        <UnorderedList fontSize="1.15rem">
                            <ListItem><b>Não</b> haverá aulas nas vésperas e feriados do Natal e Ano Novo, que este ano caem {moment(`${actualYear}-12-24`).locale('pt-br').format('dddd')} e {moment(`${actualYear}-12-25`).locale('pt-br').format('dddd')}.</ListItem>
                            <ListItem>Caso seus alunos optem por não terem aulas durante esse período, isso não afetará seu vínculo ou remuneração de forma alguma.</ListItem>
                        </UnorderedList>

                        <Text fontSize="1.15rem" mt={8} fontWeight="600">Para os professores que optarem por encerrar suas atividades no dia {breakInitialDay}/12:</Text>
                        <UnorderedList fontSize="1.15rem">
                            <ListItem>Os alunos vinculados a vocês também farão essa pausa, a menos que optem por continuar as aulas com outro professor durante o período e então faremos a troca.</ListItem>
                            <ListItem>O retorno às atividades deverá ser planejado para o dia 02/01/{actualYear + 1}, garantindo uma retomada organizada da agenda.</ListItem>
                            <ListItem>A remuneração será proporcional aos dias efetivamente trabalhados no mês de dezembro.</ListItem>
                        </UnorderedList>

                        <Text fontSize="1.15rem" mt={8}>
                            Se houver dúvidas ou necessidade de suporte, estamos à disposição para ajudá-los a organizar suas agendas.
                            <br />
                            Atenciosamente,
                            <br />
                            Equipe King of Languages
                        </Text>
                    </ModalBody>

                    <ModalFooter justifyContent="space-evenly" alignItems="center" gap={2}>
                        <WaitConfirmationPopover title="Tem certeza?" description={`Você terá mais 2 chances de trocar sua decisão até ${moment(`${actualYear}-12-${breakInitialDay}`).subtract(1, 'week').format('DD/MM/YYYY')}. Por motivos de segurança, confirme-a abaixo:`} onAction={() => handleSubmit(false)}>
                            <Flex align="center" justify="center" bg="red.500" textAlign="center" color="white" p={2} w="40%" minH="8vh" borderRadius={8} cursor="pointer" userSelect="none" transition="0.2s" _hover={{ bg: 'red.700' }}>
                                Vou continuar com as aulas pausando somente nas vésperas e feriados
                            </Flex>
                        </WaitConfirmationPopover>
                        <WaitConfirmationPopover title="Tem certeza?" description={`Você terá mais 2 chances de trocar sua decisão até ${moment(`${actualYear}-12-${breakInitialDay}`).subtract(1, 'week').format('DD/MM/YYYY')}. Por motivos de segurança, confirme-a abaixo:`} onAction={() => handleSubmit(true)}>
                            <Flex align="center" justify="center" bg="blue.500" textAlign="center" color="white" p={2} w="40%" minH="8vh" borderRadius={8} cursor="pointer" userSelect="none" transition="0.2s" _hover={{ bg: 'blue.700' }}>
                                Vou fazer a pausa do dia<br />{breakInitialDay}/12/{actualYear} a 01/01/{actualYear + 1}
                            </Flex>
                        </WaitConfirmationPopover>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};