import { Icon, Drawer, Image, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, List, ListIcon, ListItem, useDisclosure, Text, Flex } from "@chakra-ui/react"
import { useRef } from "react"
import { useCookies } from "react-cookie";
import { AiOutlineMenu } from "react-icons/ai";
import { useJwt } from "react-jwt";
import { Link, useLocation } from "react-router-dom";
import { navConfig } from "../../utils/navConfig";

export function MobileSideBar() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const location = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Flex
                position="fixed"
                mt={4}
                ml={2}
                ref={btnRef}
                onClick={onOpen}
                zIndex={999}
                align="center"
                gap={2}
                color="secondary.500"
            >
                <Icon
                    fontSize="2rem"
                    as={AiOutlineMenu}
                />
                <Text fontSize="0.7rem">Menu de Navegação</Text>
            </Flex>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg="primary.500">
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Image mt={4} p={4} w="240px" src="/King-logo.png" />
                    </DrawerHeader>

                    <List mt={8} color="white" overflowY="scroll" maxH="calc(100vh - 200px);" css={{ /*'&::-webkit-scrollbar': { display: 'none' }*/ }}>
                        {navConfig.map(nav => {
                            const active = location.pathname === nav.path;
                            return (
                                <>
                                    {
                                        decodedToken?.role === 'Administrador' ?
                                            <Link key={nav.id} to={nav.path} replace>
                                                <ListItem
                                                    w="100%"
                                                    p={4}
                                                    cursor="pointer"
                                                    transition="0.2s"
                                                    bg={active ? "textColor" : "initial"}
                                                    color={active ? "contrastTextColor" : "textColor"}
                                                    _hover={active ? {} : { bg: "textColor", color: "contrastTextColor", filter: "brightness(0.7)" }}
                                                >
                                                    <ListIcon
                                                        as={nav.icon}
                                                        fontSize="1.5rem"
                                                        _hover={{}}
                                                    />
                                                    {nav.name}
                                                </ListItem>
                                            </Link>
                                            :
                                            decodedToken?.role === 'Coordenador' && (nav.name === 'Configuração Hora/Aula'  || nav.name === 'Caléndario de Aulas' || nav.name === 'Reposição' || nav.name === 'Configuração Aluno/Professor' || nav.name === 'Usuários') ?
                                                <Link key={nav.id} to={nav.path} replace>
                                                    <ListItem
                                                        w="100%"
                                                        p={4}
                                                        cursor="pointer"
                                                        transition="0.2s"
                                                        bg={active ? "textColor" : "initial"}
                                                        color={active ? "contrastTextColor" : "textColor"}
                                                        _hover={active ? {} : { bg: "textColor", color: "contrastTextColor", filter: "brightness(0.7)" }}
                                                    >
                                                        <ListIcon
                                                            as={nav.icon}
                                                            fontSize="1.5rem"
                                                            _hover={{}}
                                                        />
                                                        {nav.name}
                                                    </ListItem>
                                                </Link>
                                                :
                                                decodedToken?.role === 'Suporte' && (nav.name === 'Configuração Aluno/Professor' || nav.name === 'Caléndario de Aulas' || nav.name === 'Usuários' || nav.name === 'Reposição') ?
                                                    <Link key={nav.id} to={nav.path} replace>
                                                        <ListItem
                                                            w="100%"
                                                            p={4}
                                                            cursor="pointer"
                                                            transition="0.2s"
                                                            bg={active ? "textColor" : "initial"}
                                                            color={active ? "contrastTextColor" : "textColor"}
                                                            _hover={active ? {} : { bg: "textColor", color: "contrastTextColor", filter: "brightness(0.7)" }}
                                                        >
                                                            <ListIcon
                                                                as={nav.icon}
                                                                fontSize="1.5rem"
                                                                _hover={{}}
                                                            />
                                                            {nav.name}
                                                        </ListItem>
                                                    </Link>
                                                    :
                                                    decodedToken?.role === 'Vendedor' && (nav.name === 'Novos Alunos' || nav.name === 'Caléndario de Aulas' || nav.name === 'Configuração Hora/Aula' || nav.name === 'Configuração Aluno/Professor' || nav.name === 'Usuários' || nav.name === 'Alunos que Começam em Breve') ?
                                                        <Link key={nav.id} to={nav.path} replace>
                                                            <ListItem
                                                                w="100%"
                                                                p={4}
                                                                cursor="pointer"
                                                                transition="0.2s"
                                                                bg={active ? "textColor" : "initial"}
                                                                color={active ? "contrastTextColor" : "textColor"}
                                                                _hover={active ? {} : { bg: "textColor", color: "contrastTextColor", filter: "brightness(0.7)" }}
                                                            >
                                                                <ListIcon
                                                                    as={nav.icon}
                                                                    fontSize="1.5rem"
                                                                    _hover={{}}
                                                                />
                                                                {nav.name}
                                                            </ListItem>
                                                        </Link>
                                                        :
                                                        decodedToken?.role === 'Professor' && (nav.name === 'Configuração Hora/Aula'|| nav.name === 'Calendário de Aulas' || nav.name === 'Reposição' || nav.name === 'Calendário de Aulas') ?
                                                            <Link key={nav.id} to={nav.path} replace>
                                                                <ListItem
                                                                    w="100%"
                                                                    p={4}
                                                                    cursor="pointer"
                                                                    transition="0.2s"
                                                                    bg={active ? "textColor" : "initial"}
                                                                    color={active ? "contrastTextColor" : "textColor"}
                                                                    _hover={active ? {} : { bg: "textColor", color: "contrastTextColor", filter: "brightness(0.7)" }}
                                                                >
                                                                    <ListIcon
                                                                        as={nav.icon}
                                                                        fontSize="1.5rem"
                                                                        _hover={{}}
                                                                    />
                                                                    {nav.name}
                                                                </ListItem>
                                                            </Link>
                                                            :
                                                            null
                                    }
                                </>
                            );
                        })}
                    </List>
                </DrawerContent>
            </Drawer>
        </>
    )
}