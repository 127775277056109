import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";
import moment from "moment";
import AulasCanceladasModel from "../../components/DataGrid/AddModals/AulasCanceladasModel";
import AulasCanceladasPorValorMonetarioModel from "../../components/DataGrid/AddModals/AulasCanceladasPorValorMonetario";

interface GetAllUserResponse {
    Id: Number;
    Professor: string;
    Aluno: string;
    Motivo: string;
    QuantidadeAulasASerCancelada: Number;
};

interface GetUsuallyUser {
    name: string;
    id: Number;
}

export default function AulasCanceladasPorValorMonetario(props:any) {
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [alunoEscolhido, setAlunoEscolhido] = useState<number>(0);
    const [aluno, setAluno] = useState<Student[]>([]);
    const [professor, setProfessor] = useState<Teachers[]>([]);
    const [year, setYear] = useState<number>(anoAtual);
    const [month, setMonth] = useState<number>(mesAtual);

    useEffect(() => {
        setAluno(props?.aluno);
        setProfessor(props?.professor);
    }, [props])

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);
        setRowData(() => []);

        updateData();

    }, [tick, decodedToken, month, alunoEscolhido, year]);

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }


    const updateData = async () => {
        setIsLoading(() => true);
        if(decodedToken && alunoEscolhido !== 0){
            try{
                const dataClassLaunch = await api.get(`api/AulasCanceladasPorValorMonetarioPorAluno/${alunoEscolhido}/${month}/${year}`)
                if(dataClassLaunch.data.object.length > 0) {
                    setRowData(dataClassLaunch.data.object.map((row: any) => {
                        return {
                            Professor: row.professor,
                            Aluno: row.aluno,
                            Motivo: row.motivo,
                            Quantidade_Aulas_Canceladas: row.quantidadeAulasASerCancelada,
                            MesReferenciaDoDescontoNoPagamento: formatDate(row.mesReferenciaPagamento, false, true),
                            MesReferenciaDaAulaAplicada: formatDate(row.mesReferenciaAulaDescontada, false, true),
                            id: row.id
                        };
                    }));
                }
                else{
                    setRowData([]);
                }
            }
            catch(err){
                setIsError(() => true)
            }
            finally{
                setIsLoading(() => false)
            }
        }
    }
    
    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
            <PatternSelect 
                placeholder='MÊS' 
                value={month} 
                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
            >
                <option key={1} value={1}>JANEIRO</option>
                <option key={2} value={2}>FEVEREIRO</option>
                <option key={3} value={3}>MARÇO</option>
                <option key={4} value={4}>ABRIL</option>
                <option key={5} value={5}>MAIO</option>
                <option key={6} value={6}>JUNHO</option>
                <option key={7} value={7}>JULHO</option>
                <option key={8} value={8}>AGOSTO</option>
                <option key={9} value={9}>SETEMBRO</option>
                <option key={10} value={10}>OUTUBRO</option>
                <option key={11} value={11}>NOVEMBRO</option>
                <option key={12} value={12}>DEZEMBRO</option>
            </PatternSelect>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
            <PatternSelect 
                placeholder='ANO' 
                value={year} 
                onChange={(event) => { updateYear(event.currentTarget.value)}}
            >
                <option key={1} value={2023}>2023</option>
                <option key={2} value={2024}>2024</option>
                <option key={3} value={2025}>2025</option>
                <option key={4} value={2026}>2026</option>
                <option key={5} value={2027}>2027</option>
                <option key={6} value={2028}>2028</option>
                <option key={7} value={2029}>2029</option>
                <option key={8} value={2030}>2030</option>
                <option key={9} value={2031}>2031</option>
                <option key={10} value={2032}>2032</option>
                <option key={11} value={2033}>2033</option>
                <option key={12} value={2034}>2034</option>
            </PatternSelect>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                <PatternSelect 
                    placeholder='Aluno' 
                    value={Number(alunoEscolhido)} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setAlunoEscolhido(Number(event.currentTarget.value))}
                >
                    {
                        aluno.map((obj:Student) => (
                            <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                        ))
                    }
                </PatternSelect>
            </FormControl>
            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <AulasCanceladasPorValorMonetarioModel modalType="new" aluno={aluno} professor={professor} updateTick={updateTick} />
                </Box>
            )}
            {
                year !== 0 && month !== 0  && alunoEscolhido !== 0 ?
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    icons={['Deletar']}
                    addModal={<AulasCanceladasPorValorMonetarioModel modalType="new"  aluno={aluno} professor={professor} updateTick={updateTick} />}
                    updateTick={updateTick}
                    deletePath={"CancelarAulaPorValorMonetario"}
                />
                :
                null
            }
        </>
        
    );
};