import { Circle, Tooltip } from "@chakra-ui/react";

interface CalendarWeekDay {
    label: string;
    weekDayNumber: number;
    daysOfWeek: number[];
    calendarData: CalendarData;
    allCalendar: CalendarData[];
    handleSelectDayOfWeek: (dayOfWeek: number) => void;
};

export default function CalendarWeekDay({ label, weekDayNumber, daysOfWeek, calendarData, allCalendar, handleSelectDayOfWeek }: CalendarWeekDay) {
    return (
        <Tooltip hasArrow label={label} bg="primary.500" placement="bottom">
            <Circle
                color="white"
                bg={daysOfWeek.find(day => day === weekDayNumber) ? "primary.500" : allCalendar.find(dc => dc.diaSemana === weekDayNumber && dc.horaInicialAula === calendarData?.horaInicialAula && dc.horaFinalAula === calendarData?.horaFinalAula)?.tipoAula ? "red.300" : "blue.300"}
                padding="3px 10px"
                cursor={calendarData?.diaSemana === weekDayNumber ? "not-allowed" : allCalendar.find(dc => dc.diaSemana === weekDayNumber && dc.horaInicialAula === calendarData?.horaInicialAula && dc.horaFinalAula === calendarData?.horaFinalAula)?.tipoAula ? "not-allowed" : "pointer"}
                onClick={calendarData?.diaSemana === weekDayNumber ? () => { } : allCalendar.find(dc => dc.diaSemana === weekDayNumber && dc.horaInicialAula === calendarData?.horaInicialAula && dc.horaFinalAula === calendarData?.horaFinalAula)?.tipoAula ? () => { } : () => handleSelectDayOfWeek(weekDayNumber)}
            >
                {label.substring(0, 1)}
            </Circle>
        </Tooltip>
    );
};