import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, Center, CircularProgress, FormControl, FormLabel, Text} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import NovosAlunosModel from "../../components/DataGrid/AddModals/NovosAlunosModel";
import Select, { SingleValue } from 'react-select';

export default function ReposicoesConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [aluno, setAluno] = useState<Student[]>([])
    const [alunoEscolhido, setAlunoEscolhido] = useState<SingleValue<{ value: number; label: string }>>();
    const [alunoEscolhidoId, setAlunoEscolhidoId] = useState<number>(0);
    const [reposicoesNaoFeitas, setReposicoesNaoFeitas] = useState<number>(0);
    const [totalReposicoes, setTotalReposicoes] = useState<number>(0);
    const [tick, setTick] = useState<number>(0);

    useEffect(() => {
        if(decodedToken)
        {  
            api.get("/api/StudentSelectBox").then((response) => {
                setAluno(response.data.object);
            })
            .finally(() => {setIsLoading(false);})
        }

    }, [decodedToken])

    useEffect(() => {
        setIsLoading(() => true);

        if(alunoEscolhidoId > 0 && decodedToken){
            api.get(`/api/ReposicaoAulas/ReposicaoDetalhadaPorAluno?alunoId=${alunoEscolhidoId}`)
            .then((response) => {
                if(response.data.object.reposicoes && response.data.object.reposicoes.length > 0){
                    setRowData(response.data.object.reposicoes.map((row: any) => {
                        return {
                            ...row,
                            cicloReposicaoConcluido: row.cicloReposicaoConcluido == true ? "SIM" : "NÃO", 
                            dataDaAulaParaRepor: formatDate(row.dataDaAulaParaRepor, false),
                            dataAulaDeReposicaoFeita: formatDate(row.dataAulaDeReposicaoFeita, false)
                        };
                    }));
                    setReposicoesNaoFeitas(response.data.object.totalReposicoesNaoRealizadas);
                    setTotalReposicoes(response.data.object.totalReposicoes);
                }
                else{
                    setRowData([]);
                    setReposicoesNaoFeitas(0);
                    setTotalReposicoes(0);
                }
                
            })
            .finally(() => {setIsLoading(false);})
        }

    }, [decodedToken, alunoEscolhidoId, tick]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            {
                !isLoading ?
                <>
                    Total de Reposições Do Aluno: {totalReposicoes} 
                    <br/>
                    Total de Reposições Pendentes Do Aluno: {reposicoesNaoFeitas}
                    <FormControl>
                        <FormLabel fontWeight="bolder">Aluno</FormLabel>
                        <Select
                            id="aluno"
                            name="aluno"
                            placeholder="Escolha um Aluno..."
                            noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                            value={alunoEscolhido}
                            onChange={(event) => { setAlunoEscolhido(event); setAlunoEscolhidoId(Number(event?.value)) }}
                            options={aluno.map(t => { return { value: t.id, label: t.nome } })}
                        />
                    </FormControl>
                    <br />
                    <DataGrid
                        rowData={rowData}
                        isLoading={isLoading}
                        isError={isError}
                        updateTick={updateTick}
                    />
                </>
                :
                <Center display={isLoading ? 'initial' : 'none'} mt={48} flexDir="column">
                    <CircularProgress isIndeterminate color="secondary.500" />
                    <Text>Carregando...</Text>
                </Center>
            }
        </>
        
    );
};