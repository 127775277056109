import { useEffect, useState } from "react";
import UserModal from "../../components/DataGrid/AddModals/UserModal";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";

import SchedulingRepositionModel from "../../components/DataGrid/AddModals/SchedulingRepositionModel";

interface GetAllUserResponse {
    id: Number;
    id_Aluno: Number;
    nome_Professor: string;
    nome_Aluno: string;
    quantidade_Aula: Number;
    dia_Aula: string;
};

interface GetTeacher {
    nome: string;
    id: Number;
}

export default function RepositionConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [teacher, setTeacher] = useState<GetTeacher[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);

    //decodedToken?.TeacherId

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);

        if(decodedToken && decodedToken?.role !== "Professor" )
        {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeacher(response.data.object);
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));

            loadDados(Number(teacherId))
        }
        else if(decodedToken) {
            loadDados(Number(decodedToken?.TeacherId))
        }
    }, [tick, decodedToken]);

    const loadDados = (teacherId:Number) => {
        api.get(`/api/schedulingRepositionClassRoom/GetTeacherSchedulingClassRoomAsync?Teacher_Id=${teacherId}`)
        .then((response: any) => {
            setRowData(response.data.object.map((row: any) => {
                return {
                    ...row,
                    dayClass: formatDate(row.dayClass)
                };
            }));
        })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false));
    }

    const updateTick = () => {
        setTick(tick + 1);
    };

    const handleUpdateReport = (event : React.ChangeEvent<HTMLSelectElement>) => {
        if(decodedToken?.role !== "Professor"){
            setTeacherId(Number(event.currentTarget.value));  
            loadDados(Number(event.currentTarget.value));
        }
    };

    return (
        <>          
            <FormControl>
                {
                    decodedToken?.role !== "Professor" ?
                    <>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                            <PatternSelect 
                            placeholder='Professor' 
                            value={Number(teacherId)} 
                            onChange={handleUpdateReport}
                            >
                            {
                                teacher.map((obj) => (
                                    <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                                ))
                            }
                        </PatternSelect>
                    </>
                    :
                    null
                }
            </FormControl>

            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <SchedulingRepositionModel modalType="new" updateTick={updateTick} />
                </Box>
            )}
            <DataGrid
                rowData={rowData}
                isLoading={isLoading}
                isError={isError}
                addModal={<SchedulingRepositionModel modalType="new" updateTick={updateTick} />}
                icons={['Deletar']}
                deletePath={"schedulingRepositionClassRoom/RemoveSchedulingClassRoom"}
                updateTick={updateTick}
            />
        </>
    );
};