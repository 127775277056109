import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    FormLabel,
    Flex,
    Accordion,
    AccordionItem,
    Box,
    AccordionIcon,
    AccordionPanel,
    AccordionButton,
} from '@chakra-ui/react'
import moment from 'moment';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PatternButton from '../../components/Patterns/Button';
import PatternInput from '../../components/Patterns/Input';
import PatternSelect from '../../components/Patterns/Select';
import { api } from '../../services/api';
import { formatDate } from '../../utils/formatter';

interface HistoricoModalProps {
    calendarData: CalendarData;
    teacherId: number | undefined;
    handleCloseOld: any;
};

interface Historico {
    nomeQuemCriou: string;
    nomeQuemEditou: string;
    nomeQuemApagou: string;
    horaInicialAula: string;
    horaFinalAula: string;
    diaSemana: number;
    nomeProfessor: string;
    tipoAula: string;
    quemCriou: string;
    nomeAluno: string;
    previsaoInicioAulas: string;
    dt_Register: Date;
}

export default function HistoricoModel({calendarData, teacherId, handleCloseOld} : HistoricoModalProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [historicos, setHistoricos] = useState<Historico[]>([]);

    const handleHistoricoCelula = async () => {       
        try {
            api.get(`api/Agenda/ObterHistoricoDeEdicoesAgenda?dataInicial=${calendarData.horaInicialAula}&dataFinal=${calendarData.horaFinalAula}&diaSemana=${calendarData.diaSemana}&professorId=${teacherId}`)
            .then((response) => {
                setHistoricos(response.data.object);
            })
            
        } catch {
            toast.error('Ocorreu um erro ao deletar este agendamento. Contate um Administrador!');
        } finally {
            setIsLoading(() => false);
            onOpen();
        };
    }


    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <PatternButton mr={3} bg={"yellow.800 !important"} onClick={() => {handleHistoricoCelula()}}  isLoading={isLoading}>Histórico de Edições</PatternButton>

            <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Histórico de Edição de: {['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][calendarData.diaSemana]} - {moment(calendarData.horaInicialAula).format('HH:mm')} às {moment(calendarData.horaFinalAula).format('HH:mm')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {
                            historicos && historicos.length > 0 ? 
                            historicos.map((historico) => (
                                <Accordion allowToggle>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                {formatDate(String(historico.dt_Register), true)}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            Nome do Aluno: {historico.nomeAluno} <br />
                                            Quem Editou: {historico.nomeQuemEditou} <br />
                                            Quem Deletou: {historico.nomeQuemApagou} <br />
                                            Data da Edição: {formatDate(String(historico.dt_Register), true)} <br />
                                            Tipo de Aula: {historico.tipoAula}
                                        </AccordionPanel>
                                    </AccordionItem>

                                    
                                    </Accordion>
                            ))
                            :
                            "Não existe histórico para esse registro."
                        }
                    </ModalBody>

                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};