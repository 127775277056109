import { Button, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

export default function NovosAlunosModel (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataFechamento, setDataFechamento] = useState<string>(moment().format('YYYY-MM-DD'));
    const [dataInicioAulas, setDataInicioAulas] = useState<string>(moment().format('YYYY-MM-DD'));
    const [valorFechado, setValorFechado] = useState<string>("");
    const [valorMatricula, setValorMatricula] = useState<string>("");
    const [deOndeVeio, setDeOndeVeio] = useState<string>("");
    const [quemFechou, setQuemFechou] = useState<string>("");
    const [quantosDiasDemorouFechar, setQuantosDiasDemorouFechar] = useState<Number>(0);
    
    useEffect(() => {
        if (props?.modalType === 'edit') {
            setDataFechamento(moment(moment(props?.data?.dataFechamento, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setDataInicioAulas(moment(moment(props?.data?.dataInicioAulas, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setValorMatricula(props?.data?.valorMatricula)
            setValorFechado(props?.data?.valorFechado);
            setDeOndeVeio(props?.data?.deOndeVeio);
            setQuemFechou(props?.data?.quemFechou);
            setQuantosDiasDemorouFechar(props?.data?.quantosDiasDemorouFechar);
        };
    }, [props])

    const handleAddNewStudent = async () => {

        if (dataFechamento === '') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (dataInicioAulas === '') { 
            toast.warning("O campo Data de Inicio das Aulas está vazio");
            return;
        };

        if (valorFechado === '') { 
            toast.warning("O campo Valor Fechado está vazio");
            return;
        };

        if (valorMatricula === '') { 
            toast.warning("O campo Valor da Mensalidade está vazio");
            return;
        };

        if (deOndeVeio === '') { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };

        if (quantosDiasDemorouFechar === 0) { 
            toast.warning("O campo Quantos Dias Demorou para Fechar está vazio");
            return;
        };

        if (quemFechou === "") { 
            toast.warning("O campo Quem Fechou está vazio");
            return;
        };
        

        try {
            setIsLoading(() => true);
            const data = {
                DataFechamento: dataFechamento,
                DataInicioAulas: dataInicioAulas,
                ValorFechado: Number(valorFechado),
                DeOndeVeio: deOndeVeio,
                ValorMatricula: Number(valorMatricula),
                QuantosDiasDemorouPraFecharContrato: Number(quantosDiasDemorouFechar),
                QuemFechou: quemFechou
            };

            await api.post('api/NovosAlunos', data);

            toast.success("Aluno criado com sucesso!");
        
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario ja existente")
                toast.error("Aluno Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar este Aluno!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleEditNewStudent = async () => {

        if (dataFechamento === '') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (dataInicioAulas === '') { 
            toast.warning("O campo Data de Inicio das Aulas está vazio");
            return;
        };

        if (valorFechado === '') { 
            toast.warning("O campo Valor Fechado está vazio");
            return;
        };

        if (valorMatricula === '') { 
            toast.warning("O campo Valor da Mensalidade está vazio");
            return;
        };

        if (deOndeVeio === '') { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };

        if (quantosDiasDemorouFechar === 0) { 
            toast.warning("O campo Quantos Dias Demorou para Fechar está vazio");
            return;
        };
        
        if (quemFechou === "") { 
            toast.warning("O campo Quem Fechou está vazio");
            return;
        };

        try {
            setIsLoading(() => true);
            const data = {
                Id: props?.data?.id,
                DataFechamento: dataFechamento,
                DataInicioAulas: dataInicioAulas,
                ValorFechado: Number(valorFechado),
                DeOndeVeio: deOndeVeio,
                ValorMatricula: Number(valorMatricula),
                QuantosDiasDemorouPraFecharContrato: Number(quantosDiasDemorouFechar),
                QuemFechou: quemFechou
            };

            await api.put('api/NovosAlunos', data);

            toast.success("Aluno Editado com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario ja existente")
                toast.error("Aluno Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar este Aluno!");
        } finally {
            setIsLoading(() => false);
        };
    };

    return (
        <>
            {
                props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> 
                : 
                <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar novo Aluno' : 'Alterar Aluno'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Data do Fechamento do Contrato</p>
                        <PatternInput
                            type="date"
                            id="dt_Fechamento"
                            name="dt_Fechamento"
                            placeholder="Data do Fechamento do Contrato"
                            value={dataFechamento}
                            onChange={(event) => setDataFechamento(event.currentTarget.value)}
                        />
                        <p>Data do Inicio das Aulas</p>
                        <PatternInput
                            type="date"
                            id="dt_InicioAulas"
                            name="dt_InicioAulas"
                            placeholder="Data do Inicio das Aulas"
                            value={dataInicioAulas}
                            onChange={(event) => setDataInicioAulas(event.currentTarget.value)}
                        />
                        <p>Valor Fechado</p>
                        <PatternInput
                            type="text"
                            id="valorFechado"
                            name="valorFechado"
                            placeholder="Valor Fechado"
                            value={valorFechado}
                            onChange={(event) => setValorFechado(event.currentTarget.value.replace(",", "."))}
                        />
                        <p>Valor da Mensalidade</p>
                        <PatternInput
                            type="text"
                            id="valorMatricula"
                            name="valorMatricula"
                            placeholder="Valor da Mensalidade"
                            value={valorMatricula}
                            onChange={(event) => setValorMatricula(event.currentTarget.value.replace(",", "."))}
                        />
                        <p>Quantos Dias Demorou Para Fechar o Contrato</p>
                        <PatternInput
                            type="number"
                            id="diasParaFecharContrato"
                            name="diasParaFecharContrato"
                            placeholder="Quantos Dias Demorou Para Fechar o Contrato"
                            value={Number(quantosDiasDemorouFechar)}
                            onChange={(event) => setQuantosDiasDemorouFechar(Number(event.currentTarget.value))}
                        />
                        <p>Quem Fechou</p>
                        <PatternInput
                            type="text"
                            id="quemFechou"
                            name="quemFechou"
                            placeholder="Quem Fechou"
                            value={quemFechou}
                            onChange={(event) => setQuemFechou(event.currentTarget.value)}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">De Onde Veio</FormLabel>
                            <PatternSelect
                                placeholder='De Onde Veio' 
                                value={deOndeVeio} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setDeOndeVeio(event.currentTarget.value)}
                            >
                                <option key={1} value={"Indicacao"}>Indicação</option>
                                <option key={2} value={"Ex-Aluno"}>Ex-Aluno</option>
                                <option key={3} value={"Google"}>Google</option>
                                <option key={4} value={"Meta (facebook e instagram)"}>Meta (facebook e instagram)</option>
                                <option key={4} value={"Leads Frios"}>Leads Frios</option>
                            </PatternSelect>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddNewStudent : handleEditNewStudent}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}