import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';

interface InfoCardProps {
  titulo: string;
  valor: string | number;
  corValor: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ titulo, valor, corValor }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={2}>
      <Heading size="sm" mb={2}>{titulo}</Heading>
      <Text fontSize="xl" color={corValor}>{valor}</Text>
    </Box>
  );
};

export default InfoCard;
