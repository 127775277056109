import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { App } from "./App"
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "./contexts/ThemeContext"

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ThemeProvider>
        <BrowserRouter>
          <ColorModeScript />
          <ToastContainer
            position="top-right"
            autoClose={5000}
          />
          <App />
        </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);