import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useJwt } from 'react-jwt';
import {
    Button,
    Text,
    MenuItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    MenuItemProps,
    Avatar,
    Center,
    Stack,
    Circle,
    Input,
    Box,
    InputGroup,
    InputRightElement,
    Icon,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import PatternButton from "../Patterns/Button";
import PatternInput from "../Patterns/Input";
import { api } from "../../services/api";
import { SenhaAtualizada } from "../../contexts/VerificaSeUsuarioAtualizouSenha";

export default function PlataformaBloqueadaNecessitaAtualizarSenha() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(["loginToken"]);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const { setSenhaAtualizadaComSucesso } = SenhaAtualizada();

    const validatePassword = (password: string) => {
        const validations = {
            minLength: password.length >= 8,
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        return validations;
    };
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const passwordValidations = validatePassword(newPassword);

    const isPasswordValid = Object.values(passwordValidations).every(Boolean);

    const handleChangePassword = async () => {
        setIsLoading(true);
        const response = await api.put(`api/AlterarSenhaPorEsquecimento`, {
            Id: decodedToken?.Id,
            Senha: newPassword
        });

        if(response.data.success) {
            setSenhaAtualizadaComSucesso(true);
            toast.success('Reset concluído com sucesso!');
        }
        setIsLoading(false);
    }

    const handleClose = () => {
        if(decodedToken?.FirstAccess === "True")
            toast.info("Atualize sua senha agora!");
        else
            onClose();
    }

    useEffect(() => {
        onOpen();
    }, [onOpen]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Alteração de senha necessária</ModalHeader>
                <ModalCloseButton />

                <ModalBody display="flex" flexDirection="column" alignItems="center" gap={4}>
                    <Icon as={FaLock} w={10} h={10} color="red.500" />
                    <Text fontSize="xl" fontWeight="bold">
                        Atenção!
                    </Text>
                    <Text textAlign="center">
                        Para garantir a proteção de seus dados, solicitamos que você atualize sua senha.
                    </Text>
                    <Text textAlign="center">
                        Lembramos que, sob nenhuma circunstância, sua senha deve ser compartilhada com terceiros. Seu acesso à plataforma é pessoal e intransferível, e manter suas credenciais seguras é essencial para a continuidade do uso com total segurança.
                    </Text>
                    <Text textAlign="center">
                        Agradecemos pela compreensão e colaboração.
                    </Text>
                    <Text textAlign="center" color={"red"}>
                        Obs: É recomendado sair e fazer o login novamente após atualizar sua senha!
                    </Text>

                    <Text fontWeight="700" fontSize="1.2rem">Alterar Senha</Text>
                    <InputGroup size="lg">
                        <PatternInput
                            type={showNewPassword ? "text" : "password"}
                            id="new-password"
                            name="new-password"
                            placeholder="Nova Senha"
                            value={newPassword}
                            onChange={(event) => setNewPassword(event.currentTarget.value)}
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>

                    <InputGroup size="lg">
                        <PatternInput
                            type={showConfirmNewPassword ? "text" : "password"}
                            id="confirm-new-password"
                            name="confirm-new-password"
                            placeholder="Confirmar nova Senha"
                            value={confirmNewPassword}
                            onChange={(event) => setConfirmNewPassword(event.currentTarget.value)}
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                                {showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                        
                    <Box mt={4}>
                            <Text>
                                É necessário que a senha contenha pelo menos:
                            </Text>
                            <Text color={passwordValidations.minLength ? "green" : "red"}>
                                - 8 caracteres
                            </Text>
                            <Text color={passwordValidations.hasUpperCase ? "green" : "red"}>
                                - 1 Letra maiúscula
                            </Text>
                            <Text color={passwordValidations.hasLowerCase ? "green" : "red"}>
                                - 1 Letra minúscula
                            </Text>
                            <Text color={passwordValidations.hasNumber ? "green" : "red"}>
                                - 1 Número
                            </Text>
                            <Text color={passwordValidations.hasSpecialChar ? "green" : "red"}>
                                - 1 Caractere especial
                            </Text>
                        </Box>
                </ModalBody>


                <ModalFooter>
                    <PatternButton 
                        onClick={handleChangePassword} 
                        isLoading={isLoading} 
                        isDisabled={!isPasswordValid || newPassword !== confirmNewPassword || !newPassword || !confirmNewPassword}
                    >
                        Alterar
                    </PatternButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
