import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button"
import PatternInput from "../../Patterns/Input";
import { formatCep, formatCpfCnpj, formatDate, formatPhone, TratamentoData } from "../../../utils/formatter";
import moment from "moment";
import PatternSelect from "../../Patterns/Select";
import PatternCheckbox from "../../Patterns/Checkbox";
import PatternMultipleSellect from "../../Patterns/MultipleSelect";
import Select, { SingleValue } from 'react-select';

interface dataResponse {
    message: string;
    success: boolean;
    errorMessage: string;
    object: object;
    object_Secondary: object;
}

export default function StudentModal(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [name, setName] = useState<string>("");
    const [CPF, setCPF] = useState<string>("");
    const [Dt_Nascimento, setDt_Nascimento] = useState<string>(moment().format('YYYY-MM-DD'));
    const [Email, setEmail] = useState<string>();
    const [PhoneNumber1, setPhoneNumber1] = useState<string>();
    const [PhoneNumber2, setPhoneNumber2] = useState<string>();
    const [Plan, setPlan] = useState<string>();
    const [DayWeek, setDayWeek] = useState<string[]>([""]);
    const [Time, setTime] = useState<Number>(0);
    const [QuantityClassPerDay, setQuantityClassPerDay] = useState<Number>(1);
    const [TimeClassRoom, setTimeClassRoom] = useState<Number>(0);
    const [SituationStudent, setSituationStudent] = useState<string>("");
    const [Value, setValue] = useState<string>("");
    const [SellerResponsible, setSellerResponsible] = useState<string>("");
    const [ativo, setAtivo] = useState<string>("ATIVO");
    const [dt_InicioAulas, setDt_InicioAulas] = useState<string>(moment().format("YYYY-MM-DD"))
    const [diasDobradinhaSemana, setDiasDobradinhaSemana] = useState<string[]>([""]);
    const [alunoTemDobradinha, setAlunoTemDobradinha] = useState<boolean>(false);
    const [deOndeVeio, setDeOndeVeio] = useState<string>("");
    const [dataFechamento, setDataFechamento] = useState<string>(moment().format('YYYY-MM-DD'));
    const [motivoDesistencia, setMotivoDesistencia] = useState<string>("");
    const [planoOutro, setPlanoOutro] = useState<string>("");
    const [responsavel, setResponsavel] = useState<string>("");
    const [idade, setIdade] = useState<Number>(0);
    const hoje = new Date();
    const [usuarios, setUsuarios] = useState<UserSelectBox[]>([]);
    const [choosenUsuario, setChoosenUsuario] = useState<SingleValue<{ value: string; label: string }>>();
    const [choosenUsuarioId, setChoosenUsuarioId] = useState<string>("");
    const [observation, setObservation] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [turmas, setTurmas] = useState<Turmas[]>([]);
    const [turmaSelecionada, setTurmaSelecionada] = useState<number>(0);
    const [choosenTurma, setChoosenTurma] = useState<SingleValue<{ value: number; label: string }>>();
    const [dt_RetornoAluno, setDt_RetornoAluno] = useState<string>("");


    const [isLoading, setIsLoading] = useState<boolean>(false);

    var diasSemana = [
        {
            label: "SEGUNDA-FEIRA",
            value: "segunda"
        },
        {
            label: "TERÇA-FEIRA",
            value: "terca"
        },
        {
            label: "QUARTA-FEIRA",
            value: "quarta"
        },
        {
            label: "QUINTA-FEIRA",
            value: "quinta"
        },
        {
            label: "SEXTA-FEIRA",
            value: "sexta"
        }
    ]

    useEffect(() => {
        if(isOpen) {
            api.get("/api/Turmas/ObterTurmasAtivas")
            .then((response) => {
                setTurmas(response.data.object);
            })   
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(false))

            api.get("/api/ObterUsuariosSelectBox")
            .then((response) => {
                const vendedores = response.data.object.filter((user:UserSelectBox) => user.role === "Vendedor");
                setUsuarios(vendedores);
    
                if (props?.modalType === 'edit') {
                    const usuarioSelecionado = vendedores.find((x:UserSelectBox) => x.nome === props?.data?.vendedorResponsavel);
    
                    if (usuarioSelecionado) {
                        setChoosenUsuario({ value: usuarioSelecionado.id, label: usuarioSelecionado.nome });
                        setChoosenUsuarioId(usuarioSelecionado.id);
                    }
                }
            
            })   
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(false))
        }
    }, [isOpen]);

    useEffect(() => {
        let usuarioSelecionado: UserSelectBox = {
            id: "",
            nome: "",
            role: ""
          };

        if (props?.modalType === 'edit') {
            setDt_RetornoAluno(moment(moment(props?.data?.dt_RetornoAluno, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setDataFechamento(moment(moment(props?.data?.dataFechamentoContrato, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setName(props?.data?.nome);
            setDt_Nascimento(moment(moment(props?.data?.dataDeNascimento, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setPhoneNumber1(props?.data?.telefone);
            setPhoneNumber2(props?.data?.phoneNumber2);
            setPlan(props?.data?.plano);
            
            if(props?.data?.plano === "Outro")
                setPlanoOutro(props?.data?.plano);

            setResponsavel(props?.data?.responsavel);
            setTime(props?.data?.mesesDeContrato);
            setTimeClassRoom(props?.data?.tempoDeAula);
            setCPF(props?.data?.cpf);
            setSituationStudent(props?.data?.situacaoDoEstudante);
            setChoosenUsuario({ value: usuarioSelecionado.id, label: usuarioSelecionado.nome });
            setEmail(props?.data?.email);
            setValue(props?.data?.valorMatricula);
            setDeOndeVeio(props?.data?.deOndeVeio);
            setQuantityClassPerDay(props?.data?.quantidadeDeAulasPorDia);
            setAtivo(props?.data?.situationStudent);
            setDt_InicioAulas(moment(moment(props?.data?.dataInicioAulas, 'DD/MM/YYYY')).format('YYYY-MM-DD'));
            setAlunoTemDobradinha(props?.data?.temDobradinha === "SIM" ? true : false);
            setMotivoDesistencia(props?.data?.motivoDesistencia);
            setObservation(props?.data?.observacao);
        }

        

    }, [isOpen, props]);

    const handleChangeDiaDobradinha = (event : MultipleSelect) =>{
        var dias = [""];

        dias = dias.filter((b) => b !== "");

        event.map((obj) => {
            dias.push(obj.value);
        });

        setDiasDobradinhaSemana(dias);
    }

    const handleChangeDiaAula = (event : MultipleSelect) =>{
        var dias = [""];

        dias = dias.filter((b) => b !== "");

        event.map((obj) => {
            dias.push(obj.value);
        });

        setDayWeek(dias);
    }

    const handleCheckBoxDobradinha = (event : React.ChangeEvent<HTMLInputElement>) => {
        if(TimeClassRoom != 30) {
            setAlunoTemDobradinha(false);
        } else {
            setAlunoTemDobradinha(event.currentTarget.checked);
        }
    }

    const handleChangeDtNascimento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDt_Nascimento(event.target.value)
        setIdade(calcularIdade(event.target.value));
    }

    function calcularIdade(Dt_Nascimento: string) {
        let dataNascimento = new Date(Dt_Nascimento);       
        let dataAtual = new Date();
        let idadeCalculada = dataAtual.getFullYear() - dataNascimento.getFullYear();

        let mesAtual = dataAtual.getMonth();
        let diaAtual = dataAtual.getDate();
        let mesNascimento = dataNascimento.getMonth();
        let diaNascimento = dataNascimento.getDate();
        
        if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
            idadeCalculada--;
        }
        
        return idadeCalculada;
    }

    const handleAddStudent = async () => {

        if (dt_InicioAulas === '') { 
            toast.warning("O campo Data de Inicio está vazio");
            return;
        };

        if (deOndeVeio === '') { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };

        if (name === '') { 
            toast.warning("O campo nome está vazio");
            return;
        };

        if (Dt_Nascimento === '') { 
            toast.warning("O campo Data de Nascimento está vazio");
            return;
        };

        if (QuantityClassPerDay === 0) { 
            toast.warning("O campo Quantidade de Aula por dia está vazio");
            return;
        };

        if (PhoneNumber1 === '') { 
            toast.warning("O campo Telefone está vazio");
            return;
        };

        if (CPF === '') { 
            toast.warning("O campo CPF está vazio");
            return;
        };

        if (Plan === '' || (Plan === 'Outro' && planoOutro === '')) { 
            toast.warning("O campo Plano está vazio");
            return;
        };

        if (DayWeek.length === 0) { 
            toast.warning("O campo Dias da Semana está vazio");
            return;
        };

        if (TimeClassRoom === 0) { 
            toast.warning("O campo Tempo de Aula está vazio");
            return;
        };

        if (Time === 0) { 
            toast.warning("O campo Tempo de Contrato está vazio");
            return;
        };

        if (Value === '') { 
            toast.warning("O campo Valor da Mensalidade está vazio");
            return;
        };

        if (choosenUsuarioId === '') { 
            toast.warning("O campo Vendedor responsável está vazio");
            return;
        };

        if(alunoTemDobradinha)
        {
            if(diasDobradinhaSemana.length === 0){
                toast.warning("É obrigatório selecionar ao menos 1 dia de dobradinha");
                return;
            }
        }

        if (dataFechamento === '' || dataFechamento === 'Invalid date') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (motivoDesistencia === '' && SituationStudent === 'DESISTENCIA') { 
            toast.warning("O campo Motivo da Desistência está vazio");
            return;
        };

        try {
            setIsLoading(() => true);
            const data = {
                Name: name,
                CPF: CPF,
                Dt_Nascimento: Dt_Nascimento,
                Email: Email,
                PhoneNumber1: PhoneNumber1,
                PhoneNumber2: PhoneNumber2,
                Responsavel: responsavel,
                Plan: Plan === "Outro" ? planoOutro : Plan,
                DayWeek: DayWeek,
                Time: Time,
                TimeClassRoom: TimeClassRoom,
                SituationStudent: SituationStudent,
                Value: Number(Value),
                SellerResponsible: choosenUsuarioId,
                QuantityClassPerDay: Number(QuantityClassPerDay),
                Ativo: true,
                Dt_FechamentoContrato: dataFechamento,
                DeOndeVeio: deOndeVeio,
                dt_InicioAulas: dt_InicioAulas,
                diasDobradinhaSemana: diasDobradinhaSemana,
                temDobradinha: alunoTemDobradinha,
                motivoDesistencia: motivoDesistencia,
                Observacao: observation,
                TurmaId: turmaSelecionada,
                Dt_RetornoAluno: (dt_RetornoAluno === '' || dt_RetornoAluno.toUpperCase() === 'DD/MM/YYYY') ? null : dt_RetornoAluno,
            };

            console.log('Teste');

            await api.post('api/StudentRegister', data);

            toast.success("Aluno criado com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario ja existente")
                toast.error("Aluno Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar este Aluno!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleEditStudent = async () => {
        if (name === '') { 
            toast.warning("O campo nome está vazio");
            return;
        };

        if (dataFechamento === '') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (deOndeVeio === '') { 
            toast.warning("O campo De Onde Veio está vazio");
            return;
        };

        if (Dt_Nascimento === '') { 
            toast.warning("O campo Data de Nascimento está vazio");
            return;
        };

        if (QuantityClassPerDay === 0) { 
            toast.warning("O campo Quantidade de Aula por dia está vazio");
            return;
        };

        if (PhoneNumber1 === '') { 
            toast.warning("O campo Telefone está vazio");
            return;
        };

        if (CPF === '') { 
            toast.warning("O campo CPF está vazio");
            return;
        };

        if (Plan === '' || (Plan === 'Outro' && planoOutro === '')) { 
            toast.warning("O campo Plano está vazio");
            return;
        };

        if (DayWeek.length === 0) { 
            toast.warning("O campo Dias da Semana está vazio");
            return;
        };

        if (TimeClassRoom === 0) { 
            toast.warning("O campo Tempo de Aula está vazio");
            return;
        };

        if (Time === 0) { 
            toast.warning("O campo Tempo de Contrato está vazio");
            return;
        };

        if (SituationStudent === '') { 
            toast.warning("O campo Situação do Estudante está vazio");
            return;
        };

        if (Value === '') { 
            toast.warning("O campo Valor da Mensalidade está vazio");
            return;
        };

        if (choosenUsuarioId === '') { 
            toast.warning("O campo Vendedor responsável está vazio");
            return;
        };

        if (dt_InicioAulas === '') { 
            toast.warning("O campo Data de Inicio está vazio");
            return;
        };

        if (dataFechamento === '' || dataFechamento === 'Invalid date') { 
            toast.warning("O campo Data de Fechamento está vazio");
            return;
        };

        if (motivoDesistencia === '' && SituationStudent === 'DESISTENCIA') { 
            toast.warning("O campo Motivo da Desistência está vazio");
            return;
        };

        try {
            setIsLoading(() => true);

            const data = {
                Id: props?.data?.id,
                Name: name,
                CPF: CPF,
                Dt_FechamentoContrato: dataFechamento,
                Dt_Nascimento: Dt_Nascimento,
                Email: Email,
                PhoneNumber1: PhoneNumber1,
                PhoneNumber2: PhoneNumber2,
                Responsavel: responsavel,
                Plan: Plan === "Outro" ? planoOutro : Plan,
                DayWeek: DayWeek,
                Time: Time,
                TimeClassRoom: TimeClassRoom,
                DeOndeVeio: deOndeVeio,
                SituationStudent: SituationStudent,
                Value: Number(Value),
                SellerResponsible: choosenUsuarioId,
                QuantityClassPerDay: Number(QuantityClassPerDay),
                Ativo: true,
                dt_InicioAulas: dt_InicioAulas,
                diasDobradinhaSemana: diasDobradinhaSemana,
                temDobradinha: alunoTemDobradinha,
                motivoDesistencia: motivoDesistencia,
                Observacao: observation,
                Dt_RetornoAluno: (dt_RetornoAluno === '' || dt_RetornoAluno.toUpperCase() === 'DD/MM/YYYY') ? null : dt_RetornoAluno,
            };
            await api.put('api/StudentRegister', data);

            toast.success("Aluno alterado com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Usuario nao encontrado")
                toast.error("Aluno não encontrado");
            else
                toast.error("Um erro ocorreu ao cadastrar este Aluno!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleClose = () => {
            setName("");
            setDt_Nascimento("");
            setPhoneNumber1("");
            setPhoneNumber2("");
            setPlan("");
            setPlanoOutro("");
            setTime(0);
            setTimeClassRoom(0);
            setDayWeek([""]);
            setCPF("");
            setSituationStudent("");
            setSellerResponsible("");
            setEmail("");
            setAtivo("");
            onClose();
    };

    return (
        <>
            {props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> : <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>}

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar Novo Aluno' : 'Alterar Aluno'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Nome do Aluno</p>
                        <PatternInput
                            type="text"
                            id="student-name"
                            name="student-name"
                            placeholder="Nome do Aluno..."
                            value={name}
                            onChange={(event) => setName(event.currentTarget.value)}
                        />
                        <p>CPF do Aluno</p>
                        <PatternInput
                            type="text"
                            id="student-CPF"
                            name="student-CPF"
                            placeholder="CPF do Aluno..."
                            maxLength={14}
                            value={CPF}
                            onChange={(event) => setCPF(formatCpfCnpj(event.currentTarget.value))}
                        />
                        <p>Data de Nascimento do Aluno</p>
                        <PatternInput
                            type="date"
                            id="student-Dt_Nascimento"
                            name="student-Dt_Nascimento"
                            placeholder="Data de Nascimento do Aluno..."
                            value={Dt_Nascimento}
                            onChange={(event) => handleChangeDtNascimento(event)}
                        />
                        {
                            idade < 18 ?
                            <>
                                <p>Nome do Responsável do Menor</p>
                                <PatternInput
                                    type="text"
                                    id="estudante-responsavel"
                                    name="student-respnsavel"
                                    placeholder="Nome do Responsável..."
                                    value={responsavel}
                                    onChange={(event) => setResponsavel(event.currentTarget.value)}
                                />
                            </>
                            :
                            null
                        }
                        <p>Email do Aluno</p>
                        <PatternInput
                            type="text"
                            id="student-Email"
                            name="student-Email"
                            placeholder="Email do Aluno..."
                            value={Email}
                            onChange={(event) => setEmail(event.currentTarget.value)}
                        />
                        <p>Telefone Principal do Aluno</p>
                        <PatternInput
                            type="text"
                            id="student-PhoneNumber1"
                            name="student-PhoneNumber1"
                            placeholder="Telefone Principal do Aluno..."
                            maxLength={20}
                            value={PhoneNumber1}
                            onChange={(event) => setPhoneNumber1(formatPhone(event.currentTarget.value))}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Plano do Aluno</FormLabel>
                            <PatternSelect 
                                placeholder='Plano do Aluno' 
                                value={Plan}
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setPlan(event.currentTarget.value)}
                            >
                                <option key={1} value={"2 aulas de 30 min"}>2 aulas de 30 min</option>
                                <option key={2} value={"3 aulas de 30 min"}>3 aulas de 30 min</option>
                                <option key={3} value={"3 aulas de 60 min"}>3 aulas de 60 min</option>
                                <option key={4} value={"4 aulas de 30 min"}>4 aulas de 30 min</option>
                                <option key={5} value={"5 aulas de 30 min"}>5 aulas de 30 min</option>
                                <option key={6} value={"4 aulas de 60 min"}>4 aulas de 60 min</option>
                                <option key={7} value={"5 aulas de 60 min"}>5 aulas de 60 min</option>
                            </PatternSelect>
                        </FormControl>
                        <p>Dias de Aula na Semana do Aluno</p>
                        <PatternMultipleSellect 
                            onChange={(event:MultipleSelect) => {handleChangeDiaAula(event)}}
                            options={diasSemana.map((diaSemana) => {
                            const data = {
                                    label: diaSemana.label,
                                    value: diaSemana.value
                            } 
                            return data;
                            })}
                        />
                        {props.modalType === 'edit' ? 
                            <>
                                <p>Dias de Aula na Semana</p>
                                <PatternInput
                                    type="text"
                                    id="student-DayWeek"
                                    isDisabled={true}
                                    name="student-DayWeek"
                                    value={props?.data?.diasDeAulaNaSemana}
                                />
                                
                            </>
                            :
                            null
                        }
                        <p>Tempo de Contrato do Aluno em Mesês</p>
                        <PatternInput
                            type="text"
                            id="student-Time"
                            name="student-Time"
                            placeholder="Tempo de Contrato do Aluno em Mesês..."
                            maxLength={2}
                            value={Number(Time)}
                            onChange={(event) => setTime(Number(event.currentTarget.value))}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Tempo de Aula do Aluno</FormLabel>
                            <PatternSelect 
                                placeholder='TEMPO DE AULA' 
                                value={Number(TimeClassRoom)} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTimeClassRoom(Number(event.currentTarget.value))}
                            >
                                <option key={1} value={30}>30 MINUTOS</option>
                                <option key={2} value={60}>60 MINUTOS</option>
                            </PatternSelect>
                        </FormControl>
                        <p>Valor da Mensalidade</p>
                        <PatternInput
                            type="text"
                            id="student-Value"
                            name="student-Value"
                            placeholder="Valor da Mensalidade"
                            value={Value}
                            onChange={(event) => setValue(event.currentTarget.value.replace(",", "."))}
                        />
                        <FormControl>
                            <FormLabel fontWeight="bolder">Vendedor Responsável</FormLabel>
                            <Select
                                id="vendedorResponsible"
                                name="vendedorResponsible"
                                placeholder="Vendedor Responsável..."
                                noOptionsMessage={(msg) => msg.inputValue = 'Nenhuma correspondência.'}
                                value={choosenUsuario}
                                onChange={(event) => { 
                                    setChoosenUsuario(event); 
                                    if (event) {
                                        setChoosenUsuarioId(event.value);
                                    } else {
                                        setChoosenUsuarioId("");
                                    }
                                }}
                                options={usuarios.map(t => ({ value: t.id, label: t.nome }))}
                            />
                        </FormControl>
                        {
                            props?.modalType === 'new' ?
                            <FormControl>
                                <FormLabel fontWeight="bolder">Turma Pertencente</FormLabel>
                                <Select
                                    id="turma"
                                    name="turma"
                                    placeholder="Turma Pertencente..."
                                    noOptionsMessage={(msg) => msg.inputValue = 'Nenhuma correspondência.'}
                                    value={choosenTurma}
                                    onChange={(event) => { 
                                        setChoosenTurma(event); 
                                        if (event) {
                                            setTurmaSelecionada(event.value);
                                        } else {
                                            setTurmaSelecionada(0);
                                        }
                                    }}
                                    options={turmas.map(t => ({ value: t.id, label: t.nome }))}
                                />
                            </FormControl>
                            :
                            null
                        }
                        <p>Aluno tem Dobradinha?</p>
                        <PatternCheckbox 
                            isChecked={alunoTemDobradinha}
                            onChange={(event) => handleCheckBoxDobradinha(event)}
                            isDisabled={TimeClassRoom != 30 ? true : false}
                        />
                        {
                            alunoTemDobradinha ? 
                            <>
                                <p>Dias de Aula Dobradinha</p>
                                <PatternMultipleSellect 
                                    onChange={(event:MultipleSelect) => {handleChangeDiaDobradinha(event)}}
                                    options={diasSemana.map((diaSemana) => {
                                    const data = {
                                            label: diaSemana.label,
                                            value: diaSemana.value
                                    } 
                                    return data;
                                    })}
                                />
                            </>
                            :
                            null
                        }
                        <p>Data do Inicio das Aulas</p>
                        <PatternInput
                            type="date"
                            id="dt_InicioAulas"
                            name="dt_InicioAulas"
                            placeholder="Data do Inicio das Aulas"
                            value={dt_InicioAulas}
                            onChange={(event) => setDt_InicioAulas(event.currentTarget.value)}
                        />
                        <p>Data do Pagamento da Matrícula</p>
                        <PatternInput
                            type="date"
                            id="dt_Fechamento"
                            name="dt_Fechamento"
                            placeholder="Data do Pagamento da Matrícula"
                            value={dataFechamento}
                            onChange={(event) => setDataFechamento(event.currentTarget.value)}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">De Onde Veio</FormLabel>
                            <PatternSelect
                                value={deOndeVeio} 
                                placeholder={" "}
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setDeOndeVeio(event.currentTarget.value)}
                            >
                                <option key={1} value={"Indicacao"}>Indicação</option>
                                <option key={2} value={"Ex-Aluno"}>Ex-Aluno</option>
                                <option key={3} value={"Google"}>Google</option>
                                <option key={4} value={"Meta (facebook e instagram)"}>Meta (facebook e instagram)</option>
                                <option key={5} value={"Leads Frios"}>Leads Frios</option>
                                <option key={6} value={"Lead Organico"}>Lead Organico</option>
                                <option key={7} value={"TikTok"}>TikTok</option>
                                <option key={7} value={"Influenciadores"}>Influenciadores</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Situação do Aluno</FormLabel>
                            <PatternSelect 
                                placeholder='Situação do Aluno' 
                                value={SituationStudent} 
                                onChange={(event) => setSituationStudent(event.currentTarget.value)}
                            >
                                <option key={1} value={"ATIVO"}>ATIVO</option>
                                <option key={2} value={"PAUSADO"}>PAUSADO</option>
                                <option key={3} value={"DESISTENCIA"}>DESISTÊNCIA</option>
                                <option key={4} value={"INADIMPLENTE"}>INADIMPLENTE</option>
                                <option key={5} value={"PAUSADO NA AGENDA"}>PAUSADO NA AGENDA</option>
                            </PatternSelect>
                        </FormControl>
                        {
                            SituationStudent === 'DESISTENCIA' ?
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Motivo da Desistência</FormLabel>
                                <PatternSelect 
                                    value={motivoDesistencia} 
                                    onChange={(event) => setMotivoDesistencia(event.currentTarget.value)}
                                >
                                    <option key={0} value={""}></option>
                                    <option key={1} value={"FALTA DE TEMPO"}>FALTA DE TEMPO</option>
                                    <option key={2} value={"MUITAS TROCAS"}>MUITAS TROCAS</option>
                                    <option key={3} value={"FINANCEIRO"}>FINANCEIRO</option>
                                    <option key={4} value={"PROBLEMAS PESSOAIS"}>PROBLEMAS PESSOAIS</option>
                                    <option key={5} value={"INADEQUAÇÃO"}>INADEQUAÇÃO</option>
                                    <option key={6} value={"FINALIZOU CONTRATO"}>FINALIZOU CONTRATO</option>
                                    <option key={7} value={"PROBLEMAS COM ESCOLA"}>PROBLEMAS COM ESCOLA</option>
                                    <option key={8} value={"SENTE QUE PAROU DE EVOLUIR"}>SENTE QUE PAROU DE EVOLUIR</option>
                                    <option key={9} value={"NEGATIVAÇÃO"}>NEGATIVAÇÃO</option>
                                    <option key={10} value={"ALUNO DESISTIU DE COMEÇAR"}>ALUNO DESISTIU DE COMEÇAR</option>
                                </PatternSelect>
                            </FormControl>
                            :
                            null
                        }
                        {
                            SituationStudent === 'PAUSADO NA AGENDA' ?
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Data de Retorno do Aluno</FormLabel>
                                <PatternInput
                                    type="date"
                                    id="dt_RetornoAluno"
                                    name="dt_RetornoAluno"
                                    placeholder="Data de Retorno do Aluno"
                                    value={dt_RetornoAluno}
                                    max={moment().add(+30, "days").format('YYYY-MM-DD')}
                                    min={moment().add(0, "days").format('YYYY-MM-DD')}
                                    onChange={(event) => setDt_RetornoAluno(event.currentTarget.value)}
                                />
                            </FormControl>
                            :
                            null
                        }
                        <p>Observação</p>
                        <PatternInput
                            type="text"
                            id="observacion"
                            name="observacion"
                            placeholder="Observação"
                            value={observation}
                            onChange={(event) => setObservation(event.currentTarget.value)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={handleClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddStudent : handleEditStudent}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};