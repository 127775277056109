import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  Tooltip,
  Circle,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import moment from 'moment';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { MdClass } from 'react-icons/md';
import { useJwt } from 'react-jwt';
import Select, { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import PatternButton from '../../components/Patterns/Button';
import PatternSelect from '../../components/Patterns/Select';
import { api } from '../../services/api';

interface NewClassMassModalProps {
  students: Student[];
  choosenTeacher: { value: number; label: string },
  setTick: React.Dispatch<React.SetStateAction<number>>;
};

export function NewClassMassModal({ students, choosenTeacher, setTick }: NewClassMassModalProps) {
  const [cookies] = useCookies(['loginToken']);
  const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [initialTime, setInitialTime] = useState<string>('--:--');
  const [finalTime, setFinalTime] = useState<string>('--:--');
  const [classType, setClassType] = useState<string>('');
  const [weekDays, setWeekDays] = useState<number[]>([]);
  const [choosenStudent, setChoosenStudent] = useState<SingleValue<{ value: number; label: string }>>();
  const [choosenStudentId, setChoosenStudentId] = useState<number>(0);
  const [studentName, setStudentName] = useState<string>('');
  const [reservedDate, setReservedDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeMass, setTypeMass] = useState<string>('post');

  const handleCreateClassMass = async () => {
    if(typeMass === 'post'){
      if (initialTime === '--:--' || finalTime === '--:--' || classType === '' || weekDays?.length <= 0) {
        toast.warn('Favor preencher as informações corretamente!');
        return;
      }
    }
    else{
      if (initialTime === '--:--' || finalTime === '--:--' || weekDays?.length <= 0) {
        toast.warn('Favor preencher as informações corretamente!');
        return;
      }
    }

    setIsLoading(() => true);
    try {
      if (typeMass === 'post') {
        const data: any = {
          horaInicialAula: moment().format(`YYYY-MM-DD[T${initialTime.split(':')[0]}:${initialTime.split(':')[1]}:00.000Z]`),
          horaFinalAula: moment().format(`YYYY-MM-DD[T${finalTime.split(':')[0]}:${finalTime.split(':')[1]}:00.000Z]`),
          diaSemana: weekDays.sort((a, b) => a - b),
          professor_Id: choosenTeacher.value,
          aluno_Id: choosenStudentId,
          id_QuemCriou: decodedToken?.Id,
          tipoAula: classType,
          atualizacaoEmMassa: true
        };

        if (classType.includes('Reservado')) {
          data["nomeAluno"] = studentName;
          data["previsaoInicioAulas"] = new Date(reservedDate);
        };

        await api.post('api/Agenda/CriarAgendamento', data);
      } else {
        await api.post('api/Agenda/ApagarAgendamento', {
            professor_Id: choosenTeacher.value,
            aluno_Id: 0,
            dia: weekDays.sort((a, b) => a - b),
            horaInicial: moment().format(`YYYY-MM-DD[T${initialTime.split(':')[0]}:${initialTime.split(':')[1]}:00.000Z]`),
            horaFim: moment().format(`YYYY-MM-DD[T${finalTime.split(':')[0]}:${finalTime.split(':')[1]}:00.000Z]`),
            apagarAgendamentoMassa: true,
        });
      };

      toast.success(`Agendamentos em Massa ${typeMass === 'post' ? 'criados' : 'deletados'} com sucesso!`);
      setTick(prev => prev + 1);
      handleClose();
    } catch (ex: any) {
      if (ex.response.data.message === 'Ja existe aula nesse horario para o professor')
        toast.error('Ja existe aula nesse horário para o professor!');
      else
        toast.error(`Houve um erro ao ${typeMass === 'post' ? 'criar' : 'deletar'} os Agendamentos em Massa. Contate um Administrador!`);
    }
    finally {
      setIsLoading(() => false);
    };

  };

  const handleClose = () => {
    setInitialTime('--:--');
    setFinalTime('--:--');
    setWeekDays([]);
    setClassType('');
    setStudentName('');
    setReservedDate(moment().format('YYYY-DD-MM'));
    onClose();
  };

  const handleChangeClassType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setClassType(event.currentTarget.value);
    if (!event.currentTarget.value.includes('Aula')) {
      setChoosenStudent(undefined);
      setChoosenStudentId(0);
    };
  };

  const handleChooseWeekDay = (weekDay: number) => {
    const newWeekDays = [...weekDays];
    const idx = newWeekDays.findIndex(d => d === weekDay)

    if (idx < 0) {
      newWeekDays.push(weekDay);
    } else {
      newWeekDays.splice(idx, 1);
    };

    setWeekDays(newWeekDays);
  };

  return (
    <>
      <PatternButton icon={MdClass} pl={10} pr={10} onClick={onOpen}>Agendamentos em Massa</PatternButton>

      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{typeMass === 'post' ? 'Criar' : 'Deletar'} agendamento em massa para {choosenTeacher.label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={setTypeMass} value={typeMass}>
              <Stack direction='row' justify="center" mb={4}>
                <Radio value="post">Criar</Radio>
                <Radio value="delete">Deletar</Radio>
              </Stack>
            </RadioGroup>
            <Flex gap={4}>
              <FormControl>
                <FormLabel fontWeight="bolder">Horário Inicial</FormLabel>
                <Input id="inital-time" name="initial-time" type="time" value={initialTime} onChange={(event) => setInitialTime(event.currentTarget.value)} />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bolder">Horário Final</FormLabel>
                <Input id="final-time" name="final-time" type="time" value={finalTime} onChange={(event) => setFinalTime(event.currentTarget.value)} />
              </FormControl>
            </Flex>

            <Flex align="center" justify="center" mt={4} gap={2}>
              {['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'].map((d, idx) => {
                return (
                  <Tooltip key={d} hasArrow label={d} bg="primary.500" placement="bottom">
                    <Circle
                      color="white"
                      bg={weekDays.find(day => day === idx + 1) ? "primary.500" : "blue.300"}
                      padding="3px 10px"
                      onClick={() => handleChooseWeekDay(idx + 1)}
                      cursor="pointer"
                    >
                      {d.substring(0, 1)}
                    </Circle>
                  </Tooltip>
                );
              })}
            </Flex>

            {
              typeMass === 'post' && (
                <FormControl mt={4}>
                  <FormLabel fontWeight="bolder">Tipo do Agendamento</FormLabel>
                  <PatternSelect
                    id="class-type"
                    name="class-type"
                    value={classType}
                    onChange={handleChangeClassType}
                  >
                    <Box as="option" value={""} disabled>Selecione o Tipo deste Agendamento...</Box>
                    <Box as="option" value={"Horário ocupado"} color="black">Horário ocupado</Box>
                    <Box as="option" value={"Reservado"} color="red.600">Reservado</Box>
                    <Box as="option" value={"Break"} color="gray">Break</Box>
                  </PatternSelect>
                  {classType.includes('Aula') && (
                    <FormControl mt={4}>
                      <FormLabel fontWeight="bolder">Aluno</FormLabel>
                      <Select
                        id="student"
                        name="student"
                        placeholder="Escolha um aluno..."
                        noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                        value={choosenStudent}
                        onChange={(event) => { setChoosenStudent(event); setChoosenStudentId(Number(event?.value)) }}
                        options={students.map(t => { return { value: t.id, label: t.nome } })}
                      />
                    </FormControl>
                  )}
                  {classType === 'Reservado' && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel htmlFor="student-name" fontWeight="bolder">Nome do Aluno</FormLabel>
                        <Input
                          id="student-name"
                          name="student-name"
                          type="text"
                          value={studentName}
                          onChange={(event) => setStudentName(event.currentTarget.value)}
                        />
                      </FormControl>
                      <FormControl mt={4}>
                        <FormLabel htmlFor="reserved-date" fontWeight="bolder">Data Início Aluno</FormLabel>
                        <Input
                          id="reserved-date"
                          name="reserved-date"
                          type="date"
                          max={moment().add(15, "days").format('YYYY-MM-DD')}
                          value={reservedDate}
                          onChange={(event) => setReservedDate(event.currentTarget.value)}
                        />
                      </FormControl>
                    </>
                  )}
                </FormControl>
              )
            }
          </ModalBody >

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose} isDisabled={isLoading}>
              Cancelar
            </Button>
            <PatternButton isLoading={isLoading} onClick={handleCreateClassMass}>{typeMass === 'post' ? 'Criar' : 'Deletar'}</PatternButton>
          </ModalFooter>
        </ModalContent >
      </Modal >
    </>
  )
}