import { Tabs, TabList, Tab, TabPanels, TabPanel, Center, CircularProgress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useJwt } from "react-jwt";
import PatternCard from "../../components/Patterns/Card";
import { api } from "../../services/api";
import ReposicoesConfig from "../Reposicoes/Reposicoes";
import AulasCanceladasPorProfessorPorValorMonetario from "./AulasCacenladasPorProfessorPorValorMonetario";
import AulasCanceladas from "./AulasCanceladas";
import AulasCanceladasPorProfessor from "./AulasCanceladasPorProfessor";
import AulasCanceladasPorValorMonetario from "./AulasCanceladasPorValorMonetario";
import HistoricoPorAluno from "./historicoPorAluno";


export function HistoricoAulas() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [aluno, setAluno] = useState<Student[]>([]);
    const [professor, setProfessor] = useState<Teachers[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if(decodedToken){
            api.get("/api/StudentSelectBox").then((response) => {
                setAluno(response.data.object);
            })
    
            api.get("/api/TeacherSelectBox").then((response) => {
                setProfessor(response.data.object);
            })
            .finally(() => setIsLoading(false));
        }
    }, [decodedToken])

    return (
        <>
            <Helmet>
                <title>King | Aulas</title>
            </Helmet>
            <PatternCard title="Aulas">
                {
                    isLoading ? 
                        <Center display={isLoading ? 'initial' : 'none'} mt={48} flexDir="column">
                            <CircularProgress isIndeterminate color="secondary.500" />
                            <Text>Carregando</Text>
                        </Center>
                    :
                        <Tabs>
                            <TabList>
                                {/* <Tab color="primary.500" _hover={{ color: "secondary.500" }}>AULAS CANCELADAS</Tab> */}
                                <Tab color="primary.500" _hover={{ color: "secondary.500" }}>AULAS CANCELADAS POR VALOR</Tab>
                                <Tab color="primary.500" _hover={{ color: "secondary.500" }}>HISTÓRICO POR ALUNO</Tab>
                                {/* <Tab color="primary.500" _hover={{ color: "secondary.500" }}>AULAS CANCELADAS POR PROFESSOR</Tab> */}
                                <Tab color="primary.500" _hover={{ color: "secondary.500" }}>AULAS CANCELADAS POR PROFESSOR POR VALOR</Tab>
                                <Tab color="primary.500" _hover={{ color: "secondary.500" }}>REPOSIÇÕES</Tab>
                            </TabList>

                            <TabPanels>                        
                                {/* <TabPanel>
                                    <AulasCanceladas aluno={aluno} professor={professor}/>
                                </TabPanel> */}
                                <TabPanel>
                                    <AulasCanceladasPorValorMonetario aluno={aluno} professor={professor}/>
                                </TabPanel>
                                <TabPanel>
                                    <HistoricoPorAluno aluno={aluno} professor={professor}/>
                                </TabPanel>
                                {/* <TabPanel>
                                    <AulasCanceladasPorProfessor aluno={aluno} professor={professor}/>
                                </TabPanel> */}
                                <TabPanel>
                                    <AulasCanceladasPorProfessorPorValorMonetario aluno={aluno} professor={professor}/>
                                </TabPanel>
                                <TabPanel>
                                    <ReposicoesConfig />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                }
            </PatternCard>
        </>
    );
};