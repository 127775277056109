import React from 'react';
import { Box, Heading, Flex, Icon } from '@chakra-ui/react';
import { FaChartLine } from 'react-icons/fa';
import InfoCard from './InfoCard';

interface InfoFaturamento
{
  fechado: string;
  salarioProf: string;
  faturamento: string;
}

const Faturamento: React.FC<InfoFaturamento> = ({fechado, salarioProf, faturamento} : InfoFaturamento) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} width={{ base: '90%', lg: '30%' }}>
      <Flex align="center" mb={4}>
        <Icon as={FaChartLine} w={6} h={6} mr={2} />
        <Heading size="md">Faturamento</Heading>
      </Flex>
      <InfoCard titulo="Valor Fechado" valor={fechado} corValor="orange.500" />
      <InfoCard titulo="Proj. Professores" valor={salarioProf} corValor="blue.500" />
      <InfoCard titulo="Faturamento" valor={faturamento} corValor="green.500" />
    </Box>
  );
};

export default Faturamento;
