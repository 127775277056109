import { useEffect, useState } from "react";
import { api } from "../../services/api";
import DataGrid from "../../components/DataGrid";
import TeacherModal from "../../components/DataGrid/AddModals/TeacherModal";
import { Box } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { formatDate } from "../../utils/formatter";

export function TeacherConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<object[]>([]);
    const [tick, setTick] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        setIsError(() => false);
        setIsLoading(() => true);

        api.get('api/Teacher')
            .then((response) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        ...row,
                        dataAniversario: formatDate(row.dataAniversario, false),
                        dt_Register: formatDate(row.dt_Register, false),
                    };
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
    }, [tick]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            {rowData.length === 0 && (
                <Box mb={4}>
                    <TeacherModal modalType="new" updateTick={updateTick} />
                </Box>
            )}

            <DataGrid
                rowData={rowData}
                isLoading={isLoading}
                isError={isError}
                addModal={<TeacherModal modalType="new" updateTick={updateTick} />}
                icons={decodedToken?.role === 'Administrador' ? ['Editar', 'Deletar'] : decodedToken?.role === 'Coordenador' ? ['Editar'] : []}
                deletePath="Teacher"
                updateTick={updateTick}
            />
        </>
    );
};