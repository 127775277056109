import { Button, Flex, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { FaClock } from "react-icons/fa";
import { toast } from "react-toastify";
import PatternButton from "../../components/Patterns/Button";
import PatternInput from "../../components/Patterns/Input";
import { api } from "../../services/api";

interface AlterCalendarModalProps {
    choosenTeacher: { value: number; label: string };
    setTick: React.Dispatch<React.SetStateAction<number>>;
};

export default function AlterCalendarModal({ choosenTeacher, setTick }: AlterCalendarModalProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [initialHour, setInitialHour] = useState<string>('--:--');
    const [finalHour, setFinalHour] = useState<string>('--:--');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClose = () => {
        setInitialHour('--:--');
        setFinalHour('--:--');
        onClose();
    };

    const handleSubmit = async () => {
        if (initialHour === '--:--' || finalHour === '--:--') {
            toast.warn('Favor preencher os horários corretamente!');
            return;
        };

        setIsLoading(() => true);

        try {
            const data = {
                professor_Id: choosenTeacher.value,
                horarioInicialAgenda: moment().format(`YYYY-MM-DD[T${initialHour.split(':')[0]}:${initialHour.split(':')[1]}:00.000Z]`),
                horarioFimAgenda: moment().format(`YYYY-MM-DD[T${finalHour.split(':')[0]}:${finalHour.split(':')[1]}:00.000Z]`),
            };

            await api.post('api/Agenda/AtualizarAgenda', data);

            toast.success(`Horários acrescentados com sucesso!`);
            setTick(prev => prev + 1);
        } catch(ex:any) {
            if(ex.response.data.message)
                toast.error("Você não pode escolher esse horário inicial, pois esse horário já existe na agenda")
            else
                toast.error(`Houve um erro ao acrescentar os horários informados. Contate um Administrador!`);
        } finally {
            setIsLoading(() => false);
        };
    };

    return (
        <>
            <PatternButton icon={FaClock} pl={10} pr={10} onClick={onOpen}>Acrescentar Horários</PatternButton>

            <Modal isOpen={isOpen} onClose={handleClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Acrescentar Horários no Calendário de {choosenTeacher.label}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDir="row" gap={4}>
                            <FormControl>
                                <FormLabel htmlFor="initial-hour" fontWeight="bolder">Hora Inicial</FormLabel>
                                <PatternInput
                                    id="initial-hour"
                                    name="initial-hour"
                                    type="time"
                                    value={initialHour}
                                    onChange={(event) => setInitialHour(event.currentTarget.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="final-hour" fontWeight="bolder">Hora Final</FormLabel>
                                <PatternInput
                                    id="final-hour"
                                    name="final-hour"
                                    type="time"
                                    value={finalHour}
                                    onChange={(event) => setFinalHour(event.currentTarget.value)}
                                />
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={handleClose} isDisabled={isLoading}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={handleSubmit}>Acrescentar</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};