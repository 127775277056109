import React from 'react';
import { Box, Heading, Flex, Icon } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import InfoCard from './InfoCard';

interface InfoCard {
    pausados: number;
    inadimplentes: number;
    desistentes: number;
}

const Informacoes: React.FC<InfoCard> = ({pausados, inadimplentes, desistentes} : InfoCard) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} width={{ base: '90%', lg: '30%' }}>
      <Flex align="center" mb={4}>
        <Icon as={FaInfoCircle} w={6} h={6} mr={2} />
        <Heading size="md">Informações</Heading>
      </Flex>
      <InfoCard titulo="Pausados" valor={pausados} corValor="orange.500" />
      <InfoCard titulo="Inadimplentes" valor={inadimplentes} corValor="red.500" />
      <InfoCard titulo="Desistências" valor={desistentes} corValor="green.500" />
    </Box>
  );
};

export default Informacoes;
