import { useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Icon,
    useDisclosure,
    List,
    Text,
    ListItem,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import PatternButton from "../../Patterns/Button";
import { toast } from "react-toastify";
import { api } from "../../../services/api";

interface response {
    response: data;
}

interface AgendaProfessor {
    id: number;
    horaInicialAula: string;
    horaFinalAula: string;
    diaSemana: number;
    professor_Id: number;
    aluno_Id: number;
}


interface data {
    data: message;
}

interface message {
    message: string;
}

export function DeleteModal(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const id = props?.data?.id ? props.data.id : props?.id;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDeleteInfo = async () => {
        try {
            setIsLoading(() => true);

            if(props?.deleteAlunoTurma){
                await api.delete(`api/${props?.deleteAlunoTurma}/${props?.data?.idAluno}`);
            }
            else if(props?.removerAlunoAgendaProf) {
                api.get(`api/Agenda/ProcurarAgendaPorProfessor?professor_Id=${props?.data?.id_Professor}`).then((response) => {
                    const agendaProfessor: AgendaProfessor[] = response.data.object;
                    const agendaFiltradaPorAluno = agendaProfessor.filter((agenda: AgendaProfessor) => agenda.aluno_Id === props?.data?.id_Aluno);
    
                    const diasParaApagarObj: { [key: number]: boolean } = {};
                    agendaFiltradaPorAluno.forEach((agenda: AgendaProfessor) => {
                        diasParaApagarObj[agenda.diaSemana] = true;
                    });
                    const diasParaApagar = Object.keys(diasParaApagarObj).map(Number);
        
                    // Obter horários do primeiro agendamento para a chamada de exclusão
                    const agendaExemplo = agendaFiltradaPorAluno[0];
                    const horaInicialExemplo = agendaExemplo.horaInicialAula;
                    const horaFinalExemplo = agendaExemplo.horaFinalAula;
        
                    // Apaga os agendamentos existentes
    
                    try {
                        api.post('/api/agenda/ApagarAgendamento', {
                            professor_Id: props?.data?.id_Professor,
                            aluno_Id: props?.data?.id_Aluno,
                            dia: diasParaApagar,
                            horaInicial: horaInicialExemplo,
                            horaFim: horaFinalExemplo,
                            apagarAgendamentoMassa: true
                        });
                    }
                    catch (err) {
                        toast.error("Erro ao remover aluno da agenda do prof");
                    }

                    toast.success("Aluno removido da agenda do prof com sucesso");
                })
                .catch(() => {})
                .finally(() => {
                    api.delete(`api/${props?.deletePath}?Id=${props?.data?.id}`);
                    props?.updateTick();
                });
            }
            else if(props?.data?.id)
                await api.delete(`api/${props?.deletePath}?Id=${props?.data?.id}`);
            else
                await api.delete(`api/${props?.deletePath}?Id=${props?.id}`);

            toast.success("Deletado com sucesso!");
            props?.updateTick();
        } catch(err:any)
        {
            if(err.response.data.message == "Já existe uma aula cadastrada para esse agendamento"){
                toast.error("Não é possivel apagar um agendamento com aula declarada");
            }
            else{
                toast.error("Um erro ocorreu ao deletar esta informação!");
            }
            
        } finally {
            setIsLoading(() => false);
            onClose();
        };
    };

    return (
        <>
            { props?.botaoExclusivo ?
                <PatternButton bg="red.600 !important" w="160px" mt={4} onClick={onOpen}>{props.nomeDelete}</PatternButton>
                :
                <Icon as={FaTrash} color="warning.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            }
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    {
                        props?.botaoExclusivo ?
                        <ModalHeader>Deletar Agenda do Professor: {props?.teacherName}</ModalHeader>
                        :
                        <ModalHeader>Deletar Registro: {id}</ModalHeader>
                    }
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        {
                            props?.botaoExclusivo ?
                            <Text mb={4} color="gray">Clicando em "Deletar" você excluirá a agenda do professor permanentemente, você tem certeza que quer fazer isso?</Text>
                            :
                            <Text mb={4} color="gray">Clicando em "Deletar" você concorda em deletar o registro contendo as seguintes informações:</Text>
                        }
                        <List>
                            {props.data ? Object.keys(props.data).map(key => {
                                return (
                                    <ListItem key={props.data[key]}><b>{key.substring(0, 1).toUpperCase()}{key.substring(1)}</b>: {props.data[key]}</ListItem>
                                );
                            }) 
                            : 
                            "Você tem certeza que quer deletar esse registro?"}
                        </List>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton bg="warning.500 !important" isLoading={isLoading} onClick={handleDeleteInfo}>Deletar</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};