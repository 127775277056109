import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text, 
    Flex
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button";
import PatternCheckbox from "../../Patterns/Checkbox";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import moment from "moment";
import { useJwt } from "react-jwt";
import { useCookies } from "react-cookie";

interface GetUsuallyUser {
    nome: string;
    id: Number;
}

interface AulaParaRepor {
    dataAulaFaltanta: string;
    id: Number;
    nomeAluno: string;
    idAluno: Number;
}

interface GetEstudante {
    name: string;
    id: Number;
}

export default function FormularioReposicaoModel(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [teacher, setTeacher] = useState<GetUsuallyUser[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [student, setStudent] = useState<Student[]>([]);
    const [student2, setStudent2] = useState<GetEstudante[]>([]);
    const [studentId, setStudentId] = useState<Number>(0);
    const [idAulaQueIraRepor, setIdAulaQueIraRepor] = useState<Number>(0);
    const [idsAulaAlunoEscolhido, setIdsAulaAlunoEscolhido] = useState<string>("");
    const [dayClass, setDayClass] = useState<string>(moment().add(-15, "days").format('YYYY-MM-DD'));
    const [image1, setImage1] = useState<File | undefined>();
    const [image2, setImage2] = useState<File | undefined>();
    const [image3, setImage3] = useState<File | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        setTeacherId(props?.data?.teacher_Id);
        setStudentId(props?.data?.student_Id);
        setIdAulaQueIraRepor(props?.data?.id_Reposicao);

        if (decodedToken?.role !== "Professor" && decodedToken) {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeacher(response.data.object);
            })
            .catch(() => setIsError(() => true));

            api.get("api/StudentSelectBox").then((response) => {
                setStudent(response.data.object);
            })
            .catch(() => setIsError(() => true));

        } else if (decodedToken) {
            api.get(`api/StudentPerTeacherGet?TeacherId=${decodedToken?.TeacherId}`).then((response) => {
                setStudent2(response.data.object);
            })
            .catch(() => setIsError(() => true));
          
            setTeacherId(Number(decodedToken?.TeacherId));
        }
    }, [props, decodedToken]);

    const fileToBase64 = (file: File) => {
        return new Promise<string | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result;
                if (typeof result === 'string') {
                    resolve(result);
                } else {
                    resolve(null);
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(file); 
        });
    };

    const handleAddBond = async () => {

        if (studentId === 0) {
            toast.warning('Campo Aluno está vazio');
            return;
        }
        if (teacherId === 0) {
            toast.warning('Campo Professor está vazio');
            return;
        }

        if (!image1 || (image1 && !['image/png', 'image/jpeg'].includes(image1.type))) {
            toast.warning('Você deve anexar uma imagem válida (PNG ou JPEG) (Imagem 1)');
            return;
        } else if (image1.size > 5 * 1024 * 1024) { 
            toast.warning('A imagem 1 deve ter no máximo 5 MB');
            return;
        }

        if (!image2 || (image2 && !['image/png', 'image/jpeg'].includes(image2.type))) {
            toast.warning('Você deve anexar uma imagem válida (PNG ou JPEG) (Imagem 2)');
            return;
        } else if (image2.size > 5 * 1024 * 1024) { 
            toast.warning('A imagem 2 deve ter no máximo 5 MB');
            return;
        }

        if (!image3 || (image3 && !['image/png', 'image/jpeg'].includes(image3.type))) {
            toast.warning('Você deve anexar uma imagem válida (PNG ou JPEG) (Imagem 3)');
            return;
        } else if (image3.size > 5 * 1024 * 1024) { 
            toast.warning('A imagem 3 deve ter no máximo 5 MB');
            return;
        }

        if (dayClass === '') {
            toast.warning('Campo Data está vazio');
            return;
        }

        try {
            const image1Base64 = image1 ? await fileToBase64(image1) : null;
            const image2Base64 = image2 ? await fileToBase64(image2) : null;
            const image3Base64 = image3 ? await fileToBase64(image3) : null;
    
            const data = {
                AlunoId: studentId,
                ProfessorId: teacherId,
                Imagem1: image1Base64 ? image1Base64.split(',')[1] : null,
                Imagem2: image2Base64 ? image2Base64.split(',')[1] : null,
                Imagem3: image3Base64 ? image3Base64.split(',')[1] : null,
                DataAula: dayClass,
            };
 
            api.post("/api/FormularioReposicao/CadastrarFormularioReposicao", data)
            .then((response) => {
                toast.success("Formulário enviado com sucesso");
            })
            .catch((err) => {
                if(err.response.data.message == "NAO_FOI_PREENCHIDO_TODOS_OS_REQUISITOS")
                toast.error("Ops, parece que nem tudo foi preenchido");
            })
            .finally(() => {
                props?.updateTick();
            });
    
        } catch (error) {
            toast.error("Erro ao processar as imagens.");
        }
    };

    const handleEditBond = async () => {
        const data = {
            Id: props?.data?.id,
            DayClass: dayClass,
            Teacher_Id: teacherId,
            Student_Id: studentId,
            Id_Reposicao: idAulaQueIraRepor,
        };
        api.put("/api/schedulingRepositionClassRoom/UpdateSchedulingClassRoom", data)
            .then((response) => {
                toast.success("Aula Agendada com Sucesso");
            })
            .catch(() => {
                toast.error("Ops, parece que houve um problema. Tente novamente mais tarde!");
            })
            .finally(() => {
                props?.updateTick();
            });
    };

    const handleEdit = (idStudentidAulaParaRepor: string) => {
        setIdsAulaAlunoEscolhido(idStudentidAulaParaRepor);
        const ids = idStudentidAulaParaRepor.split('-');

        setIdAulaQueIraRepor(Number(ids[0]));
        setStudentId(Number(ids[1]));
    };

    return (
        <>
            {props.modalType === 'edit' ? (
                <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            ) : decodedToken?.role === 'Professor' ? (
                <PatternButton icon={IoMdAdd} onClick={onOpen}>
                    Preencher Formulário
                </PatternButton>
                
            ): null}


            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {props.modalType === 'new' ? 'Formulário Reposição de Aula' : 'Alterar Formulário Reposição de Aula'}
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <FormControl>
                            <p>Data da Aula que não foi aplicado reposição</p>
                            <PatternInput
                                type="date"
                                id="dayClass"
                                name="dayClass"
                                max={moment().add(-15, "days").format('YYYY-MM-DD')}
                                min={moment().add(-45, "days").format('YYYY-MM-DD')}
                                value={dayClass}
                                onChange={(event) => setDayClass(event.currentTarget.value)}
                            />
                        </FormControl>
                        {
                            decodedToken?.role != "Professor" ? 
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                                <PatternSelect
                                    placeholder="Professor"
                                    value={Number(teacherId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setTeacherId(Number(event.currentTarget.value))}
                                >
                                    {teacher.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.nome}
                                        </option>
                                    ))}
                                </PatternSelect>
                            </FormControl>
                            :
                            null
                        }


                        {
                            decodedToken?.role === "Professor" ? 
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                                <PatternSelect
                                    placeholder="Aluno"
                                    value={Number(studentId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStudentId(Number(event.currentTarget.value))}
                                >
                                    {
                                        student2.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.name}
                                        </option>
                                    ))
                                    }
                                </PatternSelect>
                            </FormControl>
                            :
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                                <PatternSelect
                                    placeholder="Aluno"
                                    value={Number(studentId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStudentId(Number(event.currentTarget.value))}
                                >
                                    {
                                        student.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.nome}
                                        </option>
                                    ))
                                    }
                                </PatternSelect>
                            </FormControl>
                        }
                        <FormControl>
                            <Flex direction="column" gap={4}>
                                <Flex align="center">
                                    <Text flex="1">Anexe a Imagem 1*</Text>
                                    <Button
                                        variant="solid"
                                        colorScheme="blue"
                                        onClick={() => document.getElementById('file-input-1')?.click()}
                                    >
                                        Escolher Imagem
                                    </Button>
                                    <input
                                        id="file-input-1"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0]) {
                                                setImage1(event.target.files[0]);
                                            }
                                        }}
                                    />
                                    <Text flex="1" textAlign="right" fontSize="sm" color="gray.500">
                                        {image1 ? image1.name : 'Nenhum ficheiro selecionado'}
                                    </Text>
                                </Flex>

                                <Flex align="center">
                                    <Text flex="1">Anexe a Imagem 2*</Text>
                                    <Button
                                        variant="solid"
                                        colorScheme="blue"
                                        onClick={() => document.getElementById('file-input-2')?.click()}
                                    >
                                        Escolher Imagem
                                    </Button>
                                    <input
                                        id="file-input-2"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0]) {
                                                setImage2(event.target.files[0]);
                                            }
                                        }}
                                    />
                                    <Text flex="1" textAlign="right" fontSize="sm" color="gray.500">
                                        {image2 ? image2.name : 'Nenhum ficheiro selecionado'}
                                    </Text>
                                </Flex>

                                <Flex align="center">
                                    <Text flex="1">Anexe a Imagem 3*</Text>
                                    <Button
                                        variant="solid"
                                        colorScheme="blue"
                                        onClick={() => document.getElementById('file-input-3')?.click()}
                                    >
                                        Escolher Imagem
                                    </Button>
                                    <input
                                        id="file-input-3"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            if (event.target.files && event.target.files[0]) {
                                                setImage3(event.target.files[0]);
                                            }
                                        }}
                                    />
                                    <Text flex="1" textAlign="right" fontSize="sm" color="gray.500">
                                        {image3 ? image3.name : 'Nenhum ficheiro selecionado'}
                                    </Text>
                                </Flex>
                            </Flex>
                        </FormControl>

                        <p>Obs: Esse formulário só deve ser preenchido caso já faça 15 dias da aula que não foi possível aplicar a reposição.</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddBond : handleEditBond}>
                            {props?.modalType === 'new' ? 'Salvar' : 'Editar'}
                        </PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
