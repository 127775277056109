import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";
import FormularioReposicaoModel from "../../components/DataGrid/AddModals/FormularioReposicaoModel";
import FormularioVisualizacaoModel from "../../components/DataGrid/AddModals/FormularioVisualizacaoModel";
import PatternInput from "../../components/Patterns/Input";
import moment from "moment";

interface FormularioResponse {
    id: Number;
    Aluno: string;
    Professor: string;
    Aprovado: boolean;
    DiaAula: string;
};

interface GetTeacher {
    nome: string;
    id: Number;
}

export default function FormularioReposicao() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    let dataAtual = new Date();
    let mesAtual = dataAtual.getMonth() + 1;
    let anoAtual = dataAtual.getFullYear();

    const [rowData, setRowData] = useState<FormularioResponse[]>([]);
    const [rowDataCompleto, setRowDataCompleto] = useState<any[]>([]); 
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [teacher, setTeacher] = useState<GetTeacher[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [month, setMonth] = useState<number>(mesAtual);
    const [year, setYear] = useState<number>(anoAtual);

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        if(decodedToken && decodedToken?.role !== "Professor" ) {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeacher(response.data.object);
            })
            .catch(() => setIsError(true));
        }

        const data = `${year}-${month}-01`;
        
        CarregarFormularios(data);

    }, [tick, decodedToken, teacherId, month, year]); 

    const CarregarFormularios = (dataEscolhida:string) => {
        if(decodedToken && decodedToken?.role !== "Professor" ) {
            api.get(`/api/FormularioReposicao/ObterFormulariosPorData?dataEscolhida=${dataEscolhida}`)
            .then((response: any) => {
                setRowDataCompleto(response.data.object.map((row: any) => {
                    return {
                        ...row,
                    };
                })); 
                setRowData(response.data.object.map((row: any) => {
                    return {
                        Aluno: row.aluno,
                        Professor: row.professor,
                        Aprovado: row.aprovado,
                        DiaAula: formatDate(row.diaAula),
                        id: row.id,
                    };
                }));
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
        } else if (decodedToken) {
            api.get(`/api/FormularioReposicao/ObterFormulariosPorProfessor/${decodedToken?.TeacherId}`)
            .then((response: any) => {
                setRowDataCompleto(response.data.object.map((row: any) => {
                    return {
                        ...row,
                    };
                })); 
                setRowData(response.data.object.map((row: any) => {
                    return {
                        Aluno: row.aluno,
                        Professor: row.professor,
                        Aprovado: row.aprovado,
                        DiaAula: formatDate(row.diaAula),
                        id: row.id,
                    };
                }));
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
        }
    }

    const updateTick = () => {
        setTick(tick + 1);
    };

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }

    return (
        <>          
            <FormControl>
                {
                    decodedToken?.role !== "Professor" ?
                    <>
                        <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
                            <PatternSelect 
                                placeholder='MÊS' 
                                value={month} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
                            >
                                <option key={1} value={1}>JANEIRO</option>
                                <option key={2} value={2}>FEVEREIRO</option>
                                <option key={3} value={3}>MARÇO</option>
                                <option key={4} value={4}>ABRIL</option>
                                <option key={5} value={5}>MAIO</option>
                                <option key={6} value={6}>JUNHO</option>
                                <option key={7} value={7}>JULHO</option>
                                <option key={8} value={8}>AGOSTO</option>
                                <option key={9} value={9}>SETEMBRO</option>
                                <option key={10} value={10}>OUTUBRO</option>
                                <option key={11} value={11}>NOVEMBRO</option>
                                <option key={12} value={12}>DEZEMBRO</option>
                            </PatternSelect>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
                            <PatternSelect 
                                placeholder='ANO' 
                                value={year} 
                                onChange={(event) => { updateYear(event.currentTarget.value)}}
                            >
                                <option key={1} value={2023}>2023</option>
                                <option key={2} value={2024}>2024</option>
                                <option key={3} value={2025}>2025</option>
                                <option key={4} value={2026}>2026</option>
                                <option key={5} value={2027}>2027</option>
                                <option key={6} value={2028}>2028</option>
                                <option key={7} value={2029}>2029</option>
                                <option key={8} value={2030}>2030</option>
                                <option key={9} value={2031}>2031</option>
                                <option key={10} value={2032}>2032</option>
                                <option key={11} value={2033}>2033</option>
                                <option key={12} value={2034}>2034</option>
                            </PatternSelect>
                    </>
                    :
                    null
                }
            </FormControl>

            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <FormularioReposicaoModel modalType="new" updateTick={updateTick} />
                </Box>
            )}
            {isLoading ? (
                <div>Carregando...</div>
            ) : isError ? (
                <div>Ocorreu um erro ao carregar os dados</div>
            ) : (
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    addModal={<FormularioReposicaoModel modalType="new" updateTick={updateTick} />}
                    visualizationModal={<FormularioVisualizacaoModel propsData={rowDataCompleto} updateTick={updateTick} />}
                    icons={['Deletar', 'VisualizarFormulario']}
                    deletePath={"FormularioReposicao/RemoverFormularioReposicao"}
                    updateTick={updateTick}
                    rowData2={rowDataCompleto}
                />
            )}
        </>
    );
}
