import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, CircularProgress } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { api } from '../../services/api';
import { formatDate } from '../../utils/formatter';
import DataGrid from '../../components/DataGrid'; // Supondo que já exista este componente
import AlunosComecamEmBreveModel from '../../components/DataGrid/AddModals/AlunosComecamEmBreveModel';
import PatternCard from '../../components/Patterns/Card';

interface AlunosQueComecamEmBreveProps {
  // Se houver props específicas para este componente, podemos definir aqui
}

const AlunosComPendencia: React.FC<AlunosQueComecamEmBreveProps> = () => {
  const [cookies] = useCookies(['loginToken']);
  const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
  const [tick, setTick] = useState<number>(0);

  const [rowData, setRowData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (decodedToken) {
      setIsLoading(true);
      setIsError(false);

      fetchAlunosQueComecamEmBreve();
    }
  }, [decodedToken, tick]);

  const updateTick = () => {
    setTick(tick + 1);
  };

  const fetchAlunosQueComecamEmBreve = () => {
    api.get('api/GetStudentsStartedWithPending')
      .then(async response => {
        
        const formattedData = await response.data.object.map((row: any) => ({
          Aluno: row.nomeAluno,
          Contato: row.contato,
          Vendedor: row.quemFechou,
          DataInicioAulas: formatDate(row.dataInicioAulas, false),
          Plano: row.planoAluno,
          PrimeiraMensalidade: row.primeiraMensalidade === 0 ? 'Pendente' : row.primeiraMensalidade === 1 ? 'OK' : 'N/A',
          Horario: row.horario === null ? 'Pendente' : row.horario,
          ProfReserva: row.profReserva === null ? 'Pendente' : row.profReserva,
          SistemaKing: row.sistemaKing === 0 ? 'Pendente' : 'OK',
          VinculoProfessor: row.vinculoProfessor === 0 ? 'Pendente' : 'OK',
          Agenda: row.agenda === 0 ? 'Pendente' : 'OK',
          GrupoPosVenda: row.grupoPosVenda === 0 ? 'Pendente' : 'OK',
          Pendencias: row.quantidadePendencia,
          AlunoId: row.alunoId,
          AlunosComecamEmBreveId: row.alunosComecamEmBreveId 
        }));
        
        setRowData(formattedData);
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    
    <VStack align="stretch" marginTop="-5%">
      <PatternCard title="Alunos Com Pendências">
        
      {isLoading && (
        <Box width="100%" textAlign="center">
          <CircularProgress isIndeterminate color="secondary.500" />
          <Text>Carregando dados...</Text>
        </Box>
      )}

      {isError && (
        <Text width="100%" textAlign="center" color="red.500">
          Ocorreu um erro ao carregar os dados.
        </Text>
      )}

      {!isLoading && !isError && rowData.length === 0 && (
        <Text width="100%" textAlign="center">
          Não há dados para exibir.
        </Text>
      )}

      {!isLoading && !isError && rowData.length > 0 && (
        <Box >
          <DataGrid
            rowData={rowData}
            isLoading={isLoading}
            isError={isError}
            addModal={<AlunosComecamEmBreveModel modalType="edit" updateTick={updateTick} />}
            enableExport={decodedToken?.role === 'Administrador'}
            icons={['Visualizar', 'Editar', 'Deletar']}
            updateTick={updateTick}
          />
        </Box>
      )}
      </PatternCard>
    </VStack>
  );
};   

export default AlunosComPendencia;
