import { Button, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function MetasModel (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [meta, setMeta] = useState<Number>(0);
    const [mesMeta, setMesMeta] = useState<Number>(0);
    
    useEffect(() => {
        if (props?.modalType === 'edit') {
            setMeta(props?.data?.valorMeta);
            setMeta(props?.data?.mesMeta);
        };
    }, [])

    const handleAddNewStudent = async () => {
        if (meta === 0) { 
            toast.warning("O campo Meta está vazio");
            return;
        };

        if (mesMeta === 0) { 
            toast.warning("O campo mês da meta está vazio");
            return;
        };
        

        try {
            setIsLoading(() => true);
            const data = {
                ValorMeta: meta,
                MesMeta: mesMeta 
            };

            await api.post('api/Metas', data);

            toast.success("Meta criada com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Meta para o mes selecionado ja existente")
                toast.error("Meta Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar essa Meta!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleEditNewStudent = async () => {
        if (meta === 0) { 
            toast.warning("O campo Meta está vazio");
            return;
        };

        if (mesMeta === 0) { 
            toast.warning("O campo mês da meta está vazio");
            return;
        };
    
        try {
            setIsLoading(() => true);
            const data = {
                ValorMeta: meta,
                MesMeta: mesMeta 
            };

            await api.post('api/Metas', data);

            toast.success("Meta alterada com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message === "Meta para o mes selecionado ja existente")
                toast.error("Meta Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar essa Meta!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const onDelete = async () => {
        await api.delete(`api/Metas?Id=${props?.id[0]}`);
        props?.updateTick();
    }

    return (
        <>
            {
                props.modalType === 'delete' ? 
                <PatternButton margin={2} icon={RiDeleteBin6Line} onClick={onDelete}>Excluir</PatternButton>
                : 
                props.modalType === 'new' ?
                <PatternButton margin={2} icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
                :
                <PatternButton margin={2} icon={FaPencilAlt} onClick={onOpen}>Editar</PatternButton> 
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar Meta' : 'Alterar Meta'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Meta</p>
                        <PatternInput
                            type="number"
                            id="meta"
                            name="meta"
                            placeholder="Meta"
                            value={Number(meta)}
                            onChange={(event) => setMeta(Number(event.currentTarget.value))}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Meta</FormLabel>
                            <PatternSelect
                                placeholder='Mês da Meta' 
                                value={Number(mesMeta)} 
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMesMeta(Number(event.currentTarget.value))}
                            >
                                <option key={1} value={1}>JANEIRO</option>
                                <option key={2} value={2}>FEVEREIRO</option>
                                <option key={3} value={3}>MARÇO</option>
                                <option key={4} value={4}>ABRIL</option>
                                <option key={5} value={5}>MAIO</option>
                                <option key={6} value={6}>JUNHO</option>
                                <option key={7} value={7}>JULHO</option>
                                <option key={8} value={8}>AGOSTO</option>
                                <option key={9} value={9}>SETEMBRO</option>
                                <option key={10} value={10}>OUTUBRO</option>
                                <option key={11} value={11}>NOVEMBRO</option>
                                <option key={12} value={12}>DEZEMBRO</option>
                            </PatternSelect>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddNewStudent : handleEditNewStudent}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}