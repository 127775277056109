import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormLabel} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";
import NovosAlunosModel from "../../components/DataGrid/AddModals/NovosAlunosModel";
import moment from "moment";

interface GetAllUserResponse {
    id: Number;
    quemFechou: string;
    dataFechamento: Date;
    dataInicioAulas: Date;
    valorFechado: Number;
    deOndeVeio: string;
    valorMatricula: Number;
    quantosDiasDemorouFechar: Number;
    semana: Number;
};

export default function NovosAlunosConfig() {
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [month, setMonth] = useState<number>(mesAtual);
    const [year, setYear] = useState<number>(anoAtual);

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);
        // setRowData(() => []);

        updateData();

    }, [tick, decodedToken, month, year]);

    const updateData = async () => {
        setIsLoading(() => true);
        if(decodedToken && month !== 0 && year !== 0){
            try{
                const dataClassLaunch = await api.get(`api/NovosAlunos/${month}/${year}`)
                if(dataClassLaunch.data.object.length > 0) {
                    setRowData(dataClassLaunch.data.object.map((row: any) => {
                        return {
                            ...row,
                            dataInicioAulas: formatDate(row.dataInicioAulas, false),
                            dataFechamento: formatDate(row.dataFechamento, false)
                        };
                    }));
                }
                else{
                    setRowData([]);
                }
            }
            catch(err){
                setIsError(() => true)
            }
            finally{
                setIsLoading(() => false)
            }
        }
    }
    
    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Mês da Apuração</FormLabel>
            <PatternSelect 
                placeholder='MÊS' 
                value={month} 
                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setMonth(Number(event.currentTarget.value))}
            >
                <option key={1} value={1}>JANEIRO</option>
                <option key={2} value={2}>FEVEREIRO</option>
                <option key={3} value={3}>MARÇO</option>
                <option key={4} value={4}>ABRIL</option>
                <option key={5} value={5}>MAIO</option>
                <option key={6} value={6}>JUNHO</option>
                <option key={7} value={7}>JULHO</option>
                <option key={8} value={8}>AGOSTO</option>
                <option key={9} value={9}>SETEMBRO</option>
                <option key={10} value={10}>OUTUBRO</option>
                <option key={11} value={11}>NOVEMBRO</option>
                <option key={12} value={12}>DEZEMBRO</option>
            </PatternSelect>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
            <PatternSelect 
                placeholder='ANO' 
                value={year} 
                onChange={(event) => { setYear(Number(event.currentTarget.value))}}
            >
                <option key={1} value={2023}>2023</option>
                <option key={2} value={2024}>2024</option>
                <option key={3} value={2025}>2025</option>
                <option key={4} value={2026}>2026</option>
                <option key={5} value={2027}>2027</option>
                <option key={6} value={2028}>2028</option>
                <option key={7} value={2029}>2029</option>
                <option key={8} value={2030}>2030</option>
                <option key={9} value={2031}>2031</option>
                <option key={10} value={2032}>2032</option>
                <option key={11} value={2033}>2033</option>
                <option key={12} value={2034}>2034</option>
            </PatternSelect>
            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <NovosAlunosModel modalType="new" updateTick={updateTick} />
                </Box>
            )}
            {
                month !== 0 ?
                <DataGrid
                    enableExport={true}
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    updateTick={updateTick}
                />
                :
                null
            }
        </>
        
    );
};