import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Flex,
    Heading,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    FormLabel,
    FormControl,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PatternButton from '../../../components/Patterns/Button'
import PatternInput from '../../../components/Patterns/Input';
import { api } from '../../../services/api';

interface EditPaymentDataModalProps {
    paymentData: Teachers | undefined;
    setPaymentData: React.Dispatch<React.SetStateAction<Teachers | undefined>>;
};

export function EditPaymentDataModal({ paymentData, setPaymentData }: EditPaymentDataModalProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [titularityName, setTitularityName] = useState<string>('');
    const [bankName, setBankName] = useState<string>('');
    const [agencyCode, setAgencyCode] = useState<number>(0);
    const [account, setAccount] = useState<string>('');
    const [pix, setPix] = useState<string>('');
    const [timer, setTimer] = useState<number>(10);

    useEffect(() => {
        setTimer(10);

        if (isOpen && paymentData) {
            setTitularityName(paymentData?.NomeTitularidade ?? '');
            setBankName(paymentData?.nomeBanco);
            setAgencyCode(paymentData?.codigoAgencia);
            setAccount(paymentData?.contaCorrente);
            setPix(paymentData?.pix);
        };
    }, [isOpen]);

    const handleChangePaymentData = async () => {
        if (!paymentData) return;

        if (titularityName?.length <= 0 || bankName?.length <= 0 || account?.length <= 0 || pix?.length <= 0) {
            toast.warn('Preencha os dados corretamente!');
            return;
        };

        const data = {
            Id: paymentData.id,
            Name: paymentData.nome,
            Age: paymentData.idade,
            CNPJ: paymentData.cnpj,
            TitularityName: titularityName,
            NameBank: bankName,
            CodeAgency: agencyCode,
            CountChain: account,
            CPF: paymentData.cpf,
            Email: paymentData.email,
            Pix: pix,
            Value_HourPerClass: paymentData.valorPorHoraAula,
            Telefone: paymentData.telefone,
            TipoSalarioProfessor: paymentData.tipoSalarioProfessor,
            Dt_Birth: paymentData.dataAniversario,
        };

        await api.put('api/Teacher', data);

        if (paymentData) {
            const newPaymentData = { ...paymentData };

            newPaymentData.NomeTitularidade = titularityName;
            newPaymentData.nomeBanco = bankName;
            newPaymentData.codigoAgencia = agencyCode;
            newPaymentData.contaCorrente = account;
            newPaymentData.pix = pix;

            setPaymentData(newPaymentData);
        };

        onClose();
        toast.success('Dados bancários alterados com sucesso!');
    };

    const handleStartTimer = () => {
        if (timer === 10) {
            const intrvl = setInterval(() => {
                setTimer(prev => {
                    if (prev - 1 <= 0)
                        clearInterval(intrvl);

                    return prev - 1;
                });

            }, 1000);
        }
    };

    return (
        <>
            <PatternButton size="sm" onClick={onOpen}>Dados incorretos? Alterar</PatternButton>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Alteração de Dados Bancários</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDir="column" mb={4}>
                            <Flex gap={2} flexDir="column">
                                <FormControl>
                                    <FormLabel htmlFor="titularity-name" fontWeight="bolder">Titular da Conta</FormLabel>
                                    <PatternInput
                                        id="titularity-name"
                                        name="titularity-name"
                                        type="text"
                                        value={titularityName}
                                        onChange={(event) => setTitularityName(event.currentTarget.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="bank-name" fontWeight="bolder">Nome do Banco</FormLabel>
                                    <PatternInput
                                        id="bank-name"
                                        name="bank-name"
                                        type="text"
                                        value={bankName}
                                        onChange={(event) => setBankName(event.currentTarget.value)}
                                    />
                                </FormControl>
                                <Flex gap={4}>
                                    <FormControl>
                                        <FormLabel htmlFor="agency-code" fontWeight="bolder">Agência</FormLabel>
                                        <PatternInput
                                            id="agency-code"
                                            name="agency-code"
                                            type="text"
                                            value={agencyCode}
                                            onChange={(event) => setAgencyCode(Number(event.currentTarget.value.replace(/\D/, '')))}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="account" fontWeight="bolder">Conta Corrente</FormLabel>
                                        <PatternInput
                                            id="account"
                                            name="account"
                                            type="text"
                                            value={account}
                                            onChange={(event) => setAccount(event.currentTarget.value)}
                                        />
                                    </FormControl>
                                </Flex>
                                <FormControl>
                                    <FormLabel htmlFor="pix" fontWeight="bolder">PIX</FormLabel>
                                    <PatternInput
                                        id="pix"
                                        name="pix"
                                        type="text"
                                        value={pix}
                                        onChange={(event) => setPix(event.currentTarget.value)}
                                    />
                                </FormControl>
                            </Flex>
                        </Flex>
                        <Alert flexDir="column" alignItems="flex-start" status="warning">
                            <Flex align="center" mb={2}>
                                <AlertIcon />
                                <AlertTitle>Atenção</AlertTitle>
                            </Flex>
                            <AlertDescription>
                                Caso esses dados estejam incorretos, me responsabilizo e entendo ser minha responsabilidade corrigir o erro feito após minha autorização de dados bancários.
                            </AlertDescription>
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <PatternButton mr={3} onClick={onClose}>
                            Cancelar
                        </PatternButton>
                        <Popover>
                            <PopoverTrigger>
                                <span>
                                    <PatternButton mr={3} onClick={handleStartTimer}>
                                        Quero editar meus dados para pagamento
                                    </PatternButton>
                                </span>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader fontWeight="bolder">Tem certeza?</PopoverHeader>
                                <PopoverBody>
                                    <Text mb={4}>Por motivos de segurança, por favor confirme a alteração no botão abaixo:</Text>
                                    <PatternButton w="100%" onClick={handleChangePaymentData} isDisabled={timer > 0}>{timer > 0 ? `Aguarde ${timer}s` : 'Confirmar'}</PatternButton>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}