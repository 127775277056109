import {  Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useJwt } from "react-jwt";
import PatternCard from "../../components/Patterns/Card";
import ClassLaunchConfig from "./ClassLaunch";
import PendenciasProfessores from "./PendenciasAulas";
import PendenciasProfessoresGeral from "./PendenciasAulasGeral";
import ReportSalaryTeacher from "./ReportSalaryTeacher";
import ReportStudent from "./ReportStudent";
import ReportStudentPerTeacher from "./ReportStudentPerTeacher";
import { AulaProvider } from "../../contexts/VerificaAulasLancadasEm24hContext";

export function ClassLaunch() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    return (
        <>
            <Helmet>
                <title>King | Configuração Hora Aula</title>
            </Helmet>
            <PatternCard title="Configuração Hora Aula">
                <Tabs>
                    <TabList>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Lançamento de Aulas</Tab>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Pendências de Aulas</Tab>
                        { decodedToken?.role === 'Administrador' || decodedToken?.role === 'Coordenador' ? 
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Pendência Geral</Tab> 
                            : 
                            null 
                        }
                        { decodedToken?.role === 'Administrador'  || decodedToken?.role === 'Coordenador' ? 
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Relatório de Alunos</Tab> 
                            : 
                            null 
                        }
                        { decodedToken?.role === 'Administrador' || decodedToken?.role === 'Coordenador' ? 
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Relatório Unificado</Tab> 
                            : 
                            null 
                        }
                        { decodedToken?.role === 'Administrador' ? 
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Relatório de Valores a Pagar H/A Aplicadas por Professor</Tab> 
                            : 
                            null 
                        }
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <AulaProvider>
                                <ClassLaunchConfig />
                            </AulaProvider>
                        </TabPanel>
                        <TabPanel>
                            <PendenciasProfessores />
                        </TabPanel>
                        <TabPanel>
                            <PendenciasProfessoresGeral />
                        </TabPanel>
                        <TabPanel>
                            <ReportStudentPerTeacher />
                        </TabPanel>
                        <TabPanel>
                            <ReportStudent />
                        </TabPanel>
                        <TabPanel>
                            <ReportSalaryTeacher />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </PatternCard>
        </>
    );
};