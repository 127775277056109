import {
    Button,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button"
import MultiSelect from "../../Patterns/MultiSelect";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import PatternMultipleSellect from "../../Patterns/MultipleSelect";

interface Teacher {
    nome: String,
    id: Number
}


export default function ClassLaunchModalOld(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [teacher, setTeacher] = useState<Teacher[]>([]);
    const [studentId, setStudentId] = useState<Number>(0);
    const [students, setStudents] = useState<Student[]>([]);
    const [quantityClass, setQuantityClass] = useState<Number>(0);
    const [dayClass, setDayClass] = useState<string>(moment().format('YYYY-MM-DD'));
    const [observation, setObservation] = useState<string>("");
    const [typeClass, setTypeClass] = useState<string>("");
    const [resultValuesChecks, setResultValuesChecks] = useState<any>([]);
    const [tipoAulaPersonalizada, setTipoAulaPersonalizada] = useState<Number>(-1);
    const [nivelDoAluno, setNivelDoAluno] = useState<Number>(-1);
    const [materialUsadoNaAula, setMaterialUsadoNaAula] = useState<Number>(-1);
    const [paginaQueParouNoMaterial, setPaginaQueParouNoMaterial] = useState<Number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setStudentId(0);
        setStudents([]);
        setQuantityClass(0);
        setDayClass("");

        if (props?.modalType === 'edit') {
            setStudentId(props?.data.id_Aluno);
            setStudents([]);
            setQuantityClass(Number(props?.data.quantidade_Aula));
            setDayClass(moment(props?.data.dia_Aula,'DD/MM/YYYY').format('YYYY-MM-DD'));
        };
        if(decodedToken?.role === "Professor" && decodedToken){
            setTeacherId(decodedToken?.TeacherId);
            api.get(`api/StudentPerTeacherGet?TeacherId=${decodedToken?.TeacherId}`).then((response) => {
                setStudents(response.data.object);
            })
        }
        else{
            setTeacher(props?.professor);
            setStudents(props?.estudante);
        }
    }, [props, decodedToken]);

    const handleAddClass = async () => {
        if (Number(teacherId) === 0 || studentId === 0 || dayClass === '' || typeClass === '') {
            toast.warning("Preencha todas as informações!");
            return;
        };

        try {
            setIsLoading(() => true);

            const alunoData = {
                id: studentId,
                check: true,
                tipo: typeClass,
                texto: observation,
                materialUsadoEmAula: materialUsadoNaAula,
                nivelDoAluno: nivelDoAluno,
                tipoAulaPersonalizada: tipoAulaPersonalizada,
                paginaQueParouNoMaterial: paginaQueParouNoMaterial
            }

            // materialUsadoEmAula: student.materialUsadoNaAula,
            // nivelDoAluno: student.nivelDoAluno,
            // tipoAulaPersonalizada: student.tipoAulaPersonalizada,
            // paginaQueParouNoMaterial: student.paginaQueParouNoMaterial,

            resultValuesChecks.push(alunoData);

            const dataFullApi = {
                resultValuesChecks: resultValuesChecks,
                date: dayClass,
                teacherId: teacherId
            }

            await api.post('api/ClassLaunch', dataFullApi);

            toast.success("Aula Lançada com sucesso!");
            props?.updateTick();
        } catch(err : any) {
            if(err.response.data.message == "Limite Atingido")
                toast.error("Ops, parece que este aluno já está no seu limite de aulas declaradas.");
            else if(err.response.data.message == "Aula Ja Existente")
                toast.error("Ops, parece que este aluno já tem uma aula declarada neste dia.");
            else if(err.response.data.message == "Esse aluno não tem reposição de aula disponível")
                toast.error("Ops, Esse aluno não tem reposição de aula disponível");
            else
                toast.error("Um erro ocorreu ao lançar esta aula!");
        } finally {
            setIsLoading(() => false);
        };
        
        onCloseModal();
    };

    const onCloseModal = () => {
        limpaCampos();
        onClose();
    }


    const limpaCampos = () => {
        setTeacherId(0);
        setStudentId(0);
        setTypeClass("");
        setResultValuesChecks([]);
        setObservation("");
        setTipoAulaPersonalizada(0);
        setNivelDoAluno(0);
        setMaterialUsadoNaAula(0);
        setPaginaQueParouNoMaterial(0);
    }

    return (
        <>
            {props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> : <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>}

            <Modal isOpen={isOpen} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Lançar Aula' : 'Editar Aula Já Lançada'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Data da Aula</p>
                        <PatternInput
                            type="date"
                            id="dayClass"
                            name="dayClass"
                            placeholder="E-dayClass"
                            value={dayClass}
                            onChange={(event) => setDayClass(event.currentTarget.value)}
                        />
                        <p>Professor</p>
                        <PatternSelect placeholder='Professor' value={Number(teacherId)} onChange={(event) => setTeacherId(Number(event.currentTarget.value))}>
                            {teacher?.map(teacher => {
                                return (<option key={Number(teacher?.id)} value={Number(teacher?.id)}>{teacher?.nome}</option>)
                            })}
                        </PatternSelect>
                        
                        <p>Tipo de Aula</p>
                        <PatternSelect placeholder='Tipo de Aula' value={typeClass} onChange={(event) => setTypeClass(event.currentTarget.value)}>
                            <option key={1} value="Presenca">Presença</option>
                            <option key={2} value="Falta">Falta</option>
                            {/* <option key={3} value="FaltaReposicao">Falta com Reposição</option> */}
                        </PatternSelect>

                        <p>Aluno</p>
                        <PatternSelect placeholder='Aluno' value={Number(studentId)} onChange={(event) => setStudentId(Number(event.currentTarget.value))}>
                            {students.map((group:Student)  => {
                                return (<option key={Number(group.id)} value={Number(group.id)}>{group.nome}</option>)
                            })}
                        </PatternSelect>
                        
                        {/* <p>Alunos</p>
                        <PatternMultipleSellect 
                            onChange={(event:MultipleSelect) => {handleChangeStudentId(event)}}
                            options={students.map((studentd) => {
                               const data = {
                                    label: studentd.name,
                                    value: studentd.id
                               } 
                               return data;
                            })}
                        /> */}

                        <p>Observação</p>
                        <PatternInput
                            type="text"
                            id="observation"
                            name="observation"
                            placeholder="Observação"
                            value={observation}
                            onChange={(event) => setObservation(event.currentTarget.value)}
                        />
                        <p>Aula Personalizada</p>
                        <PatternSelect 
                            value={Number(tipoAulaPersonalizada)}
                            onChange={(e:any) => setTipoAulaPersonalizada(Number(e.target.value))}
                            placeholder=""
                        >
                            <option key={1} value={-1}></option>
                            <option key={2} value={1}>Aula Personalizada</option>
                            <option key={3} value={2}>Aula Personalizada + Aula do Livro</option>
                        </PatternSelect>
                        <p>Nivel do Aluno</p>
                        <PatternSelect 
                            value={Number(nivelDoAluno)}
                            onChange={(e:any) => setNivelDoAluno(Number(e.target.value))}
                            placeholder="">
                                <option key={0} value={-1}></option>
                                <option key={1} value={1}>A1</option>
                                <option key={2} value={2}>A2</option>
                                <option key={3} value={3}>B1</option>
                                <option key={4} value={4}>B2</option>
                                <option key={5} value={5}>C1</option>
                                <option key={6} value={6}>C2</option>
                        </PatternSelect>
                        <p>Material Usado na Aula</p>
                        <PatternSelect 
                            value={Number(materialUsadoNaAula)}
                            onChange={(e:any) => setMaterialUsadoNaAula(Number(e.target.value))}
                            placeholder="">
                                <option key={0} value={-1}></option>
                                <option key={1} value={1}>Conversação</option>
                                <option key={2} value={2}>Beyond Basics</option>
                        </PatternSelect>
                        <p>Pagina que parou</p>
                        <Input 
                            value={Number(paginaQueParouNoMaterial)} 
                            onChange={(e:any) => setPaginaQueParouNoMaterial(Number(e.target.value))} 
                            placeholder="Página que parou no material" />
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onCloseModal}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={handleAddClass}>{'Salvar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};