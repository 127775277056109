import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { setupApiService } from "../services/api";
import { Login } from "../pages/Login";
import { ForgotPassword } from "../pages/Login/ForgotPassword";
import { ClassLaunch } from "../pages/ClassLaunch/index";
import UsersGroups from "../pages/UsersGroups";
import { TeacherWithStudent } from "../pages/TeacherWithStudent";
import { AgenderReposition } from "../pages/AgenderReposition";
import { NovosAlunos } from "../pages/NovosAlunos";
import { Metricas } from "../pages/Metricas";
import { HistoricoAulas } from "../pages/HistoricoAulas";
import Calendar from "../pages/Calendar";
import { useJwt } from "react-jwt";
import { Alunos } from "../pages/Alunos";
import { Index } from "../pages/AlunosComecamEmBreve"
import { ResetPassword } from "../pages/Login/ResetPassword";


export default function Router() {
    const [cookies, , removeCookies] = useCookies(["loginToken"]);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    useEffect(() => {
        if (cookies.loginToken) {
            setLoggedIn(true);
            setupApiService.setupHeaders(cookies?.loginToken?.message);
            setupApiService.setupInterceptors(removeCookies);
            
        } else {
            setLoggedIn(false);
        };
    }, [cookies, removeCookies]);

    return (
        <Routes>
            <Route path="/" element={loggedIn ? decodedToken?.role === 'Professor' ? <Navigate to="/configurationHour" replace /> : <Navigate to="/home" replace /> : <Navigate to="/login" replace />} />
            <Route path="/login" element={loggedIn ? decodedToken?.role === 'Professor' ? <Navigate to="/configurationHour" replace /> : <Navigate to="/home" replace /> : <Login />} />
            <Route path="/esqueciminhasenha" element={loggedIn ? <Navigate to="/" replace /> : <ForgotPassword />} />
            <Route path="/resetsenha/:codigo" element={loggedIn ? <Navigate to="/" replace /> : <ResetPassword />} />
            <Route path="/home" element={loggedIn ? <Metricas /> : <Navigate to="/" replace />} />
            <Route path="/users-groups" element={loggedIn ? <UsersGroups /> : <Navigate to="/" replace />} />
            <Route path="/historicClass" element={loggedIn ? <HistoricoAulas /> : <Navigate to="/" replace />} />
            <Route path="/calendar" element={loggedIn ? <Calendar /> : <Navigate to="/" replace />} />
            <Route path="/configurationHour" element={loggedIn ? <ClassLaunch /> : <Navigate to="/" replace />} />
            <Route path="/configurationTeacherWithStudent" element={loggedIn ? <TeacherWithStudent /> : <Navigate to="/" replace />} />
            <Route path="/reposition" element={loggedIn ? <AgenderReposition /> : <Navigate to="/" replace />} />
            <Route path="/novosAlunos" element={loggedIn ? <NovosAlunos /> : <Navigate to="/" replace />} />
            <Route path="/alunos" element={loggedIn ? <Alunos /> : <Navigate to="/" replace />} />
            <Route path="/alunosComecamEmBreve" element={loggedIn ? <Index/> : <Navigate to="/" replace />} />
            {/* <Route path="/Metricas" element={loggedIn ? <Metricas /> : <Navigate to="/" replace />} /> */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};