import { Box, Center, Flex, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function NotificacaoPendencia() {

    const navigate = useNavigate();

    const onClickDirectionPendencia = () => {
        navigate("/configurationHour");
    }

    return(
        <Center>
            <Box
                position="fixed"
                textAlign="center"
                justifyContent="flex-end"
                alignItems="center"
                minH={16}
                minW="100%"
                p={2}
                marginTop="18vh"
                bg="red"
                border="1px solid rgba(255, 255, 255, 0.18)"
                zIndex="2 !important"
            >
                <Text
                    marginLeft="10%" 
                    color={"white"}
                >
                    <b>⚠ Atenção!! Você tem aulas que ainda não reportou</b>
                    <Button 
                        marginLeft="1%"
                        bg="#BEB100"
                        color="black"
                        onClick={onClickDirectionPendencia}
                    >
                        Resolver pendências
                    </Button>
                </Text>
            </Box>
        </Center>
        
    )
}