import { Box, Flex, IconButton, Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoIosNotifications, IoMdTrash } from "react-icons/io";
import { api } from "../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";

interface Notificacoes {
    id: number;
    titulo: string;
    conteudo: string;
    lida: boolean;
}

export default function NotificationSection() {
    const [qtdNotificacoesNaoLidas, setQtdNotificacoesNaoLidas] = useState(0);
    const [notificacoes, setNotificacoes] = useState<Notificacoes[]>([]);
    const [selectedNotificacao, setSelectedNotificacao] = useState<Notificacoes | null>(null);
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    useEffect(() => {
        if (decodedToken) {
            api.get(`/api/Notificacao/ObterNotificacaoPorGrupo/${decodedToken?.role}/${decodedToken?.Id}`)
                .then((response) => {
                    var notificacoes = response.data.object;
                    var notificacoesNaoLidas = notificacoes.filter((notificacao: Notificacoes) => !notificacao.lida);
                    setQtdNotificacoesNaoLidas(notificacoesNaoLidas.length);
                    setNotificacoes(notificacoes);
                })
                .catch((err) => {
                    toast.error("Erro ao obter notificações");
                });
        }
    }, [decodedToken]);

    const handleNotificationClick = (notificacao: Notificacoes) => {
        if (!notificacao.lida) {
            var notificacaoDto = {
                IdNotificacao: notificacao.id
            }

            api.put(`/api/Notificacao/MarcarNotificacaoComoLida`, notificacaoDto)
            .then(() => {
                setNotificacoes(prevState =>
                    prevState.map(n =>
                        n.id === notificacao.id ? { ...n, lida: true } : n
                    )
                );
                setQtdNotificacoesNaoLidas(prev => prev - 1);
            })
            .catch((err) => {
                toast.error("Erro ao marcar notificação como lida");
            });
        }
        setSelectedNotificacao(notificacao);
        onOpen();
    };

    const handleDeleteNotification = (notificacaoId: number) => {
        api.delete(`/api/Notificacao/ExcluirNotificacao/${notificacaoId}`)
            .then(() => {
                setNotificacoes(prevState => prevState.filter(n => n.id !== notificacaoId));
                toast.success("Notificação excluída com sucesso");
            })
            .catch((err) => {
                toast.error("Erro ao excluir notificação");
            });
    };

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };

    return (
        <Flex mr={4} position="relative" alignItems="center">
            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label="Notificações"
                    icon={<IoIosNotifications fontSize="26px" color="#6C6C6C" />}
                    variant="ghost"
                />
                {qtdNotificacoesNaoLidas > 0 && (
                    <Box
                        position="absolute"
                        top="-4px"
                        right="-4px"
                        backgroundColor="red"
                        borderRadius="full"
                        width="20px"
                        height="20px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                        fontSize="12px"
                        fontWeight="bold"
                    >
                        {qtdNotificacoesNaoLidas}
                    </Box>
                )}
                <MenuList maxWidth="300px" maxHeight="400px" overflowY="auto">
                    <MenuGroup title="Notificações">
                        {notificacoes.length > 0 ? (
                            notificacoes.map((notificacao) => (
                                <MenuItem
                                    key={notificacao.id}
                                    onClick={() => handleNotificationClick(notificacao)}
                                    backgroundColor={!notificacao.lida ? 'lightblue' : 'white'}
                                    _hover={{ backgroundColor: !notificacao.lida ? 'lightblue' : 'gray.100' }}
                                >
                                    <Flex justifyContent="space-between" alignItems="center" width="100%">
                                        <Box>
                                            <Box fontWeight="bold">{truncateText(notificacao.titulo, 25)}</Box>
                                            <Box fontSize="sm">{truncateText(notificacao.conteudo, 25)}</Box>
                                        </Box>
                                        <IconButton
                                            aria-label="Excluir notificação"
                                            icon={<IoMdTrash color="red" />}
                                            size="sm"
                                            variant="ghost"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteNotification(notificacao.id);
                                            }}
                                        />
                                    </Flex>
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem>Nenhuma notificação</MenuItem>
                        )}
                    </MenuGroup>
                    <MenuDivider />
                </MenuList>
            </Menu>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedNotificacao?.titulo}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>{selectedNotificacao?.conteudo}</Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
}
