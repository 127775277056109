import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { formatDate } from "../../utils/formatter";
import PatternSelect from "../../components/Patterns/Select";

interface GetAllUserResponse {
    id_Professor: Number;
    nomeProfessor: string;
    valorAPagar: string;
    quantidadeAulas: number;
    mes: string;
};

interface GetTeacherResponse {
    name: string;
    id: Number;
};

export default function PendenciasProfessoresGeral() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);

    useEffect(() => {
        if(decodedToken){
            api.get(`/api/Pendencia/RetornaPendenciasGeral`).then((response) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        ...row,
                        data_Pendencia: formatDate(row.data_Pendencia)
                    }
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }
    }, [decodedToken]);


    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>     
            <Box mt={6}>
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={true}
                    updateTick={updateTick}
                />
            </Box>
        </>   
    );
};