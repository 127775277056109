import { Button, Center, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Select, { MultiValue } from 'react-select';

interface DataGridProps {
    turmaId: string;
    updateTick: () => void;
};

export default function TurmasAdicionarAlunosSeparadamenteModal ({turmaId, updateTick} : DataGridProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [estudantes, setEstudantes] = useState<Student[]>([]);

    const [estudantesSelecionados, setEstudantesSelecionados] = useState<MultiValue<{ value: number; label: string }>>([]);

    useEffect(() => {
        carregarDadosBase();
    }, [isOpen, decodedToken])

    const carregarDadosBase = () => {
        if(decodedToken) {
            api.get("/api/StudentSelectBox")
                .then((response) => {
                    setEstudantes(response.data.object.filter((estudante:Student) => estudante.situacaoDoEstudante === "ATIVO"));
                })   
                .catch(() => setIsError(() => true))
                .finally(() => setIsLoading(false))
        }
    }

    const handleSubmit = () => {
        const alunosNaTurma = estudantesSelecionados.map(estudante => ({
          IdAluno: estudante.value
        }));
      
        setIsLoading(true);
        api.post(`/api/turmas/AdicionarAlunosEmUmaTurmaExistente/${turmaId}`, alunosNaTurma)
          .then(response => {
            toast.success('Alunos adicionados com sucesso!');
            onClose();
            updateTick();
          })
          .catch(error => {
            toast.error('Erro ao adicionar alunos');
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

    
    return (
        <>
            {
                <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Adicionar Alunos Na Turma</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        {
                            <FormControl>
                                <FormLabel fontWeight="bolder">Novos Alunos na Turma</FormLabel>
                                <Select
                                    id="alunos"
                                    name="alunos"
                                    placeholder="Escolha os Alunos a serem adicionados..."
                                    noOptionsMessage={() => 'Nenhuma correspondência.'}
                                    isMulti
                                    value={estudantesSelecionados}
                                    onChange={(selectedOptions) => { 
                                        setEstudantesSelecionados(selectedOptions as MultiValue<{ value: number; label: string }>); 
                                    }}
                                    options={estudantes.map(t => ({ value: t.id, label: t.nome }))}
                                />
                            </FormControl>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={handleSubmit} >Salvar</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}