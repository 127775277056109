import { Box, Center, Circle, Flex, FormControl, FormLabel, Grid, Icon, Radio, RadioGroup, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PatternButton from "../../components/Patterns/Button";
import PatternCard from "../../components/Patterns/Card";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { FaPhone, FaSearch } from "react-icons/fa";
import PatternCheckbox from "../../components/Patterns/Checkbox";
import NewCalendarModal from "./NewCalendarModal";
import NewClassModal from "./NewClassModal";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import Select, { SingleValue } from 'react-select';
import PatternInput from "../../components/Patterns/Input";
import { NewClassMassModal } from "./NewClassMassModal";
import AlterCalendarModal from "./AlterCalendarModal";
import { DeleteModal } from "../../components/DataGrid/DeleteModal";
import Legend from "./Legend";

type GroupedTeachers = {
    id: number;
    professor: string;
    diasSemana: string[];
    horaInicialDisponivel: Date;
    horaFinalDisponivel: Date;
    prioridade: number;
};

type HoursReservedGroup = {
    id: number;
    professor: string;
    diasSemana: string[];
    horaReservada: Date;
};

export default function Calendar() {
    const [cookies] = useCookies(['loginToken']);
    let { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [teachers, setTeachers] = useState<Teachers[]>([]);
    const [students, setStudents] = useState<Student[]>([]);
    const [studentsExperimental, setStudentsExperimental] = useState<StudentExperimental[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [choosenTeacher, setChoosenTeacher] = useState<SingleValue<{ value: number; label: string }>>();
    const [choosenTeacherId, setChoosenTeacherId] = useState<number>(0);
    const [calendarData, setCalendarData] = useState<CalendarData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cellCoordHint, setCellCoordHint] = useState<boolean>(true);
    const [tick, setTick] = useState<number>(0);
    const [searchOpt, setSearchOpt] = useState<string>('teachers');
    const [initialTime, setInitialTime] = useState<string>('--:--');
    const [finalTime, setFinalTime] = useState<string>('--:--');
    const [choosenDayOfWeek, setChoosenDayOfWeek] = useState<any>();
    const [teachersByHour, setTeachersByHour] = useState<GroupedTeachers[]>([]);
    const [hoursReservedGroup, setHoursReservedGroup] = useState<HoursReservedGroup[]>([]);

    const daysOfWeek = [
        { value: 1, label: 'Segunda-feira' },
        { value: 2, label: 'Terça-feira' },
        { value: 3, label: 'Quarta-feira' },
        { value: 4, label: 'Quinta-feira' },
        { value: 5, label: 'Sexta-feira' },
    ];

    useEffect(() => {
        if (teachers.length > 0 && decodedToken && decodedToken?.role === 'Professor') {
            setChoosenTeacherId(Number(decodedToken?.TeacherId));
            handleSearchCalendar();
        };

        if (teachers.length <= 0) {
            api.get("/api/TeacherSelectBox")
                .then(response => {
                    setTeachers(response.data.object);
                });
        };
    }, [cookies, decodedToken, teachers]);

    useEffect(() => {
        if (choosenTeacherId > 0) {
            api.get('api/StudentPerTeacherCalendar', {
                params: {
                    TeacherId: choosenTeacherId,
                },
            })
                .then(response => setStudents(response.data.object));

            api.get('api/GetAllUser')
                .then(response => setUsers(response.data.object));

            api.get(`api/NovosAlunosExperimentais/${choosenTeacherId}`)
                .then((response => setStudentsExperimental(response.data.object)));
        };
    }, [choosenTeacherId]);

    useEffect(() => {
        if (tick > 0) handleSearchCalendar();
    }, [tick]);

    const handleSearchCalendar = async (teacherId: number = 0, forceTeacherSearch: boolean = false) => {
        if (teacherId <= 0) teacherId = choosenTeacherId;

        if (searchOpt === 'teachers' && choosenTeacherId <= 0) return;
        if (searchOpt === 'hours' && (initialTime === '--:--' || finalTime === '--:--' || !choosenDayOfWeek)) return;
        setIsLoading(() => true);

        if (searchOpt === 'teachers' || forceTeacherSearch) {
            try {
                const response = await api.get('/api/Agenda/ProcurarAgendaPorProfessor', {
                    params: {
                        professor_Id: teacherId,
                    },
                });

                setCalendarData(response.data.object);

                if (response.data.object.length > 0) {
                    toast.success('Calendário retornado com sucesso!')
                } else {
                    toast.warn('Este professor não possui um calendário!')
                };
            } catch {
                toast.error('Um erro ocorreu ao buscar o calendário deste professor. Contate um Administrador!');
            } finally {
                setIsLoading(() => false);
            };
        } else if(searchOpt === 'hours') {
            try {
                const initialHours = initialTime.split(':')[0];
                const initialMinutes = initialTime.split(':')[1];
                const finalHours = finalTime.split(':')[0];
                const finalMinutes = finalTime.split(':')[1];

                const data = {
                    horaInicialAula: moment().format(`YYYY-MM-DD[T${initialHours}:${initialMinutes}:00.000Z]`),
                    horaFinalAula: moment().format(`YYYY-MM-DD[T${finalHours}:${finalMinutes}:00.000Z]`),
                    diaSemana: choosenDayOfWeek.map((day: any) => day.value),
                };

                const response = await api.post('/api/Agenda/ProcurarAgendaPorDiaEHorarioDisponivel', data);

                let idx = 1;
                const groupedTeachersByHour: GroupedTeachers[] = [];
                response?.data?.object?.forEach((obj: any) => {
                    const existing = groupedTeachersByHour.find(teacher => 
                        obj.professor === teacher.professor && 
                        obj.horaInicialDisponivel === teacher.horaInicialDisponivel
                    );
                    if (!existing) {
                        groupedTeachersByHour.push({ 
                            id: idx, 
                            professor: obj.professor, 
                            diasSemana: [obj.diaSemanaDisponivel], 
                            horaInicialDisponivel: obj.horaInicialDisponivel, 
                            horaFinalDisponivel: obj.horaFinalDisponivel,
                            prioridade: obj.prioridade
                        });
                        idx++;
                    } else {
                        existing.prioridade = obj.prioridade;
                        existing.diasSemana.push(obj.diaSemanaDisponivel);
                    }
                });

                setTeachersByHour(groupedTeachersByHour);
            } catch {
                toast.error('Um erro ocorreu ao buscar horários com os valores definidos. Contate um Administrador!');
            } finally {
                setIsLoading(() => false);
            };
        } else {
            try {
                const response = await api.get('/api/Agenda/ObterAgendaReservada');

                let idx = 1;
                const groupedReservedByHour: HoursReservedGroup[] = [];
                response.data.object.forEach((obj: any) => {
                    const existing = groupedReservedByHour.find(teacher => obj.professor === teacher.professor);

                    if (!existing) {
                        groupedReservedByHour.push({ id: idx, professor: obj.nomeProfessor, diasSemana: [obj.diaSemanaDisponivel], horaReservada: obj.horaInicialAula})
                        idx++;
                    } else {
                        existing.diasSemana.push(obj.diaSemanaDisponivel);
                    };
                });

                setHoursReservedGroup(groupedReservedByHour);
            } catch {
                toast.error('Um erro ocorreu ao buscar horários com os valores definidos. Contate um Administrador!');
            } finally {
                setIsLoading(() => false);
            };
        };
    };

    const handleDeleteCalendar = async () => {
        setIsLoading(() => true);

        try {
            await api.delete('/api/Agenda', {
                params: {
                    id: choosenTeacherId,
                },
            });

            toast.success('Calendário deletado com sucesso!');
            setTick(tick + 1);
        } catch(ex:any){
            toast.error('Erro ao deletar calendário!');
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleChooseTeacherByHour = async (teacher: string) => {
        const foundTeacher = teachers.find(_teacher => _teacher.nome === teacher);
        if (foundTeacher) {
            setChoosenTeacher({ value: foundTeacher.id, label: foundTeacher.nome });
            setChoosenTeacherId(foundTeacher.id);
            await handleSearchCalendar(foundTeacher.id, true);
            setSearchOpt('teachers');
        };
    };

    return (
        <>
            <Helmet>
                <title>King | Calendário de Aulas</title>
            </Helmet>
            <PatternCard title="Calendário de Aulas">
                {!(decodedToken?.role === 'Professor') && (
                    <>
                        <Flex gap={2}>
                            <Text>Buscar por:</Text>
                            <RadioGroup value={searchOpt} onChange={(event) => { setCalendarData([]); setSearchOpt(event); }}>
                                <Radio id="teachers" name="teachers" value="teachers" mr={2}>Professores</Radio>
                                <Radio id="hours" name="hours" value="hours" mr={2}>Horários</Radio>
                                <Radio id="hours" name="hoursReserved" value="hoursReserved">Horários Reservados</Radio>
                            </RadioGroup>
                        </Flex>
                       
        
                        <Flex gap={4} align="flex-end">
                            {searchOpt === 'teachers' ? (
                                <FormControl>
                                    <FormLabel fontWeight="bolder">Professor</FormLabel>
                                    <Select
                                        id="teacher"
                                        name="teacher"
                                        placeholder="Escolha um professor..."
                                        noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                        value={choosenTeacher}
                                        onChange={(event) => { setChoosenTeacher(event); setChoosenTeacherId(Number(event?.value)); setCalendarData([]); }}
                                        options={teachers.map(t => { return { value: t.id, label: t.nome } })}
                                    />
                                </FormControl>
                            ) : searchOpt === 'hours' ? (
                                <Flex gap={2} width="100%">
                                    <FormControl>
                                        <FormLabel fontWeight="bolder">Horário inicial</FormLabel>
                                        <PatternInput
                                            id="initial-time"
                                            name="initial-time"
                                            type="time"
                                            value={initialTime}
                                            onChange={(event) => setInitialTime(event.currentTarget.value)}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight="bolder">Horário final</FormLabel>
                                        <PatternInput
                                            id="final-time"
                                            name="final-time"
                                            type="time"
                                            value={finalTime}
                                            onChange={(event) => setFinalTime(event.currentTarget.value)}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight="bolder">Dia da Semana</FormLabel>
                                        <Select
                                            id="day-of-week"
                                            name="day-of-week"
                                            placeholder="Escolha um dia da semana..."
                                            noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                            value={choosenDayOfWeek}
                                            onChange={(event) => setChoosenDayOfWeek(event)}
                                            options={daysOfWeek}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                    </FormControl>
                                    
                                </Flex>
                                
                            ) :
                            (
                                <p>Nessa tela será exibido os horários reservados de todos os professores</p>
                            )}
                            <PatternButton onClick={() => handleSearchCalendar()} isLoading={isLoading} icon={FaSearch}>Buscar</PatternButton>
                            {['Administrador', 'Coordenador', 'Suporte'].indexOf(String(decodedToken?.role)) >= 0 && (<NewCalendarModal teachers={teachers} setTick={setTick} setIndexChoosenTeacherId={setChoosenTeacherId} />)}
                        </Flex>
                        {searchOpt === 'hours' && (
                            <div style={{ marginTop: '20px' }}> 
                                <Legend />
                            </div>
                        )}
                    </>
                )}

                {choosenTeacherId > 0 && searchOpt === 'teachers' && (
                    <Flex mt={8} gap={2} justify="center" align="center" flexDir="row">
                        <Icon as={FaPhone} />
                        <Text>{teachers.find(t => t.id === choosenTeacherId)?.telefone?.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3') ?? 'Sem telefone'}</Text>
                    </Flex>
                )}

                {searchOpt === 'teachers' ? (
                    <>
                        <Box position="relative" display="block" overflowX={calendarData.length > 0 ? "scroll" : "hidden"}>
                            {calendarData.length > 0 ? (
                                <Box>
                                    <Flex gap={4} mb={2} align="flex-start" justify="flex-start">
                                        {(['Administrador', 'Coordenador', 'Suporte'].indexOf(String(decodedToken?.role)) >= 0 && choosenTeacher) && (<NewClassMassModal students={students}  choosenTeacher={choosenTeacher} setTick={setTick} />)}
                                        {(['Administrador', 'Coordenador', 'Suporte'].indexOf(String(decodedToken?.role)) >= 0 && choosenTeacher) && (<AlterCalendarModal choosenTeacher={choosenTeacher} setTick={setTick} />)}
                                        <FormControl display="flex" flexDirection="row" gap={2}>
                                            <PatternCheckbox isChecked={cellCoordHint} onChange={() => setCellCoordHint(!cellCoordHint)} />
                                            <FormLabel mt={"10px"}>Coordenadas da Célula</FormLabel>
                                        </FormControl>
                                    </Flex>
                                    <Grid templateColumns="8% 92%" minW="1400px">
                                        <Flex flexDir="row">
                                            <Box as="span" color="white" bg="primary.500" border="1px solid black" borderTop="2px solid black" borderBottom="2px solid black" w="100%">
                                                <Text textAlign="center">Horários</Text>
                                                {calendarData.filter(cd => cd.diaSemana === 1).map(cd => {
                                                    return (
                                                        <Text key={cd.id} textAlign="center" color="black" bg="#EEEEF4" borderBottom="1px" borderTop="1px">{moment(cd.horaInicialAula).format('HH:mm')} - {moment(cd.horaFinalAula).format('HH:mm')}</Text>
                                                    );
                                                })}
                                            </Box>
                                        </Flex>
                                        <Flex w="100%" flexDir="column">
                                            <Grid templateColumns="1fr 1fr 1fr 1fr 1fr" maxH="12px" w="100%">
                                                {[/*'DOM', */'SEG', 'TER', 'QUA', 'QUI', 'SEX',/* 'SÁB'*/].map(((diaSemana, idx) => {
                                                    return (
                                                        <Flex textAlign="center" flexDir="column" color="white" bg="primary.500" border="1px solid black" borderTop="2px solid black" borderBottom="2px solid black">
                                                            <Text w="100%">{diaSemana}</Text>
                                                            {calendarData.filter(cd => cd.diaSemana === idx + 1).map(cd => {
                                                                return (
                                                                    <NewClassModal
                                                                        cellCoordHint={cellCoordHint}
                                                                        dayOfWeek={diaSemana}
                                                                        calendarData={cd}
                                                                        teacherId={choosenTeacherId}
                                                                        teacherName={teachers.find(t => t.id === choosenTeacherId)?.nome}
                                                                        setTick={setTick}
                                                                        dayClasses={calendarData.filter(c => c.diaSemana === cd.diaSemana)}
                                                                        allCalendar={calendarData}
                                                                        role={decodedToken?.role}
                                                                        students={students}
                                                                        studentsExperimental={studentsExperimental}
                                                                        users={users}
                                                                    />
                                                                );
                                                            })}
                                                        </Flex>
                                                    );
                                                }))}
                                            </Grid>
                                        </Flex>
                                    </Grid>
                                </Box>
                            ) : (
                                <Text mt={4}>{searchOpt === 'teachers' ? 'Escolha um professor e clique em buscar para carregar um Calendário...' : 'Defina os valores para buscar professores disponvéis no horário/dia da semana...'}</Text>
                            )}
                        </Box>
                        {(calendarData.length > 0 && (decodedToken?.role === 'Administrador' || decodedToken?.role === 'Coordenador')) && (<DeleteModal teacherName={teachers.find(t => t.id === choosenTeacherId)?.nome} deletePath="Agenda" nomeDelete="Deletar Calendário" id={choosenTeacherId} botaoExclusivo={true} />)}
                    </>
                ) : searchOpt === 'hours' ? (
                    <Grid overflowY="scroll" gridTemplateColumns={["1fr 1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr"]} gap={[2, 2, 2, 2, 4]} h="100%" p={8} mt={4}>
                        {teachersByHour.sort((a, b) => {
                            if (a.prioridade < b.prioridade) return 1;
                            else if (a.prioridade > b.prioridade) return -1;

                            return 0;
                        }).map(teacherByHour => {
                            return (
                                
                                <Center
                                key={teacherByHour.id}
                                flexDir="column"
                                bg={
                                        teacherByHour.prioridade === 3
                                            ? "#FF5353"
                                            : teacherByHour.prioridade === 2
                                            ? "yellow.500"
                                            : "primary.500"
                                    }
                                    color="white"
                                    textAlign="center"
                                    maxH="120px"
                                    p={8}
                                    onClick={() => handleChooseTeacherByHour(teacherByHour.professor)}
                                    cursor="pointer"
                                    transition="0.1s"
                                    borderRadius="16px"
                                    _hover={{ fontWeight: 'bolder', border: '2px dashed white', backgroundColor: '#003b70' }}
                                    >
                                    <Text fontSize={teacherByHour.professor.length >= 22 ? ["0.5rem", "0.5rem", "0.6rem", "0.65rem", "0.8rem"] : ["0.65rem", "0.65rem", "0.7rem", "0.8rem", "1rem"]}>{teacherByHour.professor}</Text>
                                    <Flex as="span" display="flex" gap={1} mt={2} mb={2}>
                                        {teacherByHour.diasSemana.map(d => {
                                            return (
                                                <Circle bg="#4facff" p="2px 8px" fontSize="0.7rem">{d.substring(0, 3).toUpperCase()}</Circle>
                                            );
                                        })}
                                    </Flex>
                                    <Text fontSize={["0.65rem", "0.65rem", "0.7rem", "0.8rem", "0.8rem"]}>{moment(teacherByHour.horaInicialDisponivel).format('HH:mm')} - {moment(teacherByHour.horaFinalDisponivel).format('HH:mm')}</Text>
                                </Center>
                            );
                        })}
                    </Grid>
                ) : (
                    <Grid overflowY="scroll" gridTemplateColumns={["1fr 1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr"]} gap={[2, 2, 2, 2, 4]} h="100%" p={8} mt={4}>
                        {hoursReservedGroup.sort((a, b) => {
                            if (a.professor > b.professor) return 1;
                            else if (a.professor < b.professor) return -1;

                            return 0;
                        }).map(reservedByHour => {
                            return (
                                <Center
                                    key={reservedByHour.id}
                                    flexDir="column"
                                    bg="#FF5353"
                                    color="white"
                                    textAlign="center"
                                    maxH="120px"
                                    p={8}
                                    onClick={() => handleChooseTeacherByHour(reservedByHour.professor)}
                                    cursor="pointer"
                                    transition="0.1s"
                                    borderRadius="16px"
                                    _hover={{ fontWeight: 'bolder', border: '2px dashed white', backgroundColor: '#FA1E1E' }}
                                >
                                    <Text fontSize={reservedByHour.professor.length >= 22 ? ["0.5rem", "0.5rem", "0.6rem", "0.65rem", "0.8rem"] : ["0.65rem", "0.65rem", "0.7rem", "0.8rem", "1rem"]}>{reservedByHour.professor}</Text>
                                    <Flex as="span" display="flex" gap={1} mt={2} mb={2}>
                                        {reservedByHour.diasSemana.map(d => {
                                            return (
                                                <Circle bg="#881600" p="2px 8px" fontSize="0.7rem">{d?.substring(0, 3).toUpperCase()}</Circle>
                                            );
                                        })}
                                    </Flex>
                                    <Text fontSize={["0.65rem", "0.65rem", "0.7rem", "0.8rem", "0.8rem"]}>{moment(reservedByHour.horaReservada).format('HH:mm')}</Text>
                                </Center>
                            );
                        })}
                    </Grid>
                )}
            </PatternCard>
        </>
    );
};