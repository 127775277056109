import { useMediaQuery } from "@chakra-ui/react";
import { AulaProvider } from "../../contexts/VerificaAulasLancadasEm24hContext";
import Sidebar from "./Sidebar";
import { MobileSideBar } from "./Sidebar.mobile";

export default function IndexSidebar() {

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    return (
        <AulaProvider>
            <>
                {isLargerThan768 && (<Sidebar />)}
                {!isLargerThan768 && (<MobileSideBar />)}
            </>
        </AulaProvider>
    )
}

