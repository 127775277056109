import { useState } from "react";
import { useCookies } from "react-cookie";
import { useJwt } from 'react-jwt';
import {
    Button,
    Text,
    MenuItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    MenuItemProps,
    Avatar,
    Center,
    Stack,
    Circle,
    Input,
    Box,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PatternButton from "../Patterns/Button";
import PatternInput from "../Patterns/Input";
import { api } from "../../services/api";

export default function PreferencesModal({ ...rest }: MenuItemProps) {
    const [cookies] = useCookies(["loginToken"]);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [visible, setVisible] = useState<boolean>(false);
    const [, setFile] = useState<File>();
    const [fileSrc, setFileSrc] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

    const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.files) {
                const inptFile = event.target.files[0];
                const newFileSrc = URL.createObjectURL(inptFile);

                setFileSrc(newFileSrc);
                setFile(inptFile);
            };
        }
        catch {
            toast.error("Um erro ocorreu ao fazer o Upload da imagem, tente novamente!");
            return;
        };
    };

    const handleChangePassword = async () => {
        setIsLoading(true);

        if (newPassword !== confirmNewPassword) {
            toast.warn("As novas senhas não coincidem!");
            setIsLoading(false);
            return;
        };
        
        if (newPassword === oldPassword) {
            toast.warn("A nova senha e a antiga senha não podem ser iguais!");
            setIsLoading(false);
            return;
        };

        try {
            const data = {
                Id: decodedToken?.Id,
                OldPassword: oldPassword,
                NewPassword: newPassword,
            };

            await api.put('api/AlterPasswordUser', data);
            toast.success("Preferências salvas com sucesso!");
        } catch {
            toast.error("Um erro ocorreu ao salvar suas preferências!");
        } finally {
            setIsLoading(false);
        };
    };

    const validatePassword = (password: string) => {
        const validations = {
            minLength: password.length >= 8,
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        return validations;
    };

    const passwordValidations = validatePassword(newPassword);

    // Variável para verificar se a senha é válida e todos os critérios foram atendidos
    const isPasswordValid = Object.values(passwordValidations).every(Boolean);

    return (
        <>
            <MenuItem onClick={onOpen} {...rest}>Preferências</MenuItem>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Preferências do Usuário</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Center display="flex" flexDir="column" gap={2}>
                            <Avatar
                                position="relative"
                                name={decodedToken?.Username}
                                cursor="pointer"
                                src={fileSrc !== '' ? fileSrc : undefined}
                                onMouseOver={() => setVisible(true)}
                            >
                                {visible && (
                                    <Circle
                                        position="absolute"
                                        bg="rgba(255, 255, 255, 0.7)"
                                        p="1.5em 1em"
                                        fontSize="0.55rem"
                                        onMouseLeave={() => setVisible(false)}
                                    >
                                        <Input
                                            position="absolute"
                                            w="100%"
                                            h="100%"
                                            opacity="0"
                                            cursor="pointer"
                                            type="file"
                                            multiple={false}
                                            accept="image/png, image/jpeg"
                                            onChange={handleChangeAvatar}
                                        />
                                        Alterar foto
                                    </Circle>
                                )}
                            </Avatar>
                            <Text>{decodedToken?.Username}</Text>
                        </Center>

                        <Stack mt="2rem !important">
                            <Text fontWeight="700" fontSize="1.2rem">Alterar Senha</Text>
                            
                            <InputGroup size="lg">
                                <PatternInput
                                    type={showOldPassword ? "text" : "password"}
                                    id="actual-password"
                                    name="actual-password"
                                    placeholder="Senha Atual"
                                    value={oldPassword}
                                    onChange={(event) => setOldPassword(event.currentTarget.value)}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={() => setShowOldPassword(!showOldPassword)}>
                                        {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                            <Stack mt="2rem !important">
                                <InputGroup size="lg">
                                    <PatternInput
                                        type={showNewPassword ? "text" : "password"}
                                        id="new-password"
                                        name="new-password"
                                        placeholder="Nova Senha"
                                        value={newPassword}
                                        onChange={(event) => setNewPassword(event.currentTarget.value)}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>

                                <InputGroup size="lg">
                                    <PatternInput
                                        type={showConfirmNewPassword ? "text" : "password"}
                                        id="confirm-new-password"
                                        name="confirm-new-password"
                                        placeholder="Confirmar nova Senha"
                                        value={confirmNewPassword}
                                        onChange={(event) => setConfirmNewPassword(event.currentTarget.value)}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                                            {showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </Stack>
                        </Stack>
                        
                        <Box mt={4}>
                            <Text color={passwordValidations.minLength ? "green" : "red"}>
                                - 8 caracteres
                            </Text>
                            <Text color={passwordValidations.hasUpperCase ? "green" : "red"}>
                                - 1 Letra maiúscula
                            </Text>
                            <Text color={passwordValidations.hasLowerCase ? "green" : "red"}>
                                - 1 Letra minúscula
                            </Text>
                            <Text color={passwordValidations.hasNumber ? "green" : "red"}>
                                - 1 Número
                            </Text>
                            <Text color={passwordValidations.hasSpecialChar ? "green" : "red"}>
                                - 1 Caractere especial
                            </Text>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton 
                            onClick={handleChangePassword} 
                            isLoading={isLoading} 
                            isDisabled={!isPasswordValid || newPassword !== confirmNewPassword || newPassword === oldPassword || !newPassword || !confirmNewPassword || !oldPassword}
                        >
                            Alterar
                        </PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
