import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box, FormControl, FormLabel} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import PatternSelect from "../../components/Patterns/Select";
import NovosAlunosModel from "../../components/DataGrid/AddModals/NovosAlunosModel";
import moment from "moment";

interface GetAllUserResponse {
    Id: Number;
    Nome_Professor: string;
    Id_Aluno: Number;
    Nome_Aluno: string;
    Observation: string;
    TypeClass: string;
    Quantidade_Aula: Number;
    Tempo_Aula_Aluno: Number;
    Dia_Aula: string;
};

interface GetUsuallyUser {
    name: string;
    id: Number;
}

export default function HistoricoPorAluno(props: any) {
    const agora = moment();
    const mesAtual = agora.month() + 1;
    const anoAtual = agora.year();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [semestre, setSemestre] = useState<number>(1);
    const [alunoEscolhido, setAlunoEscolhido] = useState<number>(0);
    const [aluno, setAluno] = useState<Student[]>([]);
    const [year, setYear] = useState<number>(anoAtual);

    useEffect(() => {
        setAluno(props?.aluno);
    }, [props])

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);
        // setRowData(() => []);

        updateData();
    }, [tick, decodedToken, semestre, alunoEscolhido]);

    const updateYear = async (year: string) => {       
        setYear(Number(year))      
    }

    const updateData = async () => {
        setIsLoading(() => true);
        if(decodedToken && alunoEscolhido !== 0){
            try{
                const dataClassLaunch = await api.get(`api/RetornaAulasPorAlunoPorSemestre/${semestre}/${alunoEscolhido}/${year}`)
                if(dataClassLaunch.data.object.length > 0) {
                    setRowData(dataClassLaunch.data.object.map((row: any) => {
                        return {
                            NomeProfessor: row.nome_Professor,
                            NomeAluno: row.nome_Aluno,
                            DiaAula: formatDate(row.dia_Aula),
                            Observacao: row.observation,
                            TipoAula: row.typeClass,
                            QuantidadeAula: row.quantidade_Aula,
                            TempoAulaAluno: row.tempo_Aula_Aluno,
                            MaterialUsadoEmAula: row.materialUsadoEmAula,
                            NivelDoAluno: row.nivelDoAluno,
                            TipoAulaPersonalizada: row.tipoAulaPersonalizada,
                            PaginaQueParouNoMaterial: row.paginaQueParouNoMaterial,
                            id: row.id
                        };
                    }));
                }
                else{
                    setRowData([]);
                }
            }
            catch(err){
                setIsError(() => true)
            }
            finally{
                setIsLoading(() => false)
            }
        }
    }
    
    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Semestre da Apuração</FormLabel>
            <PatternSelect 
                placeholder=' ' 
                value={semestre} 
                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setSemestre(Number(event.currentTarget.value))}
            >
                <option key={1} value={1}>1º SEMESTRE</option>
                <option key={2} value={2}>2º SEMESTRE</option>
            </PatternSelect>
            <FormLabel htmlFor="initial-date" fontWeight="bolder">Ano da Apuração</FormLabel>
            <PatternSelect 
                placeholder='ANO' 
                value={year} 
                onChange={(event) => { updateYear(event.currentTarget.value)}}
            >
                <option key={1} value={2023}>2023</option>
                <option key={2} value={2024}>2024</option>
                <option key={3} value={2025}>2025</option>
                <option key={4} value={2026}>2026</option>
                <option key={5} value={2027}>2027</option>
                <option key={6} value={2028}>2028</option>
                <option key={7} value={2029}>2029</option>
                <option key={8} value={2030}>2030</option>
                <option key={9} value={2031}>2031</option>
                <option key={10} value={2032}>2032</option>
                <option key={11} value={2033}>2033</option>
                <option key={12} value={2034}>2034</option>
            </PatternSelect>
            <FormControl>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                <PatternSelect 
                    placeholder='Aluno' 
                    value={Number(alunoEscolhido)} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setAlunoEscolhido(Number(event.currentTarget.value))}
                >
                    {
                        aluno.map((obj:any) => (
                            <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                        ))
                    }
                </PatternSelect>
            </FormControl>
            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <NovosAlunosModel modalType="new" updateTick={updateTick} />
                </Box>
            )}
            {
                semestre !== 0 && alunoEscolhido !== 0?
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    icons={['Deletar']}
                    enableExport={decodedToken?.role === 'Administrador' ? true : false}
                    updateTick={updateTick}
                    deletePath={"ClassLaunch"}
                />
                :
                null
            }
        </>
        
    );
};