import { Box, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text } from "@chakra-ui/react";
import { cloneElement, createElement, ReactElement, useEffect, useState } from "react";
import PatternButton from "../Patterns/Button";

interface WaitConfirmationPopoverProps {
    children: React.ReactNode;
    title: string;
    description: string;
    onAction?: () => void;
};

export default function WaitConfirmationPopover({ children, title, description, onAction = undefined }: WaitConfirmationPopoverProps) {
    const [timer, setTimer] = useState<number>(10);

    useEffect(() => {
        setTimer(10);
    }, []);

    const handleStartTimer = () => {
        if (timer === 10) {
            const intrvl = setInterval(() => {
                setTimer(prev => {
                    if (prev - 1 <= 0)
                        clearInterval(intrvl);

                    return prev - 1;
                });

            }, 1000);
        }
    };

    return (
        <Popover>
            <PopoverTrigger>
                {cloneElement(children as ReactElement, { onClick: handleStartTimer })}
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontWeight="bolder">{title}</PopoverHeader>
                <PopoverBody>
                    <Text mb={4}>{description}</Text>
                    {onAction && <PatternButton w="100%" onClick={onAction} isDisabled={timer > 0}>{timer > 0 ? `Aguarde ${timer}s` : 'Confirmar'}</PatternButton>}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};