import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    FormControl,
    FormLabel,
    Flex,
} from '@chakra-ui/react'
import moment from 'moment';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PatternButton from '../../components/Patterns/Button';
import PatternInput from '../../components/Patterns/Input';
import PatternSelect from '../../components/Patterns/Select';
import { api } from '../../services/api';

interface NewCalendarProps {
    teachers: Teachers[];
    setTick: React.Dispatch<React.SetStateAction<number>>;
    setIndexChoosenTeacherId: React.Dispatch<React.SetStateAction<number>>;
};

export default function NewCalendarModal({ teachers, setTick, setIndexChoosenTeacherId }: NewCalendarProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [choosenTeacherId, setChoosenTeacherId] = useState<number>(0);
    const [initialClassHour, setInitialClassHour] = useState<string>('');
    const [finalClassHour, setFinalClassHour] = useState<string>('');
    const [classTime, setClassTime] = useState<number>(0);
    const [minutesInterval, setMinutesInterval] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = async () => {
        if (choosenTeacherId === 0 || initialClassHour === '' || finalClassHour === '' || classTime === 0) {
            toast.warn('Por favor, preencha todos os dados corretamente!');
            return;
        };

        setIsLoading(() => true);

        try {
            const data = {
                professor_Id: choosenTeacherId,
                horarioInicialAgenda: moment(`${moment().utc().format('YYYY-MM-DD')}T${initialClassHour}:00.000Z`).utc().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                horarioFimAgenda: moment(`${moment().utc().format('YYYY-MM-DD')}T${finalClassHour}:00.000Z`).utc().format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                tempoAula: classTime,
                intervaloEmMinutos: minutesInterval,
            };

            await api.post('/api/Agenda', data);
            setIndexChoosenTeacherId(choosenTeacherId);
            toast.success('Calendário criado com sucesso!');
            handleClose();
            setTick(prev => prev + 1);
        } catch(ex:any) {
            if(ex.response.data.message === 'Nao e possivel criar uma agenda com o horario final inferior ao horario inicial')
                toast.error('A Data Final não pode ser anterior da Data Inicial');
            else
                toast.error('Ocorreu um erro ao criar o Calendário. Contate um Administrador!');
        } finally {
            setIsLoading(() => false);
        };
    };

    const clearInputs = () => {
        setChoosenTeacherId(0);
        setInitialClassHour('');
        setFinalClassHour('');
        setClassTime(0);
        setMinutesInterval(0);
    };

    const handleClose = () => {
        onClose();
        clearInputs();
    };

    return (
        <>
            <PatternButton onClick={onOpen} icon={FaPlus}>Novo</PatternButton>

            <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Novo Calendário</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight="bolder">Professor</FormLabel>
                            <PatternSelect id="professor" name="professor" value={choosenTeacherId} onChange={(event) => setChoosenTeacherId(Number(event.currentTarget.value))}>
                                <option value={0} disabled>Escolha um professor...</option>
                                {teachers?.map(teacher => {
                                    return (
                                        <option key={teacher.id} value={teacher.id}>{teacher.nome}</option>
                                    );
                                })}
                            </PatternSelect>
                        </FormControl>
                        <Flex flexDir="row" gap={8} mt={4}>
                            <FormControl>
                                <FormLabel fontWeight="bolder">Início Aula</FormLabel>
                                <PatternInput
                                    type="time"
                                    id="inicio-aula"
                                    name="inicio-aula"
                                    value={initialClassHour}
                                    onChange={(event) => setInitialClassHour(event.currentTarget.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bolder">Final Aula</FormLabel>
                                <PatternInput
                                    type="time"
                                    id="final-aula"
                                    name="final-aula"
                                    value={finalClassHour}
                                    onChange={(event) => setFinalClassHour(event.currentTarget.value)}
                                />
                            </FormControl>
                        </Flex>
                        <Flex flexDir="row" gap={8} mt={4}>
                            <FormControl>
                                <FormLabel fontWeight="bolder">Tempo de Aula</FormLabel>
                                <PatternInput
                                    type="text"
                                    id="tempo-aula"
                                    name="tempo-aula"
                                    value={classTime}
                                    onChange={(event) => setClassTime(Number(event.currentTarget.value.replace(/\D/g, '')))}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bolder">Intervalo em Minutos</FormLabel>
                                <PatternInput
                                    type="text"
                                    id="intervalo-minutos"
                                    name="intervalo-minutos"
                                    value={minutesInterval}
                                    onChange={(event) => setMinutesInterval(Number(event.currentTarget.value.replace(/\D/g, '')))}
                                />
                            </FormControl>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={handleClose}>
                            Fechar
                        </Button>
                        <PatternButton mr={3} onClick={onSubmit} isLoading={isLoading}>
                            Criar Calendário
                        </PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};