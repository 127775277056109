import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text,
    Box,
    Icon
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FaLock } from "react-icons/fa";
import { useAulaStatus } from "../../contexts/VerificaAulasLancadasEm24hContext";

export default function ModelPlataformaBloqueada() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setIsModelCloseSuccess } = useAulaStatus();
    const handleClose = () => {
        onClose();
        setIsModelCloseSuccess(true);
    }

    useEffect(() => {
        onOpen();
    }, [onOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Plataforma Bloqueada</ModalHeader>
                <ModalCloseButton />

                <ModalBody display="flex" flexDirection="column" alignItems="center" gap={4}>
                    <Icon as={FaLock} w={10} h={10} color="red.500" />
                    <Text fontSize="xl" fontWeight="bold">
                        Atenção Professor!
                    </Text>
                    <Text textAlign="center">
                        Informamos que sua plataforma está temporariamente bloqueada devido à não conformidade com o prazo estabelecido para o lançamento das aulas. É essencial que todas as aulas sejam registradas no mesmo dia em que são ministradas.
                    </Text>
                    <Text textAlign="center">
                        Solicitamos que as aulas realizadas há mais de 24 horas sejam lançadas imediatamente para que o acesso à plataforma possa ser restabelecido.
                    </Text>
                    <Text textAlign="center">
                        Agradecemos sua atenção e colaboração para a manutenção da eficácia dos nossos procedimentos.
                    </Text>
                    <Text textAlign="center">
                        Obs: A plataforma pode demorar até 1 minuto para ser desbloqueada após o lançamento das aulas atrasadas.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleClose}>
                        Fechar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
