import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { Chart } from "react-google-charts";
import { Box, Center, CircularProgress, Flex, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { toast } from "react-toastify";
import DataGrid from "../../components/DataGrid";
import moment from "moment";
import { Radio, RadioGroup } from '@chakra-ui/react'

import Faturamento from "../../components/InfoCard/RevenueInfo";
import Informacoes from "../../components/InfoCard/Informacoes";
import Clientes from "../../components/InfoCard/ClientInfo";
import { formatCurrency } from "../../utils/formatter";
import PlataformaBloqueadaNecessitaAtualizarSenha from "../../components/Models/PlataformaBloqueadaNecessitaAtualizarSenha";
import { SenhaAtualizada } from "../../contexts/VerificaSeUsuarioAtualizouSenha";
import NovosAlunosEsseMes from "../../components/InfoCard/NovosAlunosEsseMes";
interface GetAllUserResponse {
    id: Number;
    quemFechou: string;
    dataFechamento: Date;
    dataInicioAulas: Date;
    valorFechado: Number;
    deOndeVeio: string;
    valorMatricula: Number;
    quantosDiasDemorouFechar: Number;
    semana: Number;
};

interface AlunoSituacao {
    tipoSituacao: string;
    quantidadeAlunos: number;
}

 
export const options = {
    title: "Novos Alunos por Semana",
};

export const options2 = {
    title: "Novos Alunos por Semana",
};

export const options3 = {
    title: "Vendas por dia - Sazonalidade",
};

export const options4 = {
    title: "De Onde Veio os Alunos Novos",
};

export const options5 = {
    title: "Motivo das desistências por mês",
};

export const options6 = {
    title: "Desistências por professor",
};


export default function DashboardsConfig() {
    const agora = moment();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [arrayDashPizza, setArrayDashPizza] = useState<any[]>([]);
    const [arrayDashDeOndeVeio, setArrayDashDeOndeVeio] = useState<any[]>([]);
    const [arrayMotivo, setArrayMotivo] = useState<any[]>([]);
    const [arrayDashLinha, setArrayDashLinha] = useState<any[]>([]);
    const [arrayDashLinhaSazonalidade, setArrayDashLinhaSazonalidade] = useState<any[]>([]);
    const [arrayDashDesistenciaPorProfessor, setArrayDashDesistenciaPorProfessor] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tipoFiltro, setTipoFiltro] = useState<string>("1");
    const [dataInicial, setDataInicial] = useState<string>(`${agora.year()}-${agora.month() + 1}-01 00:00:00`);
    const [dataFinal, setDataFinal] = useState<string>(`${agora.year()}-${agora.month() + 1}-${agora.endOf('month').date()} 23:59:59`);
    const [valorFechado, setValorFechado] = useState<number>(0);
    const [valorAgrupadoSalarioProfessor, setValorAgrupadoSalarioProfessor] = useState<number>(0);
    const [valorFaturamento, setValorFaturamento] = useState<number>(0);
    const [alunosAtivos, setAlunosAtivos] = useState<number>(0);
    const [alunosPausados, setAlunosPausados] = useState<number>(0);
    const [alunosInadimplentes, setAlunosInadimplentes] = useState<number>(0);
    const [alunosDesistentes, setAlunosDesistentes] = useState<number>(0);
    const [ehSenhaAlterada, setEhSenhaAlterada] = useState<boolean>(true);
    const [alunosAtivoEsseMes, setAlunosAtivoEsseMes] = useState<number>(0);
    const { senhaAtualizadaComSucesso } = SenhaAtualizada();

    useEffect(() => {
        if (decodedToken) {
            if(decodedToken.ResetPasswordSecurity === "False")
                setEhSenhaAlterada(false);
            else if(decodedToken.FirstAccess === "True")
                setEhSenhaAlterada(false);

            updateData();
        }
    }, [decodedToken, tipoFiltro, dataInicial, dataFinal]);

    const updateData = async () => {
        setIsLoading(true);
        limparDados();

        const dataMap: { [key: string]: [string, string] } = {
            "1": [`${agora.year()}-${agora.month() + 1}-01 00:00:00`, `${agora.year()}-${agora.month() + 1}-${agora.endOf('month').date()} 23:59:59`],
            "2": [`${agora.year()}-01-01 00:00:00`, `${agora.year()}-${agora.month() + 1}-${agora.date()} 23:59:59`],
            "3": ["2023-07-01 00:00:00", `${agora.year()}-${agora.month() + 1}-${agora.date()} 23:59:59`]
        };

        if (dataMap[tipoFiltro]) {
            setDataInicial(dataMap[tipoFiltro][0]);
            setDataFinal(dataMap[tipoFiltro][1]);
        }

        await Promise.all([
            carregaFechamentoMatriculaPorSemana(dataInicial, dataFinal),
            carregaDashboardPizza(dataInicial, dataFinal),
            carregaDashboardLinha(dataInicial, dataFinal),
            carregaDashboardLinhaSazonalidade(dataInicial, dataFinal),
            carregarValorTotalFechadoMes(dataInicial, dataFinal),
            carregaDashboardLinhaDeOndeVeio(dataInicial, dataFinal),
            carregaDashboardMotivo(dataInicial, dataFinal),
            carregaDashboardDesistenciaPorProfessor(dataInicial, dataFinal),
            TotalAlunosSituacao(dataInicial, dataFinal),
            AlunosAtivosEsseMes(dataInicial)
        ]);

        if (decodedToken?.role === 'Administrador') {
            await Promise.all([ValoresAgrupadosSalarioProfessor(dataInicial), ValoresFaturamento()]);
        }

        setIsLoading(false);
    };

    const limparDados = () => {
        setRowData([]);
        setArrayDashPizza([]);
        setArrayDashDeOndeVeio([]);
        setArrayMotivo([]);
        setArrayDashLinha([]);
        setArrayDashLinhaSazonalidade([]);
        setArrayDashDesistenciaPorProfessor([]);
        setValorFechado(0);
        setValorAgrupadoSalarioProfessor(0);
        setValorFaturamento(0);
        setAlunosAtivos(0);
        setAlunosPausados(0);
        setAlunosInadimplentes(0);
        setAlunosDesistentes(0);
        setAlunosAtivoEsseMes(0);
    };

    const apiCall = async <T,>(url: string, processData: (data: T) => void, transformData?: (data: any) => T) => {
        try {
            const response = await api.get(url);
            const data = transformData ? transformData(response.data.object) : response.data.object;
            processData(data);
        } catch (err) {
            toast.error("Ops, houve um problema. Tente novamente mais tarde!");
        }
    };

    const ValoresAgrupadosSalarioProfessor = (dataInicial: string) => apiCall<number>(`api/Metricas/ValoresAgrupadosSalarioProfessoresAutomaticos?dataInicial=${dataInicial}`, setValorAgrupadoSalarioProfessor);

    const ValoresFaturamento = () => apiCall<number>(`api/Metricas/FaturamentoMensal`, setValorFaturamento);

    const AlunosAtivosEsseMes = (dataInicial: string) => apiCall<number>(`api/Metricas/TotalNovosAlunosEsseMes/${dataInicial}`, setAlunosAtivoEsseMes);

    const TotalAlunosSituacao = (dataInicial: string, dataFinal: string) => {
        apiCall<AlunoSituacao[]>(`api/Metricas/TotalAlunosSituacao?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, (data) => {
            data.forEach((situacao: AlunoSituacao) => {
                switch (situacao.tipoSituacao) {
                    case "ATIVO":
                        setAlunosAtivos(situacao.quantidadeAlunos);
                        break;
                    case "PAUSADO":
                        setAlunosPausados(situacao.quantidadeAlunos);
                        break;
                    case "INADIMPLENTE":
                        setAlunosInadimplentes(situacao.quantidadeAlunos);
                        break;
                    case "DESISTENCIA":
                        setAlunosDesistentes(situacao.quantidadeAlunos);
                        break;
                    default:
                        break;
                }
            });
        });
    };

    const carregarValorTotalFechadoMes = (dataInicial: string, dataFinal: string) => apiCall<number>(`api/Metricas/TotalValorFechadoMes/${dataInicial}/${dataFinal}`, setValorFechado, (data) => Number(data.toFixed(2)));

    const carregaDashboardPizza = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/DashboardPizza/${dataInicial}/${dataFinal}`, setArrayDashPizza, (data) => [
        ["Dados da Semana", "Porcentagem"],
        ...data.map(({ semana, porcentagem }: { semana: string, porcentagem: number }) => [semana, Number(porcentagem.toFixed(2))])
    ]);

    const carregaDashboardMotivo = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/MotivoDesistencia?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, setArrayMotivo, (data) => [
        ["Motivo", "Quantidade"],
        ...data.map(({ tipoSituacao, quantidadeAlunos }: { tipoSituacao: string, quantidadeAlunos: number }) => [tipoSituacao, quantidadeAlunos])
    ]);

    const carregaDashboardLinhaDeOndeVeio = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/DashboardLinhaDeOndeVeio/${dataInicial}/${dataFinal}`, setArrayDashDeOndeVeio, (data) => [
        ["Quantidade de Alunos", "Quantidade"],
        ...data.map(({ deOndeVeio, quantidade }: { deOndeVeio: string, quantidade: number }) => [deOndeVeio, quantidade])
    ]);

    const carregaDashboardLinha = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/DashboardLinhaNovosAlunosPorSemana/${dataInicial}/${dataFinal}`, setArrayDashLinha, (data) => [
        ["Quantidade de Alunos", "Alunos"],
        ...data.map(({ semana, quantidade }: { semana: string, quantidade: number }) => [semana, quantidade])
    ]);

    const carregaDashboardDesistenciaPorProfessor = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/DesistenciaPorProfessor?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, setArrayDashDesistenciaPorProfessor, (data) => [
        ["Quantidade de Alunos", "Alunos"],
        ...data.map(({ professor, quantidadeAlunos }: { professor: string, quantidadeAlunos: number }) => [professor, quantidadeAlunos])
    ]);

    const carregaDashboardLinhaSazonalidade = (dataInicial: string, dataFinal: string) => apiCall<any[]>(`api/Metricas/DashboardLinhaSazonalidade/${dataInicial}/${dataFinal}`, setArrayDashLinhaSazonalidade, (data) => [
        ["Quantidade de Alunos", "Alunos"],
        ...data.map(({ dia, quantidade }: { dia: number, quantidade: number }) => [`Dia ${dia}`, quantidade])
    ]);

    const carregaFechamentoMatriculaPorSemana = (dataInicial: string, dataFinal: string) => {
        apiCall<GetAllUserResponse[]>(`api/Metricas/FechamentosEMatriculasPorSemana/${dataInicial}/${dataFinal}`, setRowData, (data) => data.length > 0 ? data : []);
    };
    return (
        <>
            { (decodedToken?.role === "Administrador" || decodedToken?.role === "Coordenador" || decodedToken?.role === "Suporte" || decodedToken?.role === "Vendedor") && !senhaAtualizadaComSucesso && !ehSenhaAlterada ? <PlataformaBloqueadaNecessitaAtualizarSenha /> : null} 
            <Flex flexDirection={{ base: 'column', md: 'row' }} gap={4}>
                <Box>
                    Selecione o período de exibição
                    <RadioGroup onChange={setTipoFiltro} value={tipoFiltro}>
                        <Stack direction="column">
                            <Radio value='1'>Este mês</Radio>
                            <Radio value='2'>Este ano</Radio>
                            <Radio value='3'>Desde o início</Radio>
                            <Radio value='4'>Personalizado</Radio>
                        </Stack>
                    </RadioGroup>
                </Box>
                {tipoFiltro === '4' && (
                    <Box marginLeft={{ base: "0", md: "2%" }} gap={2}>
                        Filtrar por Data
                        <Flex flexDirection="column" gap={2}>
                            <Flex w="100%">
                                <Text minW="50px" maxW="50px" textAlign="right">Início:</Text>
                                <Input type="date" value={dataInicial} onChange={(e) => setDataInicial(e.currentTarget.value)} />
                            </Flex>
                            <Flex w="100%">
                                <Text minW="50px" maxW="50px" textAlign="right">Fim:</Text>
                                <Input type="date" value={dataFinal} onChange={(e) => setDataFinal(e.currentTarget.value)} />
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Flex>

            <br />

            {!isLoading ? (
                arrayDashLinha.length > 0 && arrayDashPizza.length > 0 && arrayDashDeOndeVeio.length > 0 ? (
                    <>
                        <Box textAlign="center" p={4}>
                            <Flex
                                direction={{ base: 'column', lg: 'row' }}
                                justify="space-around"
                                align="flex-start"
                                wrap="wrap"
                            >
                                <Clientes ativos={alunosAtivos} alunosAtivoEsseMes={alunosAtivoEsseMes}/>
                                <Informacoes pausados={alunosPausados} inadimplentes={alunosInadimplentes} desistentes={alunosDesistentes} />
                                {decodedToken?.role === "Administrador" && (
                                    <Faturamento fechado={formatCurrency(valorFechado)} salarioProf={formatCurrency(valorAgrupadoSalarioProfessor)} faturamento={formatCurrency(valorFaturamento)} />
                                )}
                            </Flex>
                        </Box>

                        <Flex direction="column" gap={4}>
                            <Chart chartType="ColumnChart" data={arrayDashDeOndeVeio} options={options4} width="100%" height="400px" />
                            <Flex wrap="wrap">
                                <Chart chartType="PieChart" data={arrayDashPizza} options={options} width="100%" height="400px" />
                                <Chart chartType="ColumnChart" data={arrayDashLinha} options={options2} width="100%" height="400px" />
                            </Flex>
                            <Chart chartType="ColumnChart" data={arrayDashLinhaSazonalidade} options={options3} width="100%" height="400px" />
                            <Chart chartType="ColumnChart" data={arrayMotivo} options={options5} width="100%" height="400px" />
                            <Chart chartType="ColumnChart" data={arrayDashDesistenciaPorProfessor} options={options6} width="100%" height="400px" />
                        </Flex>

                        <Box p={5}>
                            <Center><Text><b>FECHAMENTO E MATRICULAS POR SEMANA</b></Text></Center>
                            <DataGrid rowData={rowData} isLoading={isLoading} isError={isError} />
                        </Box>
                    </>
                ) : null
            ) : (
                <Center mt={48} flexDirection="column">
                    <CircularProgress isIndeterminate color="secondary.500" />
                    <Text>Carregando Tabela...</Text>
                </Center>
            )}
        </>
    );
};