import {
  ChakraProvider,
  useMediaQuery,
  Grid,
  Box,
} from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { decodeToken, useJwt } from "react-jwt";
import Topbar from "./components/Topbar";
import NotificacaoPendencia from "./components/Topbar/NotificaticaoPendencia";
import { ThemeContext } from "./contexts/ThemeContext";
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Router from "./router"
import { api } from "./services/api";
import IndexSidebar from "./components/Sidebar";
import { SenhaAtualizadaProvider } from "./contexts/VerificaSeUsuarioAtualizouSenha";
import FestivityModal from "./components/FestivityModal";
import { FestivityProvider } from "./contexts/FestivityContext";
import FestivityBreakModal from "./components/FestivityBreakModal";

export function App() {
  const [cookies] = useCookies(["loginToken"]);
  const { theme } = useContext(ThemeContext);
  const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
  const [pendencia, setPendencia] = useState(false);


  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (cookies.loginToken) {
      if (decodedToken && decodedToken?.role === 'Professor') {
        api.get(`/api/Pendencia/RetornaExistePendenciaParaProfessor?teacher_Id=${decodedToken?.TeacherId}`)
          .then((response) => {
            setPendencia(response.data.object)
          })
      }
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    };
  }, [cookies, decodedToken]);

  return (
    <ChakraProvider theme={theme}>
      <FestivityProvider>
        <SenhaAtualizadaProvider >
          {loggedIn && (
            <>
              <Topbar />
              {
                pendencia && decodedToken?.role === 'Professor' ?
                  <NotificacaoPendencia />
                  :
                  null
              }
            </>
          )}

          <Grid h="100%" gridTemplateColumns={loggedIn ? ["100%", "100%", "15% 85%"] : "100%"} w="100%">
            {loggedIn && (
              <>
                <FestivityModal />
                <FestivityBreakModal />
                <IndexSidebar />
              </>
            )}
            <Router />
          </Grid>
        </SenhaAtualizadaProvider>
      </FestivityProvider>
    </ChakraProvider>
  );
};