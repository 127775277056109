import { useEffect, useState } from "react";
import { api } from "../../services/api";
import DataGrid from "../../components/DataGrid";
import TeacherModal from "../../components/DataGrid/AddModals/TeacherModal";
import { Box, HStack, VStack, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { formatDate } from "../../utils/formatter";
import StudentModal from "../../components/DataGrid/AddModals/StudentModel";
import StudentImportModal from "../../components/DataGrid/AddModals/StudentImportModel";


export function StudentConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<object[]>([]);
    const [tick, setTick] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const stackDirection = useBreakpointValue<"row" | "column">({ base: 'column', md: 'row' });

    useEffect(() => {
        setIsError(() => false);
        setIsLoading(() => true);

        if(decodedToken && decodedToken?.role === 'Administrador'){
            RetornaDadosEstudantes();
        }
        else if(decodedToken && decodedToken?.role !== 'Administrador') {
            RetornaDadosEstudantesMascarados();
        }
    }, [decodedToken, tick]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    const RetornaDadosEstudantes = () => {
        api.get('api/EstudanteComHistoricoEProfessoresVinculados')
            .then((response) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        ...row,
                        situacaoDoEstudante: row.situacaoDoEstudante,
                        temDobradinha: row.temDobradinha == true ? "SIM" : "NÃO", 
                        dataDeNascimento: formatDate(row.dataDeNascimento, false),
                        dataInicioAulas: formatDate(row.dataInicioAulas, false),
                        dataFechamentoContrato: formatDate(row.dataFechamentoContrato, false),
                        dataDaUltimaAtualizacaoDeStatus: formatDate(row.dataDaUltimaAtualizacaoDeStatus, false)
                    };
                }));
            })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false))
    }

    const RetornaDadosEstudantesMascarados = () => {
        api.get('api/ObterAlunosCargoDiferenteADM')
            .then((response) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        ...row,
                        situacaoDoEstudante: row.situacaoDoEstudante,
                        temDobradinha: row.temDobradinha == true ? "SIM" : "NÃO", 
                        dataDeNascimento: formatDate(row.dataDeNascimento, false),
                        dataInicioAulas: formatDate(row.dataInicioAulas, false),
                        dataFechamentoContrato: formatDate(row.dataFechamentoContrato, false),
                        dataDaUltimaAtualizacaoDeStatus: formatDate(row.dataDaUltimaAtualizacaoDeStatus, false)
                    };
                }));
            })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false))
    }

    return (
        <VStack spacing={4} align="stretch" width="100%">
            <HStack
                mb={4}
                spacing={4}
                flexDirection={stackDirection}
                width="100%"
                align="stretch"
            >
                {decodedToken?.role === 'Vendedor' && (
                    <StudentModal modalType="new" updateTick={updateTick} />
                )}
            </HStack>
            <Box width="100%">
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={decodedToken?.role === 'Administrador'}
                    addModal={<StudentModal modalType="new" updateTick={updateTick} />}
                    icons={['Editar']}
                    updateTick={updateTick}
                />
            </Box>
        </VStack>
    );
};