import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import PatternCard from "../../components/Patterns/Card";
import { TeacherConfig } from "./TeacherConfig";
import UserConfig from "./UserConfig";
import { useJwt } from "react-jwt";
import { useCookies } from "react-cookie";

export default function UsersGroups() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    return (
        <PatternCard title="Usuários">
            <Helmet>
                <title>King | Usuários</title>
            </Helmet>
            <Tabs>
                <TabList>
                    {
                        decodedToken?.role === 'Coordenador' ? 
                        <>
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Usuários</Tab>
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Professores</Tab>
                        </>
                        :
                        decodedToken?.role === 'Administrador' ?
                        <>
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Usuários</Tab>
                            <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Professores</Tab>
                        </>
                        :
                        null
                    }
                </TabList>
                    {
                        decodedToken?.role === 'Coordenador' ? 
                        <TabPanels>
                            <TabPanel>
                                <UserConfig />
                            </TabPanel>
                            <TabPanel>
                                <TeacherConfig />
                            </TabPanel>
                        </TabPanels>
                        :
                        decodedToken?.role === 'Administrador' ?
                        <TabPanels>
                            <TabPanel>
                                <UserConfig />
                            </TabPanel>
                            <TabPanel>
                                <TeacherConfig />
                            </TabPanel>
                        </TabPanels>
                        :
                        null
                    }                  
                
            </Tabs>
        </PatternCard>
    );
};