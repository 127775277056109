import { Button, Center, FormControl, FormLabel, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import { api } from "../../../services/api";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Select, { MultiValue, SingleValue } from 'react-select';
import PatternSelect from "../../Patterns/Select";
import moment from "moment";
import PatternMultipleSellect from "../../Patterns/MultipleSelect";

export default function TurmasModelAddEdit (props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [teachers, setTeachers] = useState<Teachers[]>([]);
    const [usuarios, setUsuarios] = useState<UserSelectBox[]>([]);
    const [estudantes, setEstudantes] = useState<Student[]>([]);

    const [professorPrincipal, setProfessorPrincipal] = useState<SingleValue<{ value: number; label: string }>>();
    const [professorPrincipalId, setProfessorPrincipalId] = useState<number>(0);

    const [professorSubstituto, setProfessorSubstituto] = useState<SingleValue<{ value: number; label: string }>>();
    const [professorSubstitutoId, setProfessorSubstitutoId] = useState<number>(0);

    const [estudantesSelecionados, setEstudantesSelecionados] = useState<MultiValue<{ value: number; label: string }>>([]);

    const [usuario, setUsuario] = useState<SingleValue<{ value: string; label: string }>>();
    const [usuarioId, setUsuarioId] = useState<string>("");

    const [nomeTurma, setNomeTurma] = useState<string>("");
    const [descricaoTurma, setDescricaoTurma] = useState<string>("");
    const [tipoTurma, setTipoTurma] = useState<number>(0);
    const [planoTurma, setPlanoTurma] = useState<number>(0);
    const [outroPlanoTurma, setOutroPlanoTurma] = useState<string>("");
    const [nivelDaTurma, setNivelDaTurma] = useState<string>("");
    const [horarioTurma, setHorarioTurma] = useState<string>("");
    const [dataInicioTurma, setDataInicioTurma] = useState<string>(moment().format('YYYY-MM-DD'));
    const [DayWeek, setDayWeek] = useState<string[]>([]);

    var diasSemana = [
        {
            label: "SEGUNDA-FEIRA",
            value: "segunda"
        },
        {
            label: "TERÇA-FEIRA",
            value: "terca"
        },
        {
            label: "QUARTA-FEIRA",
            value: "quarta"
        },
        {
            label: "QUINTA-FEIRA",
            value: "quinta"
        },
        {
            label: "SEXTA-FEIRA",
            value: "sexta"
        },
        {
            label: "SABADO",
            value: "sabado"
        },
        {
            label: "DOMINGO",
            value: "domingo"
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        carregarDadosBase();
        popularDadosParaEdicao();
    }, [props, decodedToken])

    const abrirModal = () => {
        carregarDadosBase();
        onOpen();
    }

    
    const handleChangeDiaAula = (event : MultipleSelect) =>{
        var dias = [""];

        dias = dias.filter((b) => b !== "");

        event.map((obj) => {
            dias.push(obj.value);
        });

        setDayWeek(dias);
    }

    const carregarDadosBase = () => {
        if(decodedToken) {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeachers(response.data.object);
            })   
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(false))
            
            api.get("/api/ObterUsuariosSelectBox")
            .then((response) => {
                setUsuarios(response.data.object.filter((user:UserSelectBox) => user.role === "Coordenador"));
            })   
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(false))

            if(props.modalType === 'new'){
                api.get("/api/StudentSelectBox")
                .then((response) => {
                    setEstudantes(response.data.object.filter((estudante:Student) => estudante.situacaoDoEstudante === "ATIVO"));
                })   
                .catch(() => setIsError(() => true))
                .finally(() => setIsLoading(false))
            }
        }
    }

    const handleEdit = () => {
        setIsLoading(true);
        const alunosNaTurma = estudantesSelecionados.map(estudante => ({
            idAluno: estudante.value
        }));
        
        var diasDeAulaSemAlteracaoArray = props?.data.diaDeAulasDaTurma.split(",");

        const data = {
            id: props.data.id,
            nome: nomeTurma,
            descricao: descricaoTurma,
            tipoTurma,
            planoDaTurma: planoTurma,
            outrosPlanos: planoTurma === 6 ? outroPlanoTurma : null,
            nivel: nivelDaTurma,
            hora: horarioTurma,
            coordenadorId: usuarioId,
            professorPrincipalId: professorPrincipalId,
            professorSubstitutoId: professorSubstitutoId,
            dataInicioTurma: moment(dataInicioTurma).format('YYYY-MM-DD'),
            diaDeAulasDaTurma: DayWeek.length > 0 ? DayWeek : diasDeAulaSemAlteracaoArray
        };

        api.put('/api/turmas/EditarTurma', data)
            .then(response => {
                toast.success('Turma Editada com sucesso!');
                onClose();
                props?.updateTick();
            })
            .catch(error => {
                toast.error('Erro ao editar turma');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSubmit = () => {
        setIsLoading(true);
        const alunosNaTurma = estudantesSelecionados.map(estudante => ({
            idAluno: estudante.value
        }));

        const data = {
            nome: nomeTurma,
            descricao: descricaoTurma,
            tipoTurma,
            planoDaTurma: planoTurma,
            outrosPlanos: planoTurma === 6 ? outroPlanoTurma : null,
            nivel: nivelDaTurma,
            hora: horarioTurma,
            coordenadorId: usuarioId,
            professorPrincipalId: professorPrincipalId,
            professorSubstitutoId: professorSubstitutoId,
            dataInicioTurma: moment(dataInicioTurma).format('YYYY-MM-DD'),
            alunosNaTurma: alunosNaTurma,
            diaDeAulasDaTurma: DayWeek
        };

        api.post('/api/turmas/SalvarNovaTurma', data)
            .then(response => {
                toast.success('Turma criada com sucesso!');
                onClose();
                props?.updateTick();
            })
            .catch(error => {
                toast.error('Erro ao criar turma');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const popularDadosParaEdicao = () => {
        if(props?.data && teachers.length > 0 && usuarios.length > 0) {              
            const dataFormatada = moment(props?.data?.dataInicioTurma, 'DD/MM/YYYY', true).format('YYYY-MM-DD');
            const professorPrincipal = teachers.find((teacher: Teachers) => teacher.nome === props?.data?.professorPrincipal);
            const professorSubstituto = teachers.find((teacher: Teachers) => teacher.nome === props?.data?.professorSubstituto);
            const coordenador = usuarios.find((teacher: UserSelectBox) => teacher.nome === props?.data?.coordenador);
            var planoTurma = -1;

            switch(props?.data.plano){
                case 'Uma Aula por Semana':
                    planoTurma = 0;
                    break;
                case 'Quatro Aulas por Semana':
                    planoTurma = 1;
                    break;
                case 'Quatro Aulas de Trinta Minutos':
                    planoTurma = 2;
                    break;
                case 'Cinco Aulas de Trinta Minutos':
                    planoTurma = 3;
                    break;
                case 'Quatro Aulas de Sessenta Minutos':
                    planoTurma = 4;
                    break;
                case 'Cinco Aulas de Sessenta Minutos':
                    planoTurma = 5;
                    break;
                default:
                    planoTurma = 6;
                    break;
            }



            setNomeTurma(props?.data.nome);
            setDescricaoTurma(props?.data.descricao);
            setTipoTurma(props?.data.tipoTurma === "Privada(Amigos)" ? 0 : 1);
            setPlanoTurma(planoTurma);
            setOutroPlanoTurma(props?.data.plano);
            setNivelDaTurma(props?.data.nivel);
            setHorarioTurma(props?.data.hora);
            setDataInicioTurma(dataFormatada);

            if (professorPrincipal && professorPrincipal.id) {
                setProfessorPrincipal({ value: professorPrincipal.id, label: professorPrincipal.nome });
                setProfessorPrincipalId(professorPrincipal.id);
            }

            if (professorSubstituto && professorSubstituto.id) {
                setProfessorSubstituto({ value: professorSubstituto.id, label: professorSubstituto.nome });
                setProfessorSubstitutoId(professorSubstituto.id);
            }

            if (coordenador && coordenador.id) {
                setUsuario({ value: coordenador.id, label: coordenador.nome });
                setUsuarioId(coordenador.id);
            }
        }
    }

    return (
        <>
            {
                props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={abrirModal} /> 
                : 
                <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Criar Turma' : 'Editar Turma'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Nome da Turma</p>
                        <PatternInput
                            type="text"
                            id="turma-name"
                            name="turma-name"
                            value={nomeTurma}
                            onChange={(event) => setNomeTurma(event.currentTarget.value)}
                        />
                        <p>Descrição da Turma</p>
                        <PatternInput
                            type="text"
                            id="turma-descricao"
                            name="turma-descricao"
                            value={descricaoTurma}
                            onChange={(event) => setDescricaoTurma(event.currentTarget.value)}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Tipo da Turma</FormLabel>
                            <PatternSelect 
                                value={tipoTurma}
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTipoTurma(Number(event.currentTarget.value))}
                            >
                                <option key={1} value={0}>Privada(Amigos)</option>
                                <option key={2} value={1}>Grupo</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Plano da Turma</FormLabel>
                            <PatternSelect 
                                value={planoTurma}
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setPlanoTurma(Number(event.currentTarget.value))}
                            >
                                {
                                    tipoTurma === 0 ?
                                    <>
                                        <option key={3} value={2}>Quatro Aulas de Trinta Minutos</option>
                                        <option key={4} value={3}>Cinco Aulas de Trinta Minutos</option>
                                        <option key={5} value={4}>Quatro Aulas de Sessenta Minutos</option>
                                        <option key={6} value={5}>Cinco Aulas de Sessenta Minutos</option>
                                        <option key={7} value={6}>Outro</option>
                                    </> 
                                    :
                                    <>
                                        <option key={8} value={0}>Uma Aula por Semana</option>
                                        <option key={9} value={1}>Quatro Aulas por Semana</option>
                                    </>
                                }
                            </PatternSelect>
                            {
                                planoTurma === 6 ?
                                <>
                                    <p>Outro Plano</p>
                                    <PatternInput
                                        type="text"
                                        id="turma-outroplano"
                                        name="turma-outroplano"
                                        value={outroPlanoTurma}
                                        onChange={(event) => setOutroPlanoTurma(event.currentTarget.value)}
                                    />
                                </>
                                :
                                null
                            }
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Nivel da Turma</FormLabel>
                            <PatternSelect 
                                value={nivelDaTurma}
                                onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setNivelDaTurma(event.currentTarget.value)}
                            >
                                <option key={1} value={"A1"}>A1</option>
                                <option key={2} value={"A2"}>A2</option>
                                <option key={3} value={"B1"}>B1</option>
                                <option key={4} value={"B2"}>B2</option>
                                <option key={5} value={"C1"}>C1</option>
                                <option key={6} value={"A2"}>C2</option>
                            </PatternSelect>
                        </FormControl>
                        <p>Horário</p>
                        <PatternInput
                            type="time"
                            id="turma-horario"
                            name="turma-horario"
                            value={horarioTurma}
                            onChange={(event) => setHorarioTurma(event.currentTarget.value)}
                        />
                        
                        <FormControl>
                            <FormLabel fontWeight="bolder">Professor Principal</FormLabel>
                            <Select
                                id="teacherPrincipal"
                                name="teacherPrincipal"
                                placeholder="Escolha um professor principal..."
                                noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                value={professorPrincipal}
                                onChange={(event) => { setProfessorPrincipal(event); setProfessorPrincipalId(Number(event?.value)) }}
                                options={teachers.map(t => { return { value: t.id, label: t.nome } })}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight="bolder">Professor Substituto</FormLabel>
                            <Select
                                id="teacherSubstituto"
                                name="teacherSubstituto"
                                placeholder="Escolha um professor substituto..."
                                noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                value={professorSubstituto}
                                onChange={(event) => { setProfessorSubstituto(event); setProfessorSubstitutoId(Number(event?.value)) }}
                                options={teachers.map(t => { return { value: t.id, label: t.nome } })}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight="bolder">Coordenador</FormLabel>
                            <Select
                                id="coordenador"
                                name="coordenador"
                                placeholder="Escolha um Coordenador..."
                                noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                                value={usuario}
                                onChange={(event) => { 
                                    if (event) {
                                        setUsuario(event); 
                                        setUsuarioId(event.value);
                                    } else {
                                        setUsuario(null);
                                        setUsuarioId("");
                                    }
                                }}
                                options={usuarios.map(t => { return { value: t.id, label: t.nome } })}
                            />
                        </FormControl>
                        {
                            props.modalType === 'new' ? 
                            <FormControl>
                                <FormLabel fontWeight="bolder">Novos Alunos na Turma</FormLabel>
                                <Select
                                    id="alunos"
                                    name="alunos"
                                    placeholder="Escolha os Alunos a serem adicionados..."
                                    noOptionsMessage={() => 'Nenhuma correspondência.'}
                                    isMulti
                                    value={estudantesSelecionados}
                                    onChange={(selectedOptions) => { 
                                        setEstudantesSelecionados(selectedOptions as MultiValue<{ value: number; label: string }>); 
                                    }}
                                    options={estudantes.map(t => ({ value: t.id, label: t.nome }))}
                                />
                            </FormControl>
                            :  null
                        }
                        <p>Dias de Aula na Semana do Aluno</p>
                        <PatternMultipleSellect 
                            onChange={(event:MultipleSelect) => {handleChangeDiaAula(event)}}
                            options={diasSemana.map((diaSemana) => {
                            const data = {
                                    label: diaSemana.label,
                                    value: diaSemana.value
                            } 
                            return data;
                            })}
                        />
                        {props.modalType === 'edit' ? 
                            <>
                                <p>Dias de Aula na Semana</p>
                                <PatternInput
                                    type="text"
                                    id="student-DayWeek"
                                    isDisabled={true}
                                    name="student-DayWeek"
                                    value={props?.data.diaDeAulasDaTurma}
                                />
                                
                            </>
                            :
                            null
                        }
                        
                        <p>Data de Início da Turma</p>
                        <PatternInput
                            type="date"
                            id="turma-dataInicio"
                            name="turma-dataInicio"
                            value={dataInicioTurma}
                            onChange={(event) => setDataInicioTurma(event.currentTarget.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props.modalType === 'new' ? handleSubmit : handleEdit} >Salvar</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}