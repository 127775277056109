import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { formatDate } from "../../utils/formatter";
import PatternSelect from "../../components/Patterns/Select";

interface GetAllUserResponse {
    id_Professor: Number;
    nomeProfessor: string;
    valorAPagar: string;
    quantidadeAulas: number;
    mes: string;
};

interface GetTeacherResponse {
    nome: string;
    id: Number;
};

export default function PendenciasProfessores() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [teacher, setTeacher] = useState([]);
    const [teacherSelecionado, setTeacherSelecionado] = useState(0);
    const [pendencias, setPendencias] = useState<GetTeacherResponse[]>([]);

    useEffect(() => {
        if(decodedToken && decodedToken?.role === "Professor"){
            retornaPendenciasProfessor(decodedToken?.TeacherId);
        }
        else if(decodedToken && decodedToken?.role !== "Professor"){
            api.get("/api/TeacherSelectBox").then((response) =>{
                setTeacher(response.data.object)
            })
            .catch(() => setIsError(() => true))
            .finally();

            retornaPendenciasProfessor(teacherSelecionado);
        }
    }, [decodedToken, teacherSelecionado]);

    const retornaPendenciasProfessor = (id: Number) => {
        api.get(`/api/Pendencia/RetornaPendenciasPorProfessor?teacher_Id=${id}`).then((response) => {
            setRowData(response.data.object.map((row: any) => {
                return {
                    ...row,
                    data_Pendencia: formatDate(row.data_Pendencia)
                }
            }));
        })
        .catch(() => setIsError(() => true))
        .finally(() => setIsLoading(() => false));

        setIsLoading(() => false);
        setIsError(() => false);
        // setRowData(() => []);
    }

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>  
        {
            decodedToken && decodedToken?.role !== "Professor" ?
            <>
                <FormLabel htmlFor="initial-date" fontWeight="bolder">Selecione um professor para verificar suas pendências</FormLabel>
                <PatternSelect 
                    placeholder='Professores' 
                    value={teacherSelecionado} 
                    onChange={(event : React.ChangeEvent<HTMLSelectElement>) => setTeacherSelecionado(Number(event.currentTarget.value))}
                >
                    {
                        teacher.map((obj:GetTeacherResponse) => (
                            <option key={Number(obj.id)} value={Number(obj.id)}>{obj.nome}</option>
                        ))
                    }
                </PatternSelect>
            </>
            :
            null
        }      
            <Box mt={6}>
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    enableExport={true}
                    updateTick={updateTick}
                />
            </Box>
        </>   
    );
};