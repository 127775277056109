import React from 'react';

const Legend = () => {
  return (
    <div style={{
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <h3 style={{ fontSize: '16px', margin: '0', color: '#333' }}>Prioridade:</h3>
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{ width: '12px', height: '12px', backgroundColor: 'red', borderRadius: '50%' }}></div>
          <span>Prioridade Max.</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{ width: '12px', height: '12px', backgroundColor: 'yellow', borderRadius: '50%' }}></div>
          <span>Prioridade</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{ width: '12px', height: '12px', backgroundColor: 'blue', borderRadius: '50%' }}></div>
          <span>Normal</span>
        </div>
      </div>
    </div>
  );
};

export default Legend;
