import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useJwt } from "react-jwt";
import PatternCard from "../../components/Patterns/Card";
import MetasConfig from "./Metas";
import NovosAlunosConfig from "./NovosAlunos";
import NovosAlunosExperimentaisConfig from "./NovosAlunosExperimentais";


export function NovosAlunos() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    
    return (
        <>
            <Helmet>
                <title>King | Novos Alunos</title>
            </Helmet>
            <PatternCard title="Novos Alunos">
                <Tabs>
                    <TabList>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>NOVOS ALUNOS</Tab>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>AULAS EXPERIMENTAIS</Tab>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>METAS</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <NovosAlunosConfig />
                        </TabPanel>
                        <TabPanel>
                            <NovosAlunosExperimentaisConfig />
                        </TabPanel>
                        <TabPanel>
                            <MetasConfig />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </PatternCard>
        </>
    );
};