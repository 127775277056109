import { IconType } from "react-icons/lib";
import { AiOutlineSchedule, AiFillHome, AiFillPieChart, AiFillAccountBook } from "react-icons/ai";
import { MdClass, MdPeople, MdCoPresent } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
import { RiDashboard3Line } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { AiOutlineCalendar } from "react-icons/ai";
import { PiStudent } from "react-icons/pi";

export interface NavConfig {
    id: number;
    name: string;
    path: string;
    icon: IconType;
    children?: NavConfig[];
}

export const navConfig: NavConfig[] = [
    {
        id: 1,
        name: 'Home',
        path: '/home',
        icon: AiFillHome
    },
    {
        id: 2,
        name: 'Configuração Hora/Aula',
        path: '/configurationHour',
        icon: AiFillPieChart,
    },
    {
        id: 8,
        name: 'Aulas',
        path: '/historicClass',
        icon: MdClass,
    },
    {
        id: 9,
        name: 'Calendário de Aulas',
        path: '/calendar',
        icon: AiOutlineCalendar,
    },
    {
        id: 5,
        name: 'Reposição',
        path: '/reposition',
        icon: AiOutlineSchedule,
    },
    {
        id: 7,
        name: 'Novos Alunos',
        path: '/novosAlunos',
        icon: SiGoogleclassroom,
    },
    {
        id: 10,
        name: 'Alunos que Começam em Breve',
        path: '/alunosComecamEmBreve',
        icon: AiFillAccountBook,
    },
    {
        id: 3,
        name: 'Configuração Aluno/Professor',
        path: '/configurationTeacherWithStudent',
        icon: GiTeacher,
    },
    {
        id: 4,
        name: 'Usuários',
        path: '/users-groups',
        icon: MdPeople,
    },
    {
        id: 10,
        name: 'Alunos',
        path: '/alunos',
        icon: PiStudent,
    }
];