import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCheckSquare, FaPencilAlt, FaSquare } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button"
import PatternInput from "../../Patterns/Input";
import { formatCep, formatCpfCnpj, formatDate } from "../../../utils/formatter";
import moment from "moment";
import PatternSelect from "../../Patterns/Select";
import { FestivityContext } from "../../../contexts/FestivityContext";


export default function TeacherModal(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { actualYear } = useContext(FestivityContext);

    const [teacherName, setTeacherName] = useState<string>('');
    const [age, setAge] = useState<Number>(0);
    const [CNPJ, setCNPJ] = useState<string>("");
    const [titularityName, setTitularityName] = useState<string>('');
    const [NameBank, setNameBank] = useState<string>('');
    const [CodeAgency, setCodeAgency] = useState<Number>(0);
    const [DigitAgency, setDigitAgency] = useState<Number>(0);
    const [CountChain, setCountChain] = useState<string>("");
    const [DigitCount, setDigitCount] = useState<Number>(0);
    const [NameFavored, setNameFavored] = useState<string>("");
    const [CPF, setCPF] = useState<string>("");
    const [Endereco, setEndereco] = useState<string>("");
    const [Numero, setNumero] = useState<string>("");
    const [Complemento, setComplemento] = useState<string>("");
    const [CEP, setCEP] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [Email, setEmail] = useState<string>("");
    const [Pix, setPix] = useState<string>("");
    const [value_HourPerClass, setValue_HourPerClass] = useState<string>("");
    const [Dt_Birth, setDt_Birth] = useState<string>(moment().format('YYYY-MM-DD'));
    const [tipoPagamento, setTipoPagamento] = useState<number>(0);
    const [festivityBreak, setFestivityBreak] = useState<boolean | null | undefined>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props?.modalType === 'edit') {
            setTeacherName(props?.data?.nome);
            setAge(props?.data?.idade);
            setCNPJ(props?.data?.cnpj);
            setTitularityName(props?.data?.NomeTitularidade);
            setNameBank(props?.data?.nomeBanco);
            setCodeAgency(props?.data?.codigoAgencia);
            setDigitAgency(props?.data?.digitoAgencia);
            setCountChain(props?.data?.contaCorrente);
            setDigitCount(props?.data?.digitCount);
            setNameFavored(props?.data?.nameFavored);
            setCPF(props?.data?.cpf);
            setEndereco(props?.data?.endereco);
            setNumero(props?.data?.numero);
            setComplemento(props?.data?.complemento)
            setCEP(props?.data?.cep);
            setEmail(props?.data?.email);
            setPix(props?.data?.pix);
            setDt_Birth(moment(props?.data?.dataAniversario, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            setValue_HourPerClass(props?.data?.valorPorHoraAula);
            setTelefone(props?.data?.telefone);
            setTipoPagamento(props?.data?.tipoSalarioProfessor);
            setFestivityBreak(props?.data?.pausaFinalDeAno === 'Não definiu' ? undefined : props?.data?.pausaFinalDeAno === 'Irá pausar');
        }
    }, [isOpen, props]);

    const handleAddTeacher = async () => {
        if (teacherName === '') {
            toast.warning("O campo nome está vazio");
            return;
        };

        if (CNPJ === '') {
            toast.warning("O campo CNPJ está vazio");
            return;
        };

        if (CPF === '') {
            toast.warning("O campo CPF está vazio");
            return;
        };

        if (Pix === '') {
            toast.warning("O campo PIX está vazio");
            return;
        };

        if (Dt_Birth === '') {
            toast.warning("O campo Data de Nascimento está vazio");
            return;
        };

        if (telefone === '') {
            toast.warning("O campo Telefone está vazio");
            return;
        };

        try {
            setIsLoading(() => true);

            const data = {
                Name: teacherName,
                Age: age,
                CNPJ: CNPJ,
                TitularityName: titularityName,
                NameBank: NameBank,
                CodeAgency: CodeAgency,
                DigitAgency: DigitAgency,
                CountChain: CountChain,
                DigitCount: DigitCount,
                NameFavored: NameFavored,
                CPF: CPF,
                Email: Email,
                Pix: Pix,
                Dt_Birth: Dt_Birth,
                value_HourPerClass: Number(value_HourPerClass),
                Telefone: telefone,
                TipoSalarioProfessor: tipoPagamento
            };

            await api.post('api/Teacher', data);

            toast.success("Professor criado com sucesso!");
            props?.updateTick();
        } catch (err: any) {
            if (err.response.data.message === "Usuario ja existente")
                toast.error("Professor Já Existente");
            else
                toast.error("Um erro ocorreu ao cadastrar este Professor!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleEditTeacher = async () => {
        if (teacherName === '') {
            toast.warning("O campo nome está vazio");
            return;
        };


        if (CNPJ === '') {
            toast.warning("O campo CNPJ está vazio");
            return;
        };

        if (telefone === '') {
            toast.warning("O campo Telefone está vazio");
            return;
        };

        if (CPF === '') {
            toast.warning("O campo CPF está vazio");
            return;
        };

        if (Pix === '') {
            toast.warning("O campo PIX está vazio");
            return;
        };

        if (Dt_Birth === '') {
            toast.warning("O campo Data de Nascimento está vazio");
            return;
        };

        try {
            setIsLoading(() => true);
            const data = {
                Id: props?.data?.id,
                Name: teacherName,
                Age: age,
                CNPJ: CNPJ,
                TitularityName: titularityName,
                NameBank: NameBank,
                CodeAgency: CodeAgency,
                DigitAgency: DigitAgency,
                CountChain: CountChain,
                DigitCount: DigitCount,
                NameFavored: NameFavored,
                CPF: CPF,
                Email: Email,
                Pix: Pix,
                Dt_Birth: Dt_Birth,
                value_HourPerClass: Number(value_HourPerClass),
                Telefone: telefone,
                TipoSalarioProfessor: tipoPagamento
            };

            await api.put('api/Teacher', data);

            toast.success("Professor alterado com sucesso!");
            props?.updateTick();
        } catch (err: any) {
            if (err.response.data.message === "Usuario nao encontrado")
                toast.error("Professor não encontrado");
            else
                toast.error("Um erro ocorreu ao cadastrar este Professor!");
        } finally {
            setIsLoading(() => false);
        };
    };

    const handleClose = () => {
        setTeacherName('');
        setAge(0);
        onClose();
    };

    const handleChangeFestivityDecision = async () => {
        if (festivityBreak !== undefined && festivityBreak !== null) {
            const data = {
                ProfessorId: props?.data?.id,
                AnoDecisao: actualYear,
                DecisaoPausar: !festivityBreak,
            };

            await api.post('/api/FestivityDecision', data);
            setFestivityBreak(!festivityBreak);

            if (props?.updateTick) props?.updateTick();
            toast.success(`Você definiu que o professor ${props?.data?.nome} ${!festivityBreak === true ? 'irá pausar no final deste ano' : 'não irá pausar no final deste ano'}.`);
        };
    };

    return (
        <>
            {props.modalType === 'edit' ? <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} /> : <PatternButton icon={IoMdAdd} onClick={onOpen}>Adicionar</PatternButton>}

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar Novo Professor' : 'Alterar Professor'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <p>Nome do Professor</p>
                        <PatternInput
                            type="text"
                            id="teacher-name"
                            name="teacher-name"
                            placeholder="Nome do Professor..."
                            value={teacherName}
                            onChange={(event) => setTeacherName(event.currentTarget.value)}
                        />
                        <p>CNPJ do Professor</p>
                        <PatternInput
                            id="CNPJ"
                            name="CNPJ"
                            placeholder="CNPJ do Professor..."
                            value={CNPJ}
                            maxLength={18}
                            onChange={(event) => setCNPJ(formatCpfCnpj(event.currentTarget.value))}
                        />
                        <p>Telefone do Professor</p>
                        <PatternInput
                            id="Telefone"
                            name="Telefone"
                            placeholder="Telefone do Professor..."
                            value={telefone}
                            maxLength={12}
                            onChange={(event) => setTelefone(event.currentTarget.value)}
                        />
                        <p>Pix</p>
                        <PatternInput
                            id="Pix"
                            name="Pix"
                            placeholder="Pix"
                            value={Pix}
                            onChange={(event) => setPix(event.currentTarget.value)}
                        />
                        <p>Nome Titularidade do Professor</p>
                        <PatternInput
                            id="TitularityName"
                            name="TitularityName"
                            placeholder="Nome da Titularidade do Banco do Professor..."
                            value={titularityName}
                            maxLength={500}
                            onChange={(event) => setTitularityName(event.currentTarget.value)}
                        />
                        <p>Nome do Banco do Professor</p>
                        <PatternInput
                            id="NameBank"
                            name="NameBank"
                            placeholder="Nome do Banco do Professor..."
                            value={NameBank}
                            maxLength={25}
                            onChange={(event) => setNameBank(event.currentTarget.value)}
                        />
                        <p>Numero da Agencia do Banco do Professor</p>
                        <PatternInput
                            id="CodeAgency"
                            name="CodeAgency"
                            placeholder="Numero da Agencia do Banco do Professor"
                            value={Number(CodeAgency)}
                            maxLength={20}
                            onChange={(event) => setCodeAgency(Number(event.currentTarget.value))}
                        />
                        <p>Conta Corrente do Professor</p>
                        <PatternInput
                            id="CountChain"
                            name="CountChain"
                            placeholder="Conta Corrente do Professor"
                            value={CountChain}
                            maxLength={20}
                            onChange={(event) => setCountChain(event.currentTarget.value)}
                        />
                        <p>CPF</p>
                        <PatternInput
                            id="CPF"
                            name="CPF"
                            placeholder="CPF"
                            maxLength={14}
                            value={CPF}
                            onChange={(event) => setCPF(formatCpfCnpj(event.currentTarget.value))}
                        />
                        <p>Email</p>
                        <PatternInput
                            id="Email"
                            name="Email"
                            placeholder="Email"
                            value={Email}
                            onChange={(event) => setEmail(event.currentTarget.value)}
                        />
                        <FormControl>
                            <FormLabel htmlFor="initial-date" fontWeight="bolder">Tipo de Pagamento do Prof</FormLabel>
                            <PatternSelect
                                placeholder='Tipo de Pagamento do Prof'
                                value={tipoPagamento}
                                onChange={(event) => setTipoPagamento(Number(event.currentTarget.value))}
                            >
                                <option key={2} value={1}>TABELA FIXA</option>
                                <option key={1} value={0}>HORA/AULA</option>
                            </PatternSelect>
                        </FormControl>
                        {
                            tipoPagamento === 0 ?
                                <>
                                    <p>Valor da Hora Aula do Professor</p>
                                    <PatternInput
                                        id="value_HourPerClass"
                                        name="value_HourPerClass"
                                        placeholder="Valor da Hora Aula do Professor"
                                        value={value_HourPerClass}
                                        onChange={(event) => setValue_HourPerClass(event.currentTarget.value.replace(",", "."))}
                                    />
                                </>
                                :
                                null
                        }

                        <p>Data de Nascimento do Professor</p>
                        <PatternInput
                            id="Dt_Birth"
                            type="date"
                            name="Dt_Birth"
                            placeholder="Data de Nascimento do Professor"
                            value={Dt_Birth}
                            onChange={(event) => setDt_Birth(event.currentTarget.value)}
                        />

                        {festivityBreak !== undefined && festivityBreak !== null && moment().format('MM') === '12' && props?.modalType === 'edit' && (
                            <Flex as="span" align="center" gap={2} mt={2} mb={2} cursor="pointer" w="15vw" onClick={handleChangeFestivityDecision}>
                                <Icon as={Boolean(festivityBreak) ? FaCheckSquare : FaSquare} color={Boolean(festivityBreak) ? "blue.500" : 'transparent'} border="2px solid" borderColor={Boolean(festivityBreak) ? "transparent" : 'gray.300'} borderRadius={4} fontSize="1.25rem" />
                                <Text>Pausar no fim de {actualYear}</Text>
                            </Flex>
                        )}
                        {(festivityBreak === undefined || festivityBreak === null) && moment().format('MM') === '12' && props?.modalType === 'edit' && <Text mt={2} color="gray" fontStyle="italic">O professor ainda não decidiu se quer pausar no fim de {actualYear}.</Text>}
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={handleClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={props?.modalType === 'new' ? handleAddTeacher : handleEditTeacher}>{props?.modalType === 'new' ? 'Salvar' : 'Editar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};