import { Box, Grid, FormControl, FormLabel, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useJwt } from "react-jwt";
import { toast } from "react-toastify";
import PatternCard from "../../components/Patterns/Card";
import { api } from "../../services/api";
import RepositionConfig from "./Reposition";
import FormularioReposicao from "./FormularioReposicao";

export function AgenderReposition() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    
    return (
        <>
            <Helmet>
                <title>King | Reposição</title>
            </Helmet>
            <PatternCard title="Reposição de Aula do Aluno">
                <Tabs>
                    <TabList>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Agendar Reposição</Tab>
                        <Tab color="primary.500" _hover={{ color: "secondary.500" }}>Formulário Reposição</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <RepositionConfig />
                        </TabPanel>
                        <TabPanel>
                            <FormularioReposicao />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </PatternCard>
        </>
    );
};