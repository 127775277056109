import { useEffect, useState } from "react";
import UserModal from "../../components/DataGrid/AddModals/UserModal";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { formatDate } from "../../utils/formatter";
import { Box } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";

interface GetAllUserResponse {
    ativo: boolean | string;
    lastLoginTime: string;
};

export default function UserConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState<GetAllUserResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);

    useEffect(() => {
        setIsLoading(() => true);
        setIsError(() => false);
        // setRowData(() => []);

        api.get<GetAllUserResponse[]>('api/GetAllUser')
            .then((response : any) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        id: row.id,
                        Nome: row.name,
                        Email: row.email,
                        Role: row.role,
                        ativo: row.ativo ? 'Sim' : 'Não'
                    };
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
    }, [tick]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>
            {rowData.length === 0 && (
                <Box mb={4}> 
                    <UserModal modalType="new" updateTick={updateTick} />
                </Box>
            )}
            <DataGrid
                rowData={rowData}
                isLoading={isLoading}
                isError={isError}
                addModal={<UserModal modalType="new" updateTick={updateTick} />}
                ResetSenha={true}
                icons={decodedToken?.role === 'Administrador' ? ['ResetSenha', 'Editar', 'Deletar'] : []}
                deletePath={"DeleteUser"}
                updateTick={updateTick}
            />
        </>
    );
};