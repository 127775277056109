import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Image,
    Box,
    Text,
    Flex
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button";
import PatternCheckbox from "../../Patterns/Checkbox";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import moment from "moment";
import { useJwt } from "react-jwt";
import { useCookies } from "react-cookie";
import { IoEyeSharp } from "react-icons/io5";

interface GetUsuallyUser {
    nome: string;
    id: Number;
}

interface FormularioResponse {
    id: Number;
    Aluno: string;
    Professor: string;
    aprovado: string;
    DiaAula: string;
    imagem1: string;
    imagem2: string;
    imagem3: string;
    professorId: number;
    alunoId: number;
}

interface GetEstudante {
    name: string;
    id: Number;
}

export default function FormularioVisualizacaoModel(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();

    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [teacher, setTeacher] = useState<GetUsuallyUser[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);
    const [student, setStudent] = useState<Student[]>([]);
    const [student2, setStudent2] = useState<GetEstudante[]>([]);
    const [studentId, setStudentId] = useState<Number>(0);
    const [dayClass, setDayClass] = useState<string>(moment().format('YYYY-MM-DD'));
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState(1);

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [image1, setImage1] = useState<string | null>(null);
    const [image2, setImage2] = useState<string | null>(null);
    const [image3, setImage3] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [propsDataFilter, setPropsDataFilter] = useState<FormularioResponse[]>([]);

    useEffect(() => {
        if(props?.propsData && props?.propsData.length > 0 && props?.data) {
            var propsFiltrado = props?.propsData.filter((form:FormularioResponse) => form.id === props?.data?.id);
            setPropsDataFilter(propsFiltrado)
        }

        if (decodedToken?.role !== "Professor" && decodedToken) {
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeacher(response.data.object);
            })
            .catch(() => setIsError(() => true));

            api.get("api/StudentSelectBox").then((response) => {
                setStudent(response.data.object);
            })
            .catch(() => setIsError(() => true));

        } else if (decodedToken) {
            api.get(`api/StudentPerTeacherGet?TeacherId=${decodedToken?.TeacherId}`).then((response) => {
                setStudent2(response.data.object);
            })
            .catch(() => setIsError(() => true));
        }

        setTeacherId(propsDataFilter[0]?.professorId);
        setStudentId(propsDataFilter[0]?.alunoId);
        setDayClass(moment(propsDataFilter[0]?.DiaAula).format('YYYY-MM-DD'));

        if (propsDataFilter[0]?.imagem1) {
            setImage1(`data:image/jpeg;base64,${propsDataFilter[0]?.imagem1}`);
        }
        if (propsDataFilter[0]?.imagem2) {
            setImage2(`data:image/jpeg;base64,${propsDataFilter[0]?.imagem2}`);
        }
        if (propsDataFilter[0]?.imagem3) {
            setImage3(`data:image/jpeg;base64,${propsDataFilter[0]?.imagem3}`);
        }
    }, [decodedToken, isOpen]);

    const openImageModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        onImageOpen();
    };

    const handleAceitarFormulario = () => {
        const data = {
            AlunoId: propsDataFilter[0]?.alunoId,
            Aprovado: true,
            FormularioId: propsDataFilter[0]?.id
        }
        api.put("/api/FormularioReposicao/AutorizarRecusarFormularioReposicao", data)
        .then(() => {
            toast.success("Formulário Aceito com Sucesso!");
            setTick(0);
        })
        .catch((err) => {
            toast.error("Ops, houve um problema, avise o administrador imediatamente!");
        })
        .finally(() =>{
            setTick(1);
            props?.updateTick();
        })
    }

    const handleRecusarFormulario = () => {
        const data = {
            AlunoId: propsDataFilter[0]?.alunoId,
            Aprovado: false,
            FormularioId: propsDataFilter[0]?.id
        }
        api.put("/api/FormularioReposicao/AutorizarRecusarFormularioReposicao", data)
        .then(() => {
            toast.success("Formulário Recusado com Sucesso!");
            setTick(0);
        })
        .catch((err) => {
            toast.error("Ops, houve um problema, avise o administrador imediatamente!");
        })
        .finally(() =>{
            onClose();
            setTick(1);
            props?.updateTick();
        })
    }

    return (
        <>
            {props.modalType === 'edit' ? (
                <Icon as={IoEyeSharp} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            ) : (
                <Icon as={IoEyeSharp} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            )}

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Visualizar Preenchimento Formulário
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                        <FormControl>
                            <p>Data da Aula que não foi aplicado reposição</p>
                            <PatternInput
                                type="date"
                                id="dayClass"
                                name="dayClass"
                                max={moment().add(-15, "days").format('YYYY-MM-DD')}
                                min={moment().add(-45, "days").format('YYYY-MM-DD')}
                                value={dayClass}
                                isDisabled={true}
                                onChange={(event) => setDayClass(event.currentTarget.value)}
                            />
                        </FormControl>
                        {
                            decodedToken?.role != "Professor" ? 
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Professor</FormLabel>
                                <PatternSelect
                                    placeholder="Professor"
                                    value={Number(teacherId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setTeacherId(Number(event.currentTarget.value))}
                                    isDisabled={true}
                                >
                                    {teacher.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.nome}
                                        </option>
                                    ))}
                                </PatternSelect>
                            </FormControl>
                            :
                            null
                        }

                        {
                            decodedToken?.role === "Professor" ? 
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                                <PatternSelect
                                    placeholder="Aluno"
                                    value={Number(studentId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStudentId(Number(event.currentTarget.value))}
                                    isDisabled={true}
                                >
                                    {
                                        student2.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.name}
                                        </option>
                                    ))
                                    }
                                </PatternSelect>
                            </FormControl>
                            :
                            <FormControl>
                                <FormLabel htmlFor="initial-date" fontWeight="bolder">Aluno</FormLabel>
                                <PatternSelect
                                    placeholder="Aluno"
                                    value={Number(studentId)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStudentId(Number(event.currentTarget.value))}
                                    isDisabled={true}
                                >
                                    {
                                        student.map((obj) => (
                                        <option key={Number(obj.id)} value={Number(obj.id)}>
                                            {obj.nome}
                                        </option>
                                    ))
                                    }
                                </PatternSelect>
                            </FormControl>
                        }

                        {
                            decodedToken?.role === "Professor" ? 
                            <p>Obs: Esse formulário só deve ser preenchido caso já faça 15 dias da aula que não foi possível aplicar a reposição.</p>
                            :
                            <p>Obs: Ao aprovar o formulário o professor receberá 1 aula automaticamente.</p>
                        }
                        
                        <Box display="flex" gap={4}>
                            {image1 && (
                                <Image
                                    src={image1}
                                    alt="Imagem 1"
                                    boxSize="100px"
                                    cursor="pointer"
                                    onClick={() => openImageModal(image1)}
                                />
                            )}
                            {image2 && (
                                <Image
                                    src={image2}
                                    alt="Imagem 2"
                                    boxSize="100px"
                                    cursor="pointer"
                                    onClick={() => openImageModal(image2)}
                                />
                            )}
                            {image3 && (
                                <Image
                                    src={image3}
                                    alt="Imagem 3"
                                    boxSize="100px"
                                    cursor="pointer"
                                    onClick={() => openImageModal(image3)}
                                />
                            )}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Fechar
                        </Button>
                        {
                            decodedToken?.role !== 'Professor' &&  propsDataFilter[0]?.aprovado === "SEM AVALIAÇÃO" ? 
                            <>
                                <Button isLoading={isLoading} onClick={handleAceitarFormulario} backgroundColor="green.500">
                                    Aceitar Formulário
                                </Button>
                                <Button isLoading={isLoading} onClick={handleRecusarFormulario} backgroundColor="red.500">
                                    Recusar Formulário
                                </Button>
                            </>
                            :
                            null
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isImageOpen} onClose={onImageClose} size="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody display="flex" justifyContent="center" alignItems="center">
                        {selectedImage && (
                            <Image src={selectedImage} alt="Imagem ampliada" maxHeight="80vh" maxWidth="80vw" />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            
        </>
    );
}
