import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text,
    Icon,
    Box,
    Divider,
    Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SiGoogledocs } from "react-icons/si";
import { api } from "../../services/api";
import { toast } from "react-toastify";

interface ContratoAlunoNovoProfessorProps {
    professorId: number;
}

interface ContratoAluno {
    id: number;
    nomeAluno: string;
    nomeProfessor: string;
    alunoId: number;
    professorId: number;
}

export default function ContratoAlunoNovoProfessor(props: ContratoAlunoNovoProfessorProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [alunosNovos, setAlunosNovos] = useState<ContratoAluno[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [contratoHtml, setContratoHtml] = useState<string>(''); 
    const [isLoading, setIsLoading] = useState(true);
    const [countdown, setCountdown] = useState(15);
    const [isInputEnabled, setIsInputEnabled] = useState(false);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        api.get(`/api/VerificaProfessorTemAlunoNovoEPrecisaAssinarContrato?professorId=${props.professorId}`)
            .then((response) => {
                const alunos = response.data.object;
                setAlunosNovos(alunos);
                if (alunos.length > 0) {
                    fetchContrato(alunos[0].alunoId);
                } else {
                    onClose();
                }
            })
            .catch((error) => {
                toast.error("Houve um erro ao verificar novos alunos, contate um administrador!");
            });
    }, [props.professorId, onClose]);

    useEffect(() => {
        if (isOpen && countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else if (countdown === 0) {
            setIsInputEnabled(true);
        }
    }, [isOpen, countdown]);

    const fetchContrato = (alunoId: number) => {
        setIsLoading(true);
        api.get(`/api/RetornaContratoParaProfessor?idAluno=${alunoId}&idProfessor=${props.professorId}`)
            .then((response) => {
                setContratoHtml(response.data.object); 
                setIsLoading(false);
                onOpen(); // Abre o modal após o contrato ser carregado
            })
            .catch((error) => {
                toast.error("Houve um erro ao carregar o contrato, contate um administrador!");
                setIsLoading(false);
            });
    };

    const handleAccept = () => {
        if (inputValue.toUpperCase() === "EU CONCORDO EM RECEBER O ALUNO") {
            const contrato = {
                alunoId: alunosNovos[currentIndex].alunoId,
                professorId: props.professorId,
                novoAlunoDoProfessorId: alunosNovos[currentIndex].id,
                concordaEmReceberONovoAluno: true,
                textoEscritoPeloProfessor: inputValue.toUpperCase(),
            }
            assinarContrato(contrato);

            if (currentIndex < alunosNovos.length - 1) {
                const nextIndex = currentIndex + 1;
                setCurrentIndex(nextIndex);
                setCountdown(15); // Resetar o contador para o próximo aluno
                setIsInputEnabled(false);
                setInputValue("");
                fetchContrato(alunosNovos[nextIndex].alunoId);

            } else {
                onClose();
            }
        } else {
            toast.error("Você deve digitar a frase exata para continuar.");
        }
    };

    const handleRecuse = () => { 
        const contrato = {
            alunoId: alunosNovos[currentIndex].alunoId,
            professorId: props.professorId,
            novoAlunoDoProfessorId: alunosNovos[currentIndex].id,
            concordaEmReceberONovoAluno: false,
            textoEscritoPeloProfessor: "ALUNO RECUSADO",
        }

        assinarContrato(contrato);

        if (currentIndex < alunosNovos.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            setCountdown(15); // Resetar o contador para o próximo aluno
            setIsInputEnabled(false);
            setInputValue("");
            fetchContrato(alunosNovos[nextIndex].alunoId);
        } else {
            onClose();
        }
    };

    const assinarContrato = (contrato: any) => {
        api.post("api/AssinarContratoNovoAlunoParaProfessor", contrato)
        .then((response) => {
            if(response.data.success)
                toast.success("Sucesso");
        })
        .catch((err) => {
            toast.error("Ops, houve um problema, avise o administrador imediatamente!");
        })
    }

    const handleClose = () => {
        toast.error("Não é possível fechar sem aceitar ou recusar o aluno.")
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="full">
            <ModalOverlay />
            <ModalContent display="flex" flexDirection="column" height="100vh">
                <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">NOVO ALUNO VINCULADO</ModalHeader>
                <ModalCloseButton />
                <ModalBody padding="8" overflowY="auto" flex="1">
                    <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
                        <Icon as={SiGoogledocs} w={16} h={16} color="red.500" />
                        <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={2}>
                            Prezado Professor,
                        </Text>
                        <Text fontSize="lg" textAlign="center" mb={1}>
                            Um novo aluno foi vinculado a você. Solicitamos que leia o contrato abaixo por completo.
                        </Text>
                        <Text fontSize="lg" textAlign="center" mb={1}>
                            Caso concorde em aceitar este aluno, siga as instruções ao final do contrato para formalizar o vínculo.
                        </Text>
                        <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={2}>
                            Nome do Aluno: {alunosNovos[currentIndex]?.nomeAluno}
                        </Text>
                        <Divider borderColor="gray.300" />
                        <Box
                            mt={4}
                            width="100%"
                            bg="white"
                            p={8}
                            boxShadow="lg"
                            borderRadius="md"
                            overflowY="auto"
                            maxHeight="50vh"
                            border="1px solid #e2e8f0"
                            fontFamily="Times New Roman, serif"
                            fontSize="md"
                            lineHeight="tall"
                            dangerouslySetInnerHTML={{ __html: contratoHtml }} // Renderiza o HTML do contrato
                        />
                    </Box>
                </ModalBody>
                <ModalFooter flexDirection="column" alignItems="center">
                    {!isInputEnabled && (
                        <Text fontSize="lg" textAlign="center" mb={4}>
                            Estamos aguardando o término da leitura. O espaço para digitar será disponibilizado em... {countdown} segundos.
                        </Text>
                    )}
                    {isInputEnabled && (
                        <Box width="100%" textAlign="center">
                            <Text fontSize="lg" textAlign="center" mb={2}>
                                Para formalizar o vínculo, digite a frase abaixo e clique em "Aceitar e Continuar":
                            </Text>
                            <Input
                                placeholder="Digite 'EU CONCORDO EM RECEBER O ALUNO'"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                mb={4}
                                width="50%"
                                margin="0 auto"
                            />
                        </Box>
                    )}
                    <Button colorScheme="red" onClick={handleRecuse} mt={2} isDisabled={!isInputEnabled} marginBottom="0.5%">
                        Recusar
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={handleAccept}
                        isDisabled={!isInputEnabled}
                    >
                        Aceitar e Continuar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
