import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import { api } from "../../../services/api";
import PatternButton from "../../Patterns/Button";
import PatternInput from "../../Patterns/Input";
import PatternSelect from "../../Patterns/Select";
import moment from "moment";
import { Console } from "console";

export default function AlunosComecamEmBreveModel(props: any) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [NomeAluno, setNomeAluno] = useState<string>("");
    const [Contato, setContato] = useState<string>("");
    const [QuemFechou, setVendedor] = useState<string>("");
    const [dataInicioAulas, setDataInicioAulas] = useState<string>("");
    const [plano, setPlano] = useState<string>("");
    const [primeiraMensalidade, setPrimeiraMensalidade] = useState<number>(0);
    const [horario, setHorario] = useState<string>("");
    const [profReserva, setProfReserva] = useState<string>("");
    const [sistemaKing, setSistemaKing] = useState<number>(0);
    const [vinculoProfessor, setVinculoProfessor] = useState<number>(0);
    const [agenda, setAgenda] = useState<number>(0);
    const [grupoPosVenda, setGrupoPosVenda] = useState<number>(0);
    const [AlunoId, setAlunoId] = useState<number>(0);
    const [AlunosComecamEmBreveId, setAlunosComecamEmBreveId] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.modalType === 'edit' && props.data) {
            setNomeAluno(props.data.Aluno || "");
            setContato(props.data.Contato || "");
            setVendedor(props.data.Vendedor || "");
            setDataInicioAulas(props.data.DataInicioAulas);
            setPlano(props.data.Plano || "");
            setPrimeiraMensalidade(props.data.PrimeiraMensalidade === 'Pendente' ? 0 : 1);
            setHorario(props.data.Horario || "");
            setProfReserva(props.data.ProfReserva || "");
            setSistemaKing(props.data.SistemaKing === 'Pendente' ? 0 : 1);
            setVinculoProfessor(props.data.VinculoProfessor === 'Pendente' ? 0 : 1);
            setAgenda(props.data.Agenda === 'Pendente' ? 0 : 1);
            setGrupoPosVenda(props.data.GrupoPosVenda === 'Pendente' ? 0 : 1);
            setAlunoId(props.data.AlunoId || 0);
            setAlunosComecamEmBreveId(props.data.AlunosComecamEmBreveId || 0);
        }
    }, [props.modalType, props.data]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const formattedDate = moment(dataInicioAulas, 'DD/MM/YYYY').toISOString();

        const data = {
            AlunosComecamEmBreveId: AlunosComecamEmBreveId,
            AlunoId: AlunoId,
            NomeAluno: NomeAluno,
            Contato: Contato,
            QuemFechou: QuemFechou,
            DataInicioAulas: formattedDate,
            PlanoAluno: plano,
            Horario: horario,
            ProfReserva: profReserva,
            PrimeiraMensalidade: primeiraMensalidade,
            SistemaKing: sistemaKing,
            VinculoProfessor: vinculoProfessor,
            Agenda: agenda,
            GrupoPosVenda: grupoPosVenda,
            QuantidadePendencia: 0   
        };

            if (props.modalType === 'edit') {
                await api.put(`api/SaveStartingNext`, data);
                toast.success("Dados atualizados com sucesso");
            } else {
                await api.post(`api/SaveStartingNext`, data);
                toast.success("Aluno adicionado com sucesso");
            }

            if (props.updateTick) props.updateTick();
        } catch (err: any) {
            if (err.response) {
                toast.error("Ocorreu um erro ao salvar as informações");
            }
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    const handleClose = () => {
        onClose();
    };
    

    return (
        <>
            {props.modalType === 'edit' ? (
                <Icon as={FaPencilAlt} color="primary.500" cursor="pointer" fontSize="1.2rem" onClick={onOpen} />
            ) : (
                <PatternButton icon={IoMdAdd} onClick={onOpen}>
                    Adicionar
                </PatternButton>
            )}

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.modalType === 'new' ? 'Adicionar Novo Aluno' : 'Editar'}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody display="flex" flexDir="column" gap={2}>
                    <FormLabel>Nome do Aluno</FormLabel>
                <span
                    style={{
                        display: 'inline-block',
                        padding: '6px 12px',
                        border: '1px solid #CBD5E0',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                    }}
                >
                    {NomeAluno}
                </span>
                        <FormControl>
                            <FormLabel>Primeira Mensalidade</FormLabel>
                            <PatternSelect
                                value={primeiraMensalidade}
                                onChange={(e) => setPrimeiraMensalidade(Number(e.target.value))}>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>OK</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Horario</FormLabel>
                            <PatternInput
                                value={horario}
                                onChange={(e) => setHorario(e.target.value)}
                            />
                        </FormControl>     
                        <FormControl>
                            <FormLabel>Prof. Reserva</FormLabel>
                            <PatternInput
                                value={profReserva}
                                onChange={(e) => setProfReserva(e.target.value)}
                            />
                        </FormControl>       
                        <FormControl>
                            <FormLabel>Sistema King</FormLabel>
                            <PatternSelect
                                value={sistemaKing}
                                onChange={(e) => setSistemaKing(Number(e.target.value))}>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>OK</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Vínculo Professor</FormLabel>
                            <PatternSelect
                                value={vinculoProfessor}
                                onChange={(e) => setVinculoProfessor(Number(e.target.value))}>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>OK</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Agenda</FormLabel>
                            <PatternSelect
                                value={agenda}
                                onChange={(e) => setAgenda(Number(e.target.value))}>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>OK</option>
                            </PatternSelect>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Grupo Pós-Venda</FormLabel>
                            <PatternSelect
                                value={grupoPosVenda}
                                onChange={(e) => setGrupoPosVenda(Number(e.target.value))}>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>OK</option>
                            </PatternSelect>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <PatternButton isLoading={isLoading} onClick={handleSave}>{props.modalType === 'new' ? 'Salvar' : 'Salvar'}</PatternButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
