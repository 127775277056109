import { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { api } from "../../services/api";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import TeacherWithStudentModal from "../../components/DataGrid/AddModals/TeacherWithStudentModal";
import Select, { SingleValue } from 'react-select';
import moment from "moment";

interface GetAllUserResponse {
    id: Number;
    id_Aluno: Number;
    id_Professor: Number;
    nome_Professor: string;
    nome_Aluno: string;
    quantidade_Aula: Number;
    dia_Aula: string;
};

interface GetTeacher {
    nome: string;
    id: Number;
}

export default function TeacherWithStudentConfig() {
    const [cookies] = useCookies(['loginToken']);
    const { decodedToken } = useJwt<DecodedLoginToken>(cookies?.loginToken?.message);

    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tick, setTick] = useState<number>(0);
    const [teachers, setTeachers] = useState<Teachers[]>([]);
    const [student, setStudent] = useState<Student[]>([]);
    const [teacherId, setTeacherId] = useState<Number>(0);

    const [choosenTeacher, setChoosenTeacher] = useState<SingleValue<{ value: number; label: string }>>();
    const [choosenTeacherId, setChoosenTeacherId] = useState<number>(0);

    useEffect(() => {
        if(decodedToken){
            api.get("/api/TeacherSelectBox").then((response) => {
                setTeachers(response.data.object);
            })   
            .catch(() => setIsError(() => true))
            api.get("/api/StudentSelectBox").then((response) => {
                setStudent(response.data.object);
            })
            .catch(() => setIsError(() => true))
        }
    }, [decodedToken])

    useEffect(() => {
        setIsLoading(() => true);
        if(choosenTeacherId > 0) {
            api.get<GetAllUserResponse[]>(`api/AssociatedTeacherWithStudent?teacher_Id=${choosenTeacherId}`)
            .then((response : any) => {
                setRowData(response.data.object.map((row: any) => {
                    return {
                        NomeAluno: row.nome_Aluno,
                        DataInicioAluno: moment(row.dataAtualizacaoProfessor).format("DD/MM/yyyy"),
                        IdAluno: row.id_Aluno,
                        IdProfessor: row.id_Professor,
                        id: row.id
                    };
                }));
            })
            .catch(() => setIsError(() => true))
            .finally(() => setIsLoading(() => false));
        }
    }, [tick, decodedToken, choosenTeacherId]);

    const updateTick = () => {
        setTick(tick + 1);
    };

    return (
        <>          
            <FormControl>
                <FormLabel fontWeight="bolder">Professor</FormLabel>
                <Select
                    id="teacher"
                    name="teacher"
                    placeholder="Escolha um professor..."
                    noOptionsMessage={msg => msg.inputValue = 'Nenhuma correspondência.'}
                    value={choosenTeacher}
                    onChange={(event) => { setChoosenTeacher(event); setChoosenTeacherId(Number(event?.value)) }}
                    options={teachers.map(t => { return { value: t.id, label: t.nome } })}
                />
            </FormControl>

            <br />
            {rowData.length === 0 && (
                <Box mb={4}>
                    <TeacherWithStudentModal teacher={teachers} student={student} modalType="new" updateTick={updateTick} />
                </Box>
            )}
            {
                !isLoading && rowData.length > 0? 
                <DataGrid
                    rowData={rowData}
                    isLoading={isLoading}
                    isError={isError}
                    addModal={<TeacherWithStudentModal teacher={teachers} student={student} teacherId={teacherId} modalType="new" updateTick={updateTick} />}
                    icons={['Editar', 'Deletar']}
                    deletePath={"AssociatedTeacherWithStudent"}
                    updateTick={updateTick}
                    removerAlunoAgendaProf={true}
                />
                :
                null
            }
        </>
    );
};