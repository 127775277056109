import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';

interface SenhaAtualizadaContextType {
    senhaAtualizadaComSucesso: boolean;
    setSenhaAtualizadaComSucesso: React.Dispatch<React.SetStateAction<boolean>>;
}

const SenhaAtualizadaContext = createContext<SenhaAtualizadaContextType>({
    senhaAtualizadaComSucesso: false,
    setSenhaAtualizadaComSucesso: () => {}
});

interface SenhaAtualizadaProps {
    children: ReactNode;
}

export const SenhaAtualizadaProvider: React.FC<SenhaAtualizadaProps> = ({ children }) => {
    const [senhaAtualizadaComSucesso, setSenhaAtualizadaComSucesso] = useState<boolean>(false);

    return (
        <SenhaAtualizadaContext.Provider value={{ senhaAtualizadaComSucesso, setSenhaAtualizadaComSucesso }}>
            {children}
        </SenhaAtualizadaContext.Provider>
    );
};

export const SenhaAtualizada = () => {
    return useContext(SenhaAtualizadaContext);
};
